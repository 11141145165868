import {Stack, Typography, Box, SxProps, Theme} from '@mui/material';
import {HealthcareInsurancePolicy} from '@src/api';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import Grid from '@src/components/Grid';
import {useTranslate} from '@src/i18n/useTranslate';
import {OVERVIEW_DATA_TEST_ID} from '@src/pages/Overview/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {useColumnsNumber} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {testId} from '@src/shared/utils/testId';
import {alpha} from '@src/theme/utils';
import {useNavigate} from 'react-router-dom';

import {RowInformation} from './RowInformation';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    padding: {
      xs: '12px 18px 18px 18px',
      sm: 24,
    },
    boxSizing: 'border-box',
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 24,
    boxSizing: 'border-box',
  },
  contentContainer: {
    flexDirection: 'initial',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  content: (t) => ({
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    height: {
      xs: 114,
      sm: 132,
      lg: 158,
    },
    padding: {
      xs: '12px 18px',
    },
    maxWidth: {
      xs: 282,
      sm: '100%',
    },
    minWidth: {
      xs: 282,
      md: 384,
      lg: 260,
      xl: 285,
      xxl: 342,
    },
  }),
  link: {
    display: 'flex',
    height: '100%',
  },
  rowInformation: {
    mb: {xs: 0, sm: 20, xxl: 12},
    mr: {xs: 8, sm: 12},
    typography: {
      xs: '12_16_700',
      sm: '14_18_700',
    },
    color: (t: Theme) => t.colors.defaultTextColor,
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 6,
    mt: {
      xs: 0,
      sm: 'inherit',
    },
  },
  insuranceType: {
    color: (t: Theme) => t.colors.personTypeColor,
    mt: {xs: 0, lg: 28, xl: 34},
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
    height: {xs: 24},
  },
  insuranceName: {
    overflow: 'hidden',
    typography: {xs: '14_18_700', sm: '18_24_700'},
    maxHeight: {xs: 36, sm: 'inherit'},
  },
  gridContent: {
    overflowX: {
      xs: 'scroll',
      'scrollbar-width': 'none',
      '-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    overflowY: 'hidden',
    justifyContent: 'space-between',
  },
  titleContainer: {
    typography: {
      xs: '22_26_500',
      sm: '24_34_500',
    },
  },
  button: {
    fontWeight: 600,
  },
  policyNumber: {
    mb: {xs: 22, sm: 0},
    typography: {
      xs: '12_16_500',
      sm: '16_20_500',
    },
  },
};

interface OverviewInsuranceProps {
  insurances: HealthcareInsurancePolicy[] | undefined
  isSidebarOpen?: boolean
  cardsCount: number
}

export const OverviewInsurance = ({insurances, isSidebarOpen, cardsCount}: OverviewInsuranceProps) => {
  const navigate = useNavigate();
  const {mobile: isMobile, lg: isDesktopLg} = useMQuery();
  const {t} = useTranslate('overview');
  const goToInsurance = () => navigate(ROUTERS_PATH.INSURANCE);
  const isMoreThanTwoInsurance = insurances && insurances?.length > 2;
  const breakpoints = {lg: 623};
  const defaultColumns = {lg: 6};
  const customColumns = {lg: 12};
  const {colsNumberLg, containerRef} = useColumnsNumber({breakpoints, defaultColumns, customColumns});

  const getInsuranceTitle = (insurance: HealthcareInsurancePolicy) => {
    let title = '';
    if (insurance?.insurancePlan?.carrier?.name) {
      title = insurance?.insurancePlan?.carrier?.name;
    }
    if (insurance?.insurancePlan?.name) {
      if (title.length) {
        title += ' - ';
      }
      title += insurance?.insurancePlan?.name;
    }

    return title;
  };

  return (
    <Stack sx={sx.container} ref={containerRef}>
      <Box sx={sx.topContainer}>
        <Typography component="h3" sx={sx.titleContainer}>
          {t('INSURANCE')}
        </Typography>
      </Box>
      <Stack>
        <Grid container sx={sx.gridContent}>
          <Grid
            container
            direction='row'
            wrap='nowrap'
            width='100%'
            gap={12}
          >
            {insurances?.slice(0, cardsCount).map((insurance) => (
              <Grid
                data-test-id={testId(OVERVIEW_DATA_TEST_ID.overviewInsuranceItem)}
                direction='row'
                wrap='nowrap'
                width='100%'
                xs={12}
                sm={6}
                lg={colsNumberLg}
                xl={6}
                onClick={goToInsurance}
                key={insurance.id}
                sx={{
                  display: 'grid',
                  cursor: 'pointer',
                  maxWidth: {
                    xs: 282,
                    sm: '100%',
                    md: isMoreThanTwoInsurance ? '100%' : 'calc(50% - 12px)',
                    xl: isMoreThanTwoInsurance ? '100%' : 360,
                  },
                  minWidth: {
                    xs: 282,
                    md: 384,
                    lg: isDesktopLg && isSidebarOpen && !isMoreThanTwoInsurance ? 270 : 260,
                    xl: 285,
                    xxl: 342,
                  },
                  width: {
                    lg: (insurances?.length === 1 && isDesktopLg && isSidebarOpen && window.innerWidth > 1419) ||
                      (insurances?.length === 1 && isDesktopLg && !isSidebarOpen)
                      ? 'calc(50% - 18px)'
                      : 'inherit',
                    xl: 'inherit',
                  },
                }}
              >
                <Box sx={sx.content}>
                  <Box sx={sx.contentWrapper}>
                    <EllipsisTextTooltip ellipsisText={getInsuranceTitle(insurance)} sx={sx.insuranceName} />
                  </Box>
                  <RowInformation
                    ellipsisText
                    ellipsisTextSx={sx.policyNumber}
                    noMargin={isMobile}
                    value={insurance?.number}
                    name={t('POLICY_ID')}
                    sx={sx.rowInformation}
                    isMobile={isMobile}
                  />
                  <Typography sx={sx.insuranceType}>{insurance?.primary ? t('PRIMARY') : t('SECONDARY')}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
