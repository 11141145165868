export const EDIT_RESULT_DIALOG_DATA_TEST_ID = {
  editResultDialogNameInput: '_edit-result-dialog_name-input',
  editResultDialogDateRange: '_edit-result-dialog_date-range',
  editResultDialogHealthCase: '_edit-result-dialog_health-case',
  editResultDialogDescriptionInput: '_edit-result-dialog_description-input',
  editResultDialog: '_edit-result-dialog',
  editResultDialogAddBiomarkerButton: '_edit-result-dialog_add-biomarker-button',
  editResultDialogDownloadButton: '_edit-result-dialog_download-button',
  editResultDialogDeleteFileButton: '_edit-result-dialog_delete-file-button',
  editResultDialogCancelButton: '_edit-result-dialog_cancel-button',
  editResultDialogSaveButton: '_edit-result-dialog_save-button',
  editResultDialogTitleCloseButton: '_edit-result-dialog_title_close-button',
} as const;
