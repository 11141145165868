import {Grid, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {Result} from '@src/api';
import {DatePickerRange} from '@src/components';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {BoxPaddings} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {RESULTS_DATA_TEST_ID} from '@src/pages/Results/testIds';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {useMQuery} from '@src/shared/hooks';
import {getFullName} from '@src/shared/utils';
import {testId} from '@src/shared/utils/testId';
import {useAppDispatch} from '@src/store';
import {resultsActions} from '@src/store/results/slice';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {ActionsButton, ActionsRow, IconButton, MenuItem, OptionValue, SelectControl} from 'ui-kit';

import {initialFilter} from '../../Results.constant';
import {dateInInterval} from '../../Results.helpers';
import {FilterDialog} from '../FiltersDialog';

import {sx} from './styles';
import {ResultsFilterTableProps} from './types';

export const ResultsFilters: FC<ResultsFilterTableProps> = ({
  isOpen,
  handleCloseFilter,
  handleOpenFilter,
  handleSetFilter,
  filter,
  data,
}) => {
  const {t, ready} = useTranslate('results');
  const {mobile, laptop, tabletPortrait} = useMQuery();
  const dispatch = useAppDispatch();
  const [tempFilter, setTempFilter] = useState(filter);

  const [selectedPhysician, setSelectedPhysician] = useState('');
  const [tempSelectedPhysician, setTempSelectedPhysician] = useState(selectedPhysician);
  const [selectedHealthCase, setSelectedHealthCase] = useState<OptionValue[]>([]);
  const [tempSelectedHealthCase, setTempSelectedHealthCase] = useState(selectedHealthCase);
  const onPortrait = (mobile || tabletPortrait);

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (value: string) => {
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('');
  };

  const getPhysicians = useMemo(() => {
    const uniquePhysiciansMap = new Map(data?.map((item) => [item?.physician?.id, item.physician]));

    const uniquePhysicians = [...uniquePhysiciansMap.values()];

    const physiciansOptions = uniquePhysicians.map((physician) => ({
      value: physician?.id || '',
      name: getFullName(physician),
      key: physician?.id || '',
    }));

    return physiciansOptions.filter((item) => item.name && item.value);
  }, [data]);

  const healthCasesOptions = useMemo(() => {
    const allHealthCases = data?.flatMap((item) => item?.healthCases) || [];

    const uniqueHealthCasesMap = new Map(
      allHealthCases.map((healthCase) => [healthCase?.name, healthCase]),
    );

    const uniqueHealthCases = Array.from(uniqueHealthCasesMap.values());

    return uniqueHealthCases.map((healthCase) => ({
      value: healthCase?.id || '',
      label: healthCase?.name || '',
    }));
  }, [data]);

  const filteredCasesOptions = useMemo(() => {
    if (searchValueHealthCase) {
      return healthCasesOptions.filter((option) =>
        option.label.toLowerCase().includes(searchValueHealthCase.toLowerCase()),
      );
    }
    return healthCasesOptions;
  }, [healthCasesOptions, searchValueHealthCase]);

  const handlePhysicianSelect = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setTempSelectedPhysician(selectedValue);
    const selectedPhysician = getPhysicians.find((item) => item.name === event.target.value);
    if (selectedPhysician) {
      setTempFilter((prev) => ({
        ...prev,
        physician: {value: selectedPhysician.value, name: selectedPhysician.name},
      }));
    }
  };

  const handleHealthCaseSelect = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const selectedHealthCases = healthCasesOptions.filter((option) => value.includes(option.value));

    setTempSelectedHealthCase(selectedHealthCases);
    setTempFilter((prevFilter) => ({
      ...prevFilter,
      healthCase: selectedHealthCases,
    }));
  };

  const filterByPhysician = (
    resultItem: Result,
    physician: { value: string, name: string } | null,
  ) => {
    if (!physician) return true;
    return resultItem.physician?.id === physician.value;
  };

  const filterByHealthCase = (resultItem: Result, selectedHealthCases: OptionValue[] | null) => {
    if (!selectedHealthCases || selectedHealthCases.length === 0) return true;
    return resultItem.healthCases?.some((hc) =>
      selectedHealthCases.some(selectedCase => selectedCase.value === hc?.id),
    );
  };

  const filterByCollectionDate = (
    resultItem: Result,
    collectionDateFrom: Date | null,
    collectionDateTo: Date | null,
  ) => {
    if (!collectionDateFrom && !collectionDateTo) return true;
    return dateInInterval(
      collectionDateFrom,
      collectionDateTo,
      resultItem.collected || resultItem.date,
    );
  };

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setTempFilter((prev) => ({...prev, [name]: date}));
  };

  const handleClearCollectionDate = () => {
    setTempFilter((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handleApplyFilter = useCallback(() => {
    const {physician, healthCase, collectionDateFrom, collectionDateTo} = tempFilter;

    handleSetFilter(tempFilter);
    setSelectedPhysician(tempSelectedPhysician);
    setSelectedHealthCase(tempSelectedHealthCase);

    const doFiltration = (resultItem: Result) => {
      return (
        filterByPhysician(resultItem, physician) &&
        filterByHealthCase(resultItem, healthCase) &&
        filterByCollectionDate(resultItem, collectionDateFrom, collectionDateTo)
      );
    };

    if (data) dispatch(resultsActions.setFilterResults(data.filter(doFiltration)));
  }, [data, tempFilter, isOpen]);

  const handleClearFilter = () => {
    setSelectedPhysician('');
    setTempSelectedPhysician('');
    setSelectedHealthCase([]);
    setTempSelectedHealthCase([]);
    setTempFilter(initialFilter);
  };

  useEffect(() => {
    if (!isOpen) {
      handleApplyFilter();
    }
  }, [tempFilter]);

  const handleCasesCheckToggle = () => {
    if (selectedHealthCase.length > healthCasesOptions.length / 2) {
      setSelectedHealthCase([]);
    } else {
      setSelectedHealthCase(healthCasesOptions);
    }
  };

  if (!ready) return null;

  return (
    <>
      {!onPortrait && (
        <BoxPaddings noBottom noTop sx={sx.filterWrapper}>
          <Grid container flexGrow={1} gap={24}>
            <Grid xs={5} lg={5.5} xl={3.5}>
              <Stack justifyContent={'center'} pb={2}>
                <Typography variant="14_18_700" width={75}>
                  {t('PHYSICIAN')}
                </Typography>
              </Stack>
              <FormSelectControl
                placeholder={t('SELECT')}
                name={'physician'}
                value={tempSelectedPhysician}
                onChange={handlePhysicianSelect}
                data-test-id={testId(RESULTS_DATA_TEST_ID.resultsFiltersPhysicianSelect)}
              >
                {getPhysicians.map((option) => (
                  <MenuItem
                    key={option.name}
                    value={option.name}
                    data-test-id={testId(RESULTS_DATA_TEST_ID.resultsFiltersPhysicianSelectItem)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </FormSelectControl>
            </Grid>
            <Grid
              xs={5}
              lg={5.5}
              xl={3.5}
              sx={sx.inputWrapper}
            >
              <SelectControl<string>
                data-test-id={testId(RESULTS_DATA_TEST_ID.resultsFiltersHealthCaseSelect)}
                value={selectedHealthCase}
                onChange={handleHealthCaseSelect}
                multiple
                label={t('HEALTH_CASES')}
                placeholder={t('SELECT')}
                onSearch={handleChangeSearchValue}
                onClose={handleClearSearchValue}
                dropdownFooterRow={
                  <ActionsRow>
                    <ActionsButton
                      data-test-id={testId(
                        RESULTS_DATA_TEST_ID.resultsFiltersHealthCaseSelectDropdownCheckUncheckAll,
                      )}
                      onClick={handleCasesCheckToggle}
                      noClose={onPortrait}
                    >
                      {selectedHealthCase.length > healthCasesOptions.length / 2 ? t('UNCHECK_ALL') : t('CHECK_ALL')}
                    </ActionsButton>
                  </ActionsRow>
                }
              >
                {filteredCasesOptions.length
                  ? (
                    filteredCasesOptions.map(({value, label}) => (
                      <MenuItem
                        key={value}
                        value={value}
                        data-test-id={testId(
                          RESULTS_DATA_TEST_ID.resultsFiltersHealthCaseSelectDropdownItem,
                        )}
                      >
                        {label}
                      </MenuItem>
                    ))
                  )
                  : (
                    <ActionsRow>{t('NO_SUITABLE_RESULTS')}</ActionsRow>
                  )}
              </SelectControl>
            </Grid>
            {!laptop && (
              <Grid xs={2.5} xl={3.5}>
                <DatePickerRange
                  isIconVisible={false}
                  variant="outlined"
                  label={t('COLLECTED')}
                  startDate={filter.collectionDateFrom}
                  setStartDate={handleChangeDate('collectionDateFrom')}
                  setEndDate={handleChangeDate('collectionDateTo')}
                  endDate={filter.collectionDateTo}
                  clearDate={handleClearCollectionDate}
                  labelTop
                  dataTestId={testId(RESULTS_DATA_TEST_ID.resultsFiltersDateRange)}
                />
              </Grid>
            )}
          </Grid>
          <Stack flexDirection={'row'} gap={12} sx={{alignItems: 'flex-end'}}>
            {laptop && (
              <IconButton
                color={'secondary-light'}
                variant={'outlined'}
                onClick={handleOpenFilter}
                data-test-id={testId(RESULTS_DATA_TEST_ID.resultsFiltersOpenFiltersButton)}
              >
                <SettingsIcon />
              </IconButton>
            )}
            <IconButton
              color={'secondary-light'}
              onClick={handleClearFilter}
              data-test-id={testId(RESULTS_DATA_TEST_ID.resultsFiltersClearFiltersButton)}
            >
              <RefreshIcon />
            </IconButton>
          </Stack>
        </BoxPaddings>
      )}
      <FilterDialog
        isFilterOpen={isOpen}
        handleClose={handleCloseFilter}
        physicianOptions={getPhysicians}
        healthCasesOptions={healthCasesOptions}
        tempSelectedHealthCase={tempSelectedHealthCase}
        filter={filter}
        setNewFilters={setTempFilter}
      />
    </>
  );
};
