export const BMI_DATA_TEST_ID = {
  bmiBreadcrumbsBack: 'bmi__breadcrumbs_back',
  bmiCalculatorSexMaleButton: 'bmi__calculator_sex_male-button',
  bmiCalculatorSexFemaleButton: 'bmi__calculator_sex_female-button',
  bmiCalculatorHeightSlider: 'bmi__calculator_height-slider',
  bmiCalculatorWeightSlider: 'bmi__calculator_weight-slider',
  bmiCalculatorAgeSlider: 'bmi__calculator_age-slider',
  bmiCalculatorBirthDate: 'bmi__calculator_birth-date',
  bmiCalculatorSaveButton: 'bmi__calculator_save-button',
  bmiCalculatorSuccessDialog: 'bmi__calculator_success-dialog',
  bmiCalculatorSuccessDialogTitleCloseButton: 'bmi__calculator_success-dialog_title_close-button',
  bmiCalculatorSuccessDialogFooterOkButton: 'bmi__calculator_success-dialog_footer_ok-button',
} as const;
