export const RESULTS_DATA_TEST_ID = {
  resultsTableRowCheckbox: 'results__table_row_checkbox',
  resultsFiltersHealthCaseSelect: 'results__filters_health-case_select',
  resultsFiltersHealthCaseSelectDropdownCheckUncheckAll: 'results__filters_health-case_select_dropdown_check-uncheck-all',
  resultsFiltersHealthCaseSelectDropdownItem: 'results__filters_health-case_select_dropdown_item',
  resultsFiltersDialogHealthCaseSelect: 'results__filters_dialog_health-case_select',
  resultsFiltersDialogHealthCaseSelectDropdownCheckUncheckAll: 'results__filters_dialog_health-case_select_dropdown_check-uncheck-all',
  resultsFiltersDialogHealthCaseSelectDropdownItem: 'results__filters_dialog_health-case_select_dropdown_item',
  resultsFiltersOpenFiltersButton: 'results__filters_open-filters-button',
  resultsFiltersClearFiltersButton: 'results__filters_clear-filters-button',
  resultsHeaderAddResultButton: 'results__header_add-result-button',
  results: 'results_',
  resultsTableExpandAllButton: 'results__table_expand-all-button',
  resultsFiltersPhysicianSelect: 'results__filters_physician-select',
  resultsFiltersPhysicianSelectItem: 'results__filters_physician-select_item',
  resultsFiltersDateRange: 'results__filters_date-range',
  resultsFiltersDialogPhysicianSelect: 'results__filters-dialog_physician-select',
  resultsFiltersDialogPhysicianSelectItem: 'results__filters-dialog_physician-select_item',
  resultsFiltersDialogDateRange: 'results__filters-dialog_date-range',
  resultsTableRow: 'results__table_row',
  resultsTable: 'results__table',
  resultsAddResultDialog: 'results__add-result-dialog',
  resultsAddResultDialogHeaderCloseButton: 'results__add-result-dialog_header_close-button',
  resultsAddResultDialogManualButton: 'results__add-result-dialog_manual-button',
  resultsAddResultDialogAutomaticButton: 'results__add-result-dialog_automatic-button',
} as const;
