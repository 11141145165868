export const ADD_INSURANCE_DATA_TEST_ID = {
  addInsuranceCarrier: 'add-insurance__carrier',
  addInsurancePlanSelect: 'add-insurance__plan_select',
  addInsurancePlanSelectDropdownItem: 'add-insurance__plan_select_dropdown_item',
  addInsurancePolicyIdInput: 'add-insurance__policy-id-input',
  addInsuranceEndDate: 'add-insurance__end-date',
  addInsuranceRelationshipSelect: 'add-insurance__relationship_select',
  addInsuranceRelationshipSelectDropdownItem: 'add-insurance__relationship_select_dropdown_item',
  addInsuranceFirstNameInput: 'add-insurance__first-name-input',
  addInsuranceLastNameInput: 'add-insurance__last-name-input',
  addInsuranceMiddleNameInput: 'add-insurance__middle-name-input',
  addInsuranceBirthDate: 'add-insurance__birth-date',
  addInsuranceBirthSexSelect: 'add-insurance__birth-sex_select',
  addInsuranceBirthSexSelectDropdownItem: 'add-insurance__birth-sex_select_dropdown_item',
  addInsuranceSsnTailInput: 'add-insurance__ssn-tail-input',
  addInsuranceStateSelect: 'add-insurance__state_select',
  addInsuranceStateSelectDropdownItem: 'add-insurance__state_select_dropdown_item',
  addInsuranceCityInput: 'add-insurance__city-input',
  addInsuranceZipInput: 'add-insurance__zip-input',
  addInsuranceAddress1Input: 'add-insurance__address-1-input',
  addInsuranceAddress2Input: 'add-insurance__address-2-input',
  addInsurancePhoneInput: 'add-insurance__phone-input',
  addInsuranceFooterCancelButton: 'add-insurance__footer_cancel-button',
  addInsuranceFooterSaveButton: 'add-insurance__footer_cancel-button',
} as const;
