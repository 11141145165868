export const DETAILS_DATA_TEST_ID = {
  detailsTable: 'result-details__table',
  accordion: '_accordion',
  checkboxTest: '_checkbox-test',
  testRowRejectedReasonButton: '_test-row_rejected-reason-button',
  checkboxTestProductPanel: '_checkbox-product-panel',
  detailsBreadcrumbsResults: 'result-details__breadcrumbs_results',
  detailsRejectedReasonsDialog: 'result-details__rejected-reasons-dialog',
  rejectedReasonsDialogTitleClose: '_title-close',
  rejectedReasonsDialogBackButton: '_back-button',
  details: 'result-details_',
  detailsHeaderEditButton: 'result-details__header_edit-button',
  detailsHeaderDeleteButton: 'result-details_header_delete-button',
  detailsDeleteDialog: 'result-details_header_delete-dialog',
  detailsDeleteDialogHeaderCloseButton: 'result-details_delete-dialog_header_close-button',
  detailsDeleteDialogFooterCloseButton: 'result-details_delete-dialog_footer_close-button',
  detailsDeleteDialogFooterDeleteButton: 'result-details_delete-dialog_footer_delete-button',
  detailsInfoCollapseButton: 'result-details_info_collapse-button',
  detailsInfoHealthCaseLink: 'result-details_info_health-case-link',
  detailsInfoDownloadButton: 'result-details_info_download-button',
  detailsInfoDynamicsButton: 'result-details_info_dynamics-button',
} as const;
