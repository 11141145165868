import {Dialog, DialogActions, DialogTitle} from '@components';
import {Box, Typography} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {DELETE_DIALOG_DATA_TEST_ID} from '@src/pages/StorageDocument/components/DeleteDialog/testIds';
import {ReactComponent as Error} from '@src/shared/assets/icons/error.svg';
import {testId} from '@src/shared/utils/testId';
import {deleteStorageFile} from '@src/store/storage/slice';
import {useAppDispatch} from '@store';
import {FC, useState} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {DeleteDialogProps} from './types';

export const DeleteDialog: FC<DeleteDialogProps> = ({
  isOpen,
  id,
  name,
  onClose,
  afterDeleteAction,
  testIdFeatureGroup,
}) => {
  const {t} = useTranslate('storage');
  const dispatch = useAppDispatch();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const handleErrorDialogOpen = () => {
    setIsErrorDialogOpen(true);
  };
  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false);
    onClose();
  };

  const handleDelete = () => {
    void dispatch(
      deleteStorageFile({
        id,
        successText: t('DELETE_SUCCESS'),
      }),
    )
      .unwrap()
      .then(() => {
        onClose();
        afterDeleteAction?.();
      })
      .catch(() => {
        handleErrorDialogOpen();
      });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        size={'sm'}
        border={'unset'}
        data-test-id={testId(testIdFeatureGroup + DELETE_DIALOG_DATA_TEST_ID.deleteDialogSure)}
      >
        <DialogTitle
          onClose={onClose}
          width="65px"
          closeButtonDataTestId={
            testIdFeatureGroup + DELETE_DIALOG_DATA_TEST_ID.deleteDialogSureHeaderCloseButton
          }
        >
          {t('DELETE_DOCUMENT')}
        </DialogTitle>
        <Box sx={sx.mainDialogContent}>
          <Typography variant={'16_20_500'}>{t('DELETE_DOCUMENT_SURE')}</Typography>
        </Box>
        <DialogActions sx={sx.btnContainer}>
          <Button
            sx={sx.btn}
            variant={'outlined'}
            color={'secondary'}
            onClick={onClose}
            data-test-id={testId(
              testIdFeatureGroup + DELETE_DIALOG_DATA_TEST_ID.deleteDialogSureFooterCloseButton,
            )}
          >
            {t('CANCEL')}
          </Button>
          <Button
            sx={sx.btn}
            color={'secondary'}
            onClick={handleDelete}
            data-test-id={testId(
              testIdFeatureGroup + DELETE_DIALOG_DATA_TEST_ID.deleteDialogSureFooterDeleteButton,
            )}
          >
            {t('DELETE')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isErrorDialogOpen}
        sx={sx.dialog}
        size={'sm'}
        data-test-id={testId(testIdFeatureGroup + DELETE_DIALOG_DATA_TEST_ID.deleteDialog)}
      >
        <DialogTitle
          sx={sx.dialogTitle}
          width="65px"
          onClose={handleErrorDialogClose}
          closeButtonDataTestId={
            testIdFeatureGroup + DELETE_DIALOG_DATA_TEST_ID.deleteDialogHeaderCloseButton
          }
        >
          {<Error />}
          {t('ERROR_EXCLAMATION')}
        </DialogTitle>
        <Box sx={sx.dialogContent}>
          <Typography variant={'14_18_500'}>{t('DOCUMENT_NOT_DELETED', {name})}</Typography>
        </Box>
        <DialogActions sx={sx.dialogActions}>
          <Button
            color={'white'}
            sx={sx.okBtn}
            onClick={handleErrorDialogClose}
            data-test-id={testId(
              testIdFeatureGroup + DELETE_DIALOG_DATA_TEST_ID.deleteDialogFooterOkButton,
            )}
          >
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
