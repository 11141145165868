import {StorageFileCategory} from '@api';
import {DatePickerRange} from '@components';
import {SelectChangeEvent, Stack} from '@mui/material';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {STORAGE_DATA_TEST_ID} from '@src/pages/Storage/testIds';
import {STORAGE_CATEGORY_TYPE_MAP} from '@src/shared/constants/enumsMaps';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {INITIAL_DATES, INITIAL_FILTERS} from '@src/store/storage/constants';
import {FC, useEffect, useMemo, useState} from 'react';
import {ActionsButton, ActionsRow, MenuItem, SelectControl} from 'ui-kit';

import {categoryOptions} from '../../helpers';
import {useStorageTranslate} from '../../hooks';
import {StyledAdaptiveFieldWrapper} from '../Filters/styles';

import {FiltersDialogProps} from './types';

export const FiltersDialog: FC<FiltersDialogProps> = ({
  filters,
  onUpdateFilters,
  isOpen,
  onClose,
  isSidebarOpen,
  healthCasesOptions,
}) => {
  const {xl, xxl, mobile: isMobile, tabletPortrait: isTabletSm} = useMQuery();
  const {t: tEnums} = useTranslate('enums');
  const translate = useStorageTranslate();
  const [dialogFilters, setDialogFilters] = useState(filters);

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (value: string) => {
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('');
  };

  const filteredCasesOptions = useMemo(() => {
    if (searchValueHealthCase) {
      return healthCasesOptions.filter((option) =>
        option.label.toLowerCase().includes(searchValueHealthCase.toLowerCase()),
      );
    }
    return healthCasesOptions;
  }, [healthCasesOptions, searchValueHealthCase]);

  useEffect(() => {
    if (isOpen && (isSidebarOpen ? xxl : xl || xxl)) {
      onClose();
    }
  }, [xl, xxl, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setDialogFilters(filters);
    }
  }, [isOpen]);

  const handleChangeHealthCases = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const selectedHealthCases = healthCasesOptions.filter((option) => value.includes(option.value));
    setDialogFilters((prevState) => ({...prevState, cases: selectedHealthCases}));
  };
  const handleCasesCheckToggle = () => {
    if ((dialogFilters.cases?.length || 0) > healthCasesOptions.length / 2) {
      setDialogFilters(prev => ({...prev, cases: []}));
    } else {
      setDialogFilters(prev => ({...prev, cases: healthCasesOptions}));
    }
  };

  const handleChangeCategory = (event: SelectChangeEvent<StorageFileCategory>) => {
    const value = event.target.value as StorageFileCategory;
    setDialogFilters((prevState) => ({...prevState, category: value}));
  };
  const handleChangeDate = (name: 'from' | 'to') => (date: Date | null) => {
    setDialogFilters((prevState) => ({...prevState, [name]: date}));
  };
  const handleClearDate = () => {
    setDialogFilters((prevState) => ({...prevState, ...INITIAL_DATES}));
  };

  const handleClearFilters = () => {
    setDialogFilters(INITIAL_FILTERS);
  };

  const handleApply = () => {
    onClose();
    onUpdateFilters(dialogFilters);
  };

  return (
    <FiltersDialogContainer
      isOpen={isOpen}
      title={translate('FILTER')}
      onClose={onClose}
      onApply={handleApply}
      onClear={handleClearFilters}
      testIdFeatureGroup={STORAGE_DATA_TEST_ID.storage}
    >
      <Stack gap={24}>
        <StyledAdaptiveFieldWrapper
          downBreakpoint={'xs'}
          upBreakpoint={'lg'}
          isSidebarOpen={isSidebarOpen}
        >
          <FormSelectControl
            name="category"
            label={translate('CATEGORY')}
            placeholder={translate('SELECT')}
            value={dialogFilters.category}
            onChange={handleChangeCategory}
            renderValue={(v) => (v ? tEnums(STORAGE_CATEGORY_TYPE_MAP[v]) : '')}
            data-test-id={testId(STORAGE_DATA_TEST_ID.storageHeaderFiltersDialogCategorySelect)}
          >
            {categoryOptions.map(({name, value}, key) => (
              <MenuItem
                key={key}
                value={value}
                data-test-id={testId(
                  STORAGE_DATA_TEST_ID.storageHeaderFiltersDialogCategorySelectDropdownItem,
                )}
              >
                {tEnums(name)}
              </MenuItem>
            ))}
          </FormSelectControl>
        </StyledAdaptiveFieldWrapper>
        <StyledAdaptiveFieldWrapper downBreakpoint={'xs'} isSidebarOpen={isSidebarOpen}>
          <SelectControl<string>
            data-test-id={testId(STORAGE_DATA_TEST_ID.storageFiltersDialogHealthCaseSelect)}
            value={dialogFilters.cases}
            onChange={handleChangeHealthCases}
            multiple
            label={translate('HEALTH_CASES')}
            placeholder={translate('SELECT')}
            onSearch={handleChangeSearchValue}
            onClose={handleClearSearchValue}
            dropdownFooterRow={
              <ActionsRow>
                <ActionsButton
                  data-test-id={testId(
                    STORAGE_DATA_TEST_ID.storageFiltersDialogHealthCaseSelectDropdownCheckUncheckAll,
                  )}
                  onClick={handleCasesCheckToggle}
                  noClose={isMobile || isTabletSm}
                >
                  {(dialogFilters.cases?.length || 0) > healthCasesOptions.length / 2 ? translate('UNCHECK_ALL') : translate('CHECK_ALL')}
                </ActionsButton>
              </ActionsRow>
            }
          >
            {filteredCasesOptions.length
              ? (
                filteredCasesOptions.map(({value, label}) => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-test-id={testId(
                      STORAGE_DATA_TEST_ID.storageFiltersDialogHealthCaseSelectDropdownItem,
                    )}
                  >
                    {label}
                  </MenuItem>
                ))
              )
              : (
                <ActionsRow>{translate('NO_SUITABLE_RESULTS')}</ActionsRow>
              )}
          </SelectControl>
        </StyledAdaptiveFieldWrapper>
        <StyledAdaptiveFieldWrapper downBreakpoint={'xs'} isSidebarOpen={isSidebarOpen}>
          <DatePickerRange
            sx={{mb: '0 !important'}}
            isIconVisible={false}
            variant="outlined"
            label={translate('DATE')}
            startDate={dialogFilters.from}
            endDate={dialogFilters.to}
            setStartDate={handleChangeDate('from')}
            setEndDate={handleChangeDate('to')}
            clearDate={handleClearDate}
            labelTop
            startRangeWithCurrentMonth
            dataTestId={STORAGE_DATA_TEST_ID.storageHeaderFiltersDialogDateRange}
          />
        </StyledAdaptiveFieldWrapper>
      </Stack>
    </FiltersDialogContainer>
  );
};
