export const SEND_RESULT_DIALOG_DATA_TEST_ID = {
  sendResultDialog: '_send-result-dialog',
  sendResultDialogHeaderCloseButton: '_send-result-dialog_header_close-button',
  sendResultDialogEmailInput: '_send-result-dialog_email-input',
  sendResultDialogFooterCloseButton: '_send-result-dialog_footer_close-button',
  sendResultDialogFooterSubmitButton: '_send-result-dialog_footer_close-button',
  sendResultErrorDialog: '_send-result-error-dialog',
  sendResultErrorDialogHeaderCloseButton: '_send-result-error-dialog_header_close-button',
  sendResultErrorDialogFooterCloseButton: '_send-result-error-dialog_footer_close-button',
  sendResultSuccessDialog: '_send-result-success-dialog',
  sendResultSuccessDialogHeaderCloseButton: '_send-result-success-dialog_header_close-button',
  sendResultSuccessDialogFooterCloseButton: '_send-result-success-dialog_footer_close-button',
} as const;
