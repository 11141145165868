import {Stack, Typography} from '@mui/material';
import {
  Biomarker as BiomarkerType,
  BiomarkerUnit,
  FetchBiomarkersResponse,
  storageApi,
} from '@src/api';
import {BIOMARKER_DATA_TEST_ID} from '@src/components/EditDocumentDialog/components/Biomarker/testIds';
import {FormInputControl} from '@src/components/FormInputControl';
import {FormSelectControlNew} from '@src/components/FormSelectControlNew';
import {useTranslate} from '@src/i18n/useTranslate';
import {MAX_STORAGE_BIOMARKER_VALUE_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks';
import {useDebounce} from '@src/shared/hooks/useDebounce';
import {testId} from '@src/shared/utils/testId';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ActionsRow, IconButtonNew, MenuItem} from 'ui-kit';
import DeleteIcon from 'ui-kit/lib/icons/24/Action/Delete';

import {DEFAULT_BIOMARKER_PER_PAGE} from './constants';
import {sx} from './styles';
import {BiomarkerProps} from './types';

export const Biomarker: FC<BiomarkerProps> = ({
  biomarker,
  onRemove,
  name,
  initialBiomarker,
  isEdit,
  testIdFeatureGroup = '',
}) => {
  const {t} = useTranslate('storage');
  const {mobile: isMobile} = useMQuery();
  const formContext = useFormContext();

  const formBiomarkerIdName = name + '.biomarkerId';
  const formBiomarkerNameName = name + '.biomarkerName';
  const formBiomarkerUnitIdName = name + '.biomarkerUnitId';
  const formValueName = name + '.value';
  const formHasUnitsName = name + '.hasUnits';

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const [currentBiomarkerPage, setCurrentBiomarkerPage] = useState(0);
  const [isBiomarkersLoading, setIsBiomarkersLoading] = useState(false);
  const [biomarkers, setBiomarkers] = useState<FetchBiomarkersResponse>({
    hasNext: true,
    items: [],
  });

  const [selectedBiomarker, setSelectedBiomarker] = useState<BiomarkerType>({
    name: formContext.getValues(formBiomarkerNameName),
    id: formContext.getValues(formBiomarkerIdName),
  });

  const [biomarkerUnits, setBiomarkerUnits] = useState<BiomarkerUnit[]>([]);

  const handleChangeSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const handleChangeSelectedBiomarker = (newBiomarker: BiomarkerType) => {
    setSelectedBiomarker(newBiomarker);
    formContext.setValue(formBiomarkerNameName, newBiomarker.name);

    if (biomarker.biomarkerId !== selectedBiomarker?.id) {
      formContext.clearErrors(name);
      formContext.setValue(formBiomarkerUnitIdName, '');
      formContext.setValue(formValueName, '');
    }
  };

  const handleFetchBiomarkers = async (isNext?: boolean) => {
    if (isBiomarkersLoading || (isNext && !biomarkers.hasNext)) {
      return;
    }
    setIsBiomarkersLoading(true);
    try {
      const fetchPage = isNext ? currentBiomarkerPage + 1 : 0;
      const response = await storageApi.getBiomarkers({
        perPage: DEFAULT_BIOMARKER_PER_PAGE,
        startPage: fetchPage,
        phrase: debouncedSearchValue,
      });
      if (isNext) {
        setBiomarkers((prev) => ({
          hasNext: response.hasNext,
          items: [...prev.items, ...response.items],
        }));
      } else {
        setBiomarkers(response);
      }
      setCurrentBiomarkerPage(fetchPage);
    } catch (e) {
      console.error(e);
    }
    setIsBiomarkersLoading(false);
  };

  const createBiomarkersArr = formContext.watch('createBiomarkers');
  const editBiomarkersArr = formContext.watch('editBiomarkers');

  const filteredBiomarkers = useMemo(() => {
    const createBiomarkers = formContext.getValues('createBiomarkers') || [];
    const editBiomarkers = formContext.getValues('editBiomarkers') || [];
    const combinedBiomarkers = [...editBiomarkers, ...createBiomarkers];

    return biomarkers.items.filter((responseBiomarker) => {
      if (responseBiomarker.id === selectedBiomarker?.id) {
        return true;
      }
      return !combinedBiomarkers.find((cBiomarker) => {
        if (cBiomarker.biomarkerId === selectedBiomarker?.id) {
          return false;
        }
        return responseBiomarker.id === cBiomarker.biomarkerId;
      });
    });
  }, [biomarkers.items, createBiomarkersArr, editBiomarkersArr]);

  const handleFetchSelectedBiomarkerUnits = async () => {
    if (!selectedBiomarker) {
      return;
    }
    try {
      const response = await storageApi.getBiomarkersUnits({
        requests: [selectedBiomarker.id],
      });
      setBiomarkerUnits(response);
      formContext.setValue(formHasUnitsName, !!response.length);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (initialBiomarker) {
      const {biomarkerObj} = initialBiomarker;
      setSelectedBiomarker(biomarkerObj);
    }
  }, [initialBiomarker]);

  useEffect(() => {
    void handleFetchBiomarkers();
  }, [debouncedSearchValue]);

  useEffect(() => {
    void handleFetchSelectedBiomarkerUnits();
  }, [selectedBiomarker]);

  const handleBiomarkerChange = (value: string) => {
    if (value === selectedBiomarker?.id) {
      return selectedBiomarker?.name || '';
    }
    const newBiomarker = filteredBiomarkers.find((item) => item.id === value);
    if (newBiomarker) {
      handleChangeSelectedBiomarker(newBiomarker);
      return newBiomarker.name;
    }
    return '';
  };

  const formBiomarkerId = formContext.getValues(formBiomarkerIdName);
  useEffect(() => {
    handleBiomarkerChange(formBiomarkerId);
  }, [formBiomarkerId]);

  return (
    <Stack sx={sx.container}>
      <Stack flexDirection={'row'} gap={12} flex={1}>
        <FormSelectControlNew
          name={formBiomarkerIdName}
          sx={sx.biomarkerSelect}
          options={filteredBiomarkers.map((option) => ({
            value: option.id,
            label: option.name,
          }))}
          mobileHeader={t('BIOMARKERS')}
          placeholder={t('SELECT_BIOMARKER')}
          disabled={isEdit}
          onReachEnd={() => {
            void handleFetchBiomarkers(true);
          }}
          onReachEndThreshold={1000}
          onSearch={handleChangeSearchValue}
          onClose={() => {
            handleChangeSearchValue('');
          }}
          initialLabel={biomarker.biomarkerName}
          data-test-id={testId(testIdFeatureGroup + BIOMARKER_DATA_TEST_ID.biomarkerNameSelect)}
        >
          {filteredBiomarkers.length
            ? (
              filteredBiomarkers.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  data-test-id={testId(
                    testIdFeatureGroup + BIOMARKER_DATA_TEST_ID.biomarkerNameSelectDropdownItem,
                  )}
                >
                  {option.name}
                </MenuItem>
              ))
            )
            : (
              <ActionsRow>
                <Typography variant="14_18_500">{t('NO_SUITABLE_RESULTS')}</Typography>
              </ActionsRow>
            )}
        </FormSelectControlNew>

        {isMobile && !isEdit && (
          <IconButtonNew
            onClick={onRemove}
            size={'lg'}
            color={'tertiary'}
            data-test-id={testId(testIdFeatureGroup + BIOMARKER_DATA_TEST_ID.biomarkerDeleteButton)}
          >
            <DeleteIcon />
          </IconButtonNew>
        )}
      </Stack>
      <Stack sx={sx.wrapperForm}>
        <FormInputControl
          name={formValueName}
          placeholder={(!selectedBiomarker.id || isEdit) && !isMobile ? '' : t('VALUE')}
          label={isMobile ? '' : t('VALUE')}
          compact={false}
          sx={sx.valueInput}
          noClearHandler
          showErrorText={false}
          disabled={!selectedBiomarker.id || isEdit}
          maxlength={MAX_STORAGE_BIOMARKER_VALUE_LENGTH}
          max={MAX_STORAGE_BIOMARKER_VALUE_LENGTH}
          data-test-id={testId(testIdFeatureGroup + BIOMARKER_DATA_TEST_ID.biomarkerValueInput)}
        />
        <FormSelectControlNew
          name={formBiomarkerUnitIdName}
          disabled={!biomarkerUnits.length || isEdit}
          sx={sx.unitSelect}
          noMobileView
          options={biomarkerUnits.map((option) => ({
            value: option.id,
            label: option.name,
          }))}
          showErrorText={false}
          data-test-id={testId(testIdFeatureGroup + BIOMARKER_DATA_TEST_ID.biomarkerUnitSelect)}
        >
          {biomarkerUnits.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              data-test-id={testId(
                testIdFeatureGroup + BIOMARKER_DATA_TEST_ID.biomarkerUnitSelectDropdownItem,
              )}
            >
              <span>{option.name}</span>
            </MenuItem>
          ))}
        </FormSelectControlNew>
      </Stack>
      {!isMobile && !isEdit && (
        <IconButtonNew
          onClick={onRemove}
          size={'lg'}
          color={'tertiary'}
          data-test-id={testId(testIdFeatureGroup + BIOMARKER_DATA_TEST_ID.biomarkerDeleteButton)}
        >
          <DeleteIcon />
        </IconButtonNew>
      )}
    </Stack>
  );
};
