import {Box, Stack, Avatar, Typography} from '@mui/material';
import {Link} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {SidebarTabTetrary} from '@src/components/SidebarTabTetrary';
import {Tab, Tabs, useTabs} from '@src/components/TabPanel';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {RELATIONS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ResultIcon} from '@src/shared/assets/icons/24x24/results.svg';
import {ReactComponent as ArrowLeftIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {ReactComponent as InfoIcon} from '@src/shared/assets/icons/avatar.svg';
import {ReactComponent as RelationsIcon} from '@src/shared/assets/icons/relations-icon.svg';
import {getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {testId} from '@src/shared/utils/testId';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {useEffect, useState} from 'react';
import {Outlet, useLocation, useMatch, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {BreakRelationModal} from '../BreakRelationModal';
import {ChangeAccessModal} from '../ChangeAccessModal';
import {tabIndexes} from '../constants';
import {createNavigateHandler} from '../helpers';
import {MainTabs, SubTabs, TabValues} from '../types';

import {sx} from './styles';

export const RelationDesktopLayout = () => {
  const {t, ready} = useTranslate('connections');

  const {id} = useParams();
  const {relationId, fetching} = useContactRelations(id);
  const {relation} = useRelation(relationId);

  const location = useLocation();
  const navigate = useNavigate();

  const routes = {
    PERSONAL: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT),
    PERSONAL_EDIT: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT_EDIT),
    CONTACTS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_CONTACTS),
    HEALTH_POLICIES: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES),
    RELATIONS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RELATIONS),
    RESULTS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS),
    RESULT_DETAILS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DETAILS),
    RESULT_DYNAMICS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DYNAMICS),
  };
  const {index, handleChange: tabChange} = useTabs(
    tabIndexes[location.pathname.split('/').at(-1) ?? 'about'],
  );
  const [activeTab, setActiveTab] = useState(MainTabs.INFO);
  const [activeSubTab, setActiveSubTab] = useState(SubTabs.PERSONAL);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteAccessModalOpen, setIsDeleteAccessModalOpen] = useState(false);

  const isActiveResultsMainTab = routes.RESULTS || routes.RESULT_DETAILS || routes.RESULT_DYNAMICS;

  const isInfoMainTabActive = (activeSubTab: SubTabs, activeTab: MainTabs) =>
    ['about', 'health-policies', 'contacts'].includes(activeSubTab) && ['info'].includes(activeTab);

  const handleOpenDeleteAccessModal = () => {
    setIsDeleteAccessModalOpen(true);
  };

  const handleClickBthBreakRelation = () => {
    setIsModalOpen((prev) => !prev);
  };

  const navigateHandler = createNavigateHandler(navigate, id);

  useEffect(() => {
    if (routes.PERSONAL) {
      setActiveTab(MainTabs.INFO);
      setActiveSubTab(SubTabs.PERSONAL);
    } else if (routes.CONTACTS) {
      setActiveTab(MainTabs.INFO);
      setActiveSubTab(SubTabs.CONTACTS);
    } else if (routes.HEALTH_POLICIES) {
      setActiveTab(MainTabs.INFO);
      setActiveSubTab(SubTabs.HEALTH_POLICIES);
    } else if (routes.RELATIONS) {
      setActiveTab(MainTabs.RELATIONS);
    } else if (isActiveResultsMainTab) {
      setActiveTab(MainTabs.RESULTS);
    }
  }, [location]);

  if (!ready) return null;

  if (!relation && relationId === undefined && !fetching) {
    navigate(ROUTERS_PATH.NOT_FOUND);
    return null;
  }

  return (
    <Stack sx={sx.wrapper}>
      <Box sx={sx.boxContainer}>
        <Stack sx={sx.breadCrumbs}>
          <ArrowLeftIcon />
          <Link
            to={ROUTERS_PATH.CONNECTIONS_RELATIONS}
            data-test-id={testId(RELATIONS_DATA_TEST_ID.relationBreadcrumbsBackLink)}
          >
            {t('BACK_TO_ALL_RELATIONS')}
          </Link>
        </Stack>
        <Box sx={sx.breakRelationContainer}>
          <Button
            sx={sx.breakRelationBth}
            size="xs"
            variant="text"
            color="secondary"
            onClick={handleClickBthBreakRelation}
            data-test-id={testId(RELATIONS_DATA_TEST_ID.relationBreakRelationButton)}
          >
            {t('BREAK_RELATION')}
          </Button>
          <Typography typography="18_24_500">{t('WITH')}</Typography>
          <Box sx={sx.avatarBoxContainer}>
            <Avatar sx={sx.avatar} src={relation?.avatarUrl} alt={''}>
              <Typography variant="12_16_700">{getUserInitials(relation?.contact)}</Typography>
            </Avatar>
            <EllipsisTextTooltip
              id="relation-contact"
              ellipsisText={getName(relation?.contact, {short: true})}
              component="p"
              variant="18_24_500"
            />
            <Typography variant="18_24_500">{'?'}</Typography>
          </Box>
          <Button
            sx={sx.changeAccessBth}
            size="sm"
            variant="outlined"
            color="secondary"
            onClick={handleOpenDeleteAccessModal}
            data-test-id={testId(RELATIONS_DATA_TEST_ID.relationChangeAccessButton)}
          >
            {t('CHANGE_ACCESS')}
          </Button>
        </Box>
      </Box>
      <Stack flexDirection="row" height="calc(100% - 56px)">
        <Box sx={sx.mainTabsContainer}>
          <>
            <SidebarTabTetrary
              id="tab-info"
              label={t('INFO')}
              icon={<InfoIcon width={24} height={24} />}
              isActive={isInfoMainTabActive(activeSubTab, activeTab)}
              onClick={navigateHandler(MainTabs.INFO)}
              data-test-id={testId(RELATIONS_DATA_TEST_ID.relationInfoTab)}
            />
            <SidebarTabTetrary
              id="tab-relations"
              label={t('RELATIONS')}
              icon={<RelationsIcon />}
              isActive={activeTab === MainTabs.RELATIONS}
              onClick={navigateHandler(MainTabs.RELATIONS)}
              data-test-id={testId(RELATIONS_DATA_TEST_ID.relationRelationsTab)}
            />
            <SidebarTabTetrary
              id="tab-results"
              label={t('RESULTS')}
              icon={<ResultIcon />}
              isActive={activeTab === MainTabs.RESULTS}
              onClick={navigateHandler(MainTabs.RESULTS)}
              data-test-id={testId(RELATIONS_DATA_TEST_ID.relationResultsTab)}
            />
          </>
        </Box>
        <WBox sx={sx.container}>
          {activeTab === MainTabs.INFO && (
            <Tabs
              value={index}
              scrollButtons={false}
              onChange={tabChange}
              sx={sx.tabsContainer}
              variant="fullWidth"
            >
              <Tab
                label={t('PERSONAL')}
                value={TabValues.PERSONAL}
                isActive={!!routes.PERSONAL}
                sx={sx.tab}
                onClick={navigateHandler(SubTabs.PERSONAL)}
                data-test-id={testId(RELATIONS_DATA_TEST_ID.relationInfoPersonalTab)}
              />
              <Tab
                value={TabValues.CONTACTS}
                label={t('CONTACTS')}
                isActive={!!routes.CONTACTS}
                sx={sx.tab}
                onClick={navigateHandler(SubTabs.CONTACTS)}
                data-test-id={testId(RELATIONS_DATA_TEST_ID.relationInfoContactsTab)}
              />
              <Tab
                value={TabValues.HEALTH_POLICIES}
                label={t('HEALTH_POLICIES')}
                isActive={!!routes.HEALTH_POLICIES}
                sx={sx.tab}
                onClick={navigateHandler(SubTabs.HEALTH_POLICIES)}
                data-test-id={testId(RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesTab)}
              />
            </Tabs>
          )}
          <Stack
            id="scroll-container"
            sx={[
              ...spreadSx(sx.contentContainer),
              {
                mt: isInfoMainTabActive(activeSubTab, activeTab) ? 36 : 0,
              },
            ]}
          >
            <Stack sx={sx.outletContainer}>
              <Outlet />
            </Stack>
          </Stack>
        </WBox>
      </Stack>
      <BreakRelationModal
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        contactRelationId={id}
      />
      <ChangeAccessModal
        isOpen={isDeleteAccessModalOpen}
        onClose={() => setIsDeleteAccessModalOpen(false)}
      />
    </Stack>
  );
};
