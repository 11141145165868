import {Typography, Stack} from '@mui/material';
import {DatePickerRange, SelectLabels} from '@src/components';
import Grid from '@src/components/Grid';
import {MultiSelect} from '@src/components/MultiSelect';
import {FiltersDialog} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {RESULTS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/resultTestIds';
import {TIME_PERIOD_OPTIONS} from '@src/pages/Dynamics/Dynamics.constant';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {FC} from 'react';

import {ResultFilterDialogProps} from './types';

export const ResultFilterDialog: FC<ResultFilterDialogProps> = ({
  filterOpened,
  onApply,
  onClear,
  timePeriod,
  changeTimePeriod,
  handleChangeDate,
  clearDate,
  testsOptions,
  changeTestCodes,
  onClose,
  filter,
}) => {
  const {t} = useTranslate('details');
  const {desktop, lg} = useMQuery();

  const onMiddleSizeWidth = !lg && desktop;

  return (
    <FiltersDialog
      isOpen={filterOpened}
      onClose={onClose}
      title={t('FILTERS')}
      onApply={onApply}
      onClear={onClear}
      testIdFeatureGroup={testId(RESULTS_DATA_TEST_ID.dynamicsFiltersDialog)}
    >
      <Stack gap={24}>
        <Grid xs={12} sm={6}>
          {!onMiddleSizeWidth && <Typography variant="14_18_700">{t('TIME_PERIOD')}</Typography>}
          <SelectLabels
            value={timePeriod ?? 'Select'}
            onChange={changeTimePeriod}
            options={TIME_PERIOD_OPTIONS}
            contentPaddingLeft="103px"
            label={onMiddleSizeWidth ? t('TIME_PERIOD') : ''}
            testIdFeatureGroup={testId(RESULTS_DATA_TEST_ID.dynamicsFiltersDialogPeriod)}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          {!onMiddleSizeWidth && <Typography variant="14_18_700">{t('REPORT_DATE')}</Typography>}
          <DatePickerRange
            label={onMiddleSizeWidth ? t('REPORT_DATE') : ''}
            variant="outlined"
            startDate={filter.start}
            endDate={filter.end}
            startAdornmentName="Date"
            setStartDate={handleChangeDate('start')}
            setEndDate={handleChangeDate('end')}
            clearDate={clearDate}
            isLabelOutside={false}
            isIconVisible={false}
            dataTestId={testId(RESULTS_DATA_TEST_ID.dynamicsFiltersDialogDateRange)}
          />
        </Grid>
        <Grid xs={12} sm={6} mt={onMiddleSizeWidth ? 0 : 24}>
          <MultiSelect
            value={filter.testCodes}
            label={t('TESTS')}
            placeholder={t('SELECT')}
            options={testsOptions}
            onChange={changeTestCodes}
            testIdFeatureGroup={testId(RESULTS_DATA_TEST_ID.dynamicsFiltersDialogTests)}
          />
        </Grid>
      </Stack>
    </FiltersDialog>
  );
};
