// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {css as _, ButtonBase, Box, Typography, Stack} from '@mui/material';
import {Result} from '@src/api';
import {FullContainerLoader} from '@src/components/FullContainerLoader';
import {Switch} from '@src/components/Switch';
import {WhiteBox} from '@src/components/WhiteBox';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {HealthCaseSelector} from '@src/features/HealthCaseSelector';
import {RESULTS_DATA_TEST_ID} from '@src/pages/Results/testIds';
import {ReactComponent as ChevronDownIcon} from '@src/shared/assets/icons/chevron-down.svg';
import {ReactComponent as AddIcon} from '@src/shared/assets/icons/plus.svg';
import {SEARCH_PARAMS} from '@src/shared/constants/searchParams';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useScrollToTop} from '@src/shared/hooks/useScrollToTop';
import {testId} from '@src/shared/utils/testId';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {EmptySearch, Table, ITableScrollUp, PageHeader} from 'components';
import {BoxBody, BoxPaddings} from 'components/WhiteBox/WhiteBox';
import {useTranslate} from 'i18n/useTranslate';
import {isEqual} from 'lodash-es';
import {useEffect, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useResults} from 'store/results/hooks';
import {Button} from 'ui-kit';

import {useHeader, initialFilter, initiialSort, IFilterResults} from './Results.constant';
import {sortByDate} from './Results.helpers';
import {AddResultDialog} from './components/AddResultDialog';
import {ResultsFilters} from './components/ResultsFilters/ResultsFilters';
import {ResultsTable} from './components/ResultsTable/ResultsTable';
import {TableRow} from './components/TableRow';
import {sx} from './styles';

export const Results = () => {
  const {t, ready} = useTranslate('results');
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = useState(initiialSort);
  const {data, filteredResults, isLoading} = useResults();
  const [results, setResults] = useState<Result[]>();
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const {mobile, desktop, tabletPortrait, tablet} = useMQuery();
  const [filterOpened, setFilterOpened] = useState(false);
  const refTable = useRef<ITableScrollUp>();
  const [allExpanded, setAllExpanded] = useState(false);
  const [collectedPosition, setCollectedPosition] = useState(false);
  const selectedResults = useAppSelector((state) => state.healthCases.selectedResults);
  const {isSidebarOpen} = useSidebarClassObserver();

  const isInitialAddResultDialogOpened = !!searchParams.get(SEARCH_PARAMS.ADD_RESULT);
  const [isAddResultDialogOpen, setIsAddResultDialogOpen] = useState(isInitialAddResultDialogOpened);
  const handleToggleAddResultDialogOpen = () => setIsAddResultDialogOpen((prev) => !prev);
  const handleCloseAddResultDialog = () => setIsAddResultDialogOpen(false);

  const dispatch = useAppDispatch();

  const isFiltersEmpty = isEqual(filter, initialFilter);

  const handleCloseFilter = () => setFilterOpened(false);
  const handleOpenFilter = () => setFilterOpened(true);

  const handleSetFilter = (newValue: IFilterResults) => {
    setFilter(newValue);
  };

  const handleSetResults = (newValue: Result[]) => {
    setResults(newValue);
    setIsLocalLoading(false);
  };

  useEffect(() => {
    handleSetResults(filteredResults);
  }, [filteredResults]);

  useEffect(() => {
    if (isLoading) {
      setIsLocalLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (results) {
      const arrayForSort = [...results];
      handleSetResults(arrayForSort.sort((a, b) => sortByDate(a, b, sort)));
    }
  }, [sort]);

  const filterResultByDate = (columnName: string) => () => {
    setSort((prev) => ({...prev, sortBy: columnName, sortDesc: !prev.sortDesc}));
    if (columnName === 'collected') setCollectedPosition((prev) => !prev);
  };

  const {HEADER_LIST} = useHeader(filterResultByDate, collectedPosition);

  const handleExpandAll = () => {
    setAllExpanded((prev) => {
      return !prev;
    });
  };

  useScrollToTop();

  const testLoading = isLoading || isLocalLoading || (isFiltersEmpty && !results?.length);

  const isResultsNotExists = !isFiltersEmpty && !results?.length && !isLocalLoading;

  const disabledSettingsButton = isFiltersEmpty && !results?.length && !isLocalLoading;

  useEffect(() => {
    return () => {
      dispatch(healthCasesActions.clearSelectedResults());
    };
  }, []);

  const onPortrait = mobile || tabletPortrait;
  const paddingSize = desktop ? '36px 24px' : '14px';

  const calcPaddingLeft = () => {
    if (tablet && !tabletPortrait) return '24px';
    if (tabletPortrait) return '30px';
    if (mobile) return '52px';
    return '36px';
  };

  const paddingLeft = calcPaddingLeft();
  const height = desktop ? 'calc(100vh - 348px)' : 'calc(100vh - 315px)';
  const paddingBoxBottom = onPortrait ? '50px' : '0px';

  const renderAddResultsToHealthCase = () => {
    return (
      <Typography variant="14_18_500">
        {t(selectedResults.size > 1 ? 'ADD_COUNT_RESULTS' : 'ADD_COUNT_RESULT', {
          count: selectedResults.size || '',
        })}
      </Typography>
    );
  };

  const addResultsToHealthCaseSelector = renderAddResultsToHealthCase();

  const renderSettingsButton = () => {
    if (!onPortrait || disabledSettingsButton) {
      return null;
    }

    return <SettingsButton disabled={disabledSettingsButton} onClick={handleOpenFilter} dataTestId={RESULTS_DATA_TEST_ID.resultsFiltersOpenFiltersButton} />;
  };

  const renderHeader = () => {
    return (
      <Box sx={sx.headerContainer}>
        <PageHeader height={'auto'}>{t('RESULTS')}</PageHeader>
        {!onPortrait && (
          <Stack flexDirection={'row'} gap={12}>
            <Button
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleToggleAddResultDialogOpen}
              data-test-id={testId(RESULTS_DATA_TEST_ID.resultsHeaderAddResultButton)}
            >
              {t('ADD_RESULT')}
            </Button>
            <HealthCaseSelector enableDisabledBtn={false} testIdFeatureGroup={RESULTS_DATA_TEST_ID.results}>
              <Stack sx={sx.contentHealthCaseSelector}>
                {addResultsToHealthCaseSelector}
                <ChevronDownIcon />
              </Stack>
            </HealthCaseSelector>
          </Stack>
        )}
        {renderSettingsButton()}
      </Box>
    );
  };

  const renderDialogAndActions = () => {
    return (
      <>
        {onPortrait && (
          <Box sx={sx.fixedButtonContainer} zIndex={isSidebarOpen ? 0 : 1030}>
            <Button
              color="secondary"
              onClick={handleToggleAddResultDialogOpen}
              data-test-id={testId(RESULTS_DATA_TEST_ID.resultsHeaderAddResultButton)}
            >
              {t('ADD_RESULT')}
            </Button>
            <HealthCaseSelector externalIsMobile enableDisabledBtn={false} testIdFeatureGroup={RESULTS_DATA_TEST_ID.results}>
              <Stack sx={sx.caseSelectorWrapper}>{addResultsToHealthCaseSelector}</Stack>
            </HealthCaseSelector>
          </Box>
        )}
        <AddResultDialog isOpen={isAddResultDialogOpen} onClose={handleCloseAddResultDialog} />
      </>
    );
  };

  if (!ready || isLocalLoading) {
    return (
      <FullContainerLoader/>
    );
  }

  if (data?.length === 0 && !isLoading) {
    return (
      <>
        <Stack sx={{display: 'flex', flexGrow: 1, gap: 24}}>
          {renderHeader()}
          <WhiteBox noPaddings sx={{display: 'flex', flexGrow: 1}}>
            <BoxPaddings sx={{padding: paddingSize}}>
              <Typography variant="14_18_500" color={'grey.500'}>
                {t('NO_RESULTS')}
              </Typography>
            </BoxPaddings>
          </WhiteBox>
        </Stack>
        {renderDialogAndActions()}
      </>
    );
  }

  return (
    <>
      {renderHeader()}
      <WhiteBox noPaddings sx={{display: 'flex', flexGrow: 1, mb: paddingBoxBottom}}>
        <BoxBody>
          {onPortrait && !isResultsNotExists && (
            <ButtonBase
              data-test-id={testId(RESULTS_DATA_TEST_ID.resultsTableExpandAllButton)}
              onClick={handleExpandAll}
              css={_`
                width: 100%;
                display: flex;
                justify-content: space-between;
                height: 58px;
                align-items: center;
                padding: 0 16px;
              `}
            >
              <Typography variant="14_18_500">{t('EXPAND_ALL')}</Typography>
              <Switch css={_`margin-right: -12px;`} readOnly checked={allExpanded}/>
            </ButtonBase>
          )}
          <ResultsFilters
            handleCloseFilter={handleCloseFilter}
            handleOpenFilter={handleOpenFilter}
            handleSetFilter={handleSetFilter}
            isOpen={filterOpened}
            filter={filter}
            data={data}
          />
          {!onPortrait && (
            <Box sx={{height, marginTop: '0px'}}>
              <ResultsTable result={results} isLoading={testLoading} isResults/>
            </Box>
          )}
          {onPortrait && (
            <Box sx={sx.tableWrapper}>
              <Table
                css={_`margin-top: -1px`}
                classNames={{header: 'table-header'}}
                ref={refTable}
                gridTemplateColumns={onPortrait ? '20px 4fr 3fr 1fr' : '1fr 1fr 1fr 0.5fr'}
                data={results || []}
                headerList={HEADER_LIST}
                loading={false}
                rowComponent={{
                  component: TableRow,
                  props: {
                    allExpanded,
                    testIdFeatureGroup: RESULTS_DATA_TEST_ID.resultsTable,
                    expandableTemplateColumn: {
                      gridTemplateColumns: '5fr 5fr',
                      padding: '12px 40px 0px',
                      columnGap: '40px',
                    },
                  },
                }}
                paddingLeft={paddingLeft}
                emptyData={
                  <Box
                    sx={{
                      padding: paddingSize,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <EmptySearch isLoading={testLoading} isNew isResults/>
                  </Box>
                }
              />
            </Box>
          )}
        </BoxBody>
      </WhiteBox>
      {renderDialogAndActions()}
    </>
  );
};
