// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {css, Grid, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {FilterWrapper, SelectLabels} from '@src/components';
import {DateRangeButton} from '@src/components/DateRangeButton';
import {MultiSelect} from '@src/components/MultiSelect';
import {BoxPaddings} from '@src/components/WhiteBox';
import {RESULTS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/resultTestIds';
import {TIME_PERIOD_OPTIONS} from '@src/pages/Dynamics/Dynamics.constant';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {useTranslate} from 'i18n/useTranslate';
import {FC} from 'react';

import {ResultDynamicsFiltersProps} from './types';

export const ResultDynamicsFilters: FC<ResultDynamicsFiltersProps> = ({
  onApply,
  onClear,
  timePeriod,
  changeTimePeriod,
  start,
  end,
  handleChangeDate,
  clearDate,
  inputTitlePosition,
  selectedTests,
  testsOptions,
  changeTestCodes,
}) => {
  const {t} = useTranslate('details');
  const {xl} = useMQuery();

  const rowFilter = {
    display: 'flex',
    flexDirection: xl ? 'column' : 'row',
    gap: 4,
  };

  return (
    <>
      <BoxPaddings paddings="24px" noTop>
        <FilterWrapper
          onApply={onApply}
          onClear={onClear}
          isMotionButton={true}
          sxRefreshButton={{
            padding: 0,
          }}
          css={css`
            label .date_picker {
              width: 100%;
            }
          `}
          refreshButtonDataTestId={testId(RESULTS_DATA_TEST_ID.dynamicsFiltersRefreshButton)}
        >
          <Grid container flexGrow={1} sx={{gap: 12, alignItems: 'end'}}>
            <Grid
              xs={4}
              lg={4}
              xl={4}
              sx={rowFilter}>
              <Stack justifyContent={inputTitlePosition}>
                <Typography variant="14_18_700" width={95}>
                  {t('TIME_PERIOD')}
                </Typography>
              </Stack>
              <SelectLabels
                value={timePeriod ?? 'Select'}
                onChange={changeTimePeriod}
                options={TIME_PERIOD_OPTIONS}
                contentPaddingLeft="103px"
                testIdFeatureGroup={testId(RESULTS_DATA_TEST_ID.dynamicsFiltersPeriod)}
              />
            </Grid>
            <Grid pr={24}>
              <DateRangeButton
                startDate={start}
                endDate={end}
                setStartDate={handleChangeDate('start')}
                setEndDate={handleChangeDate('end')}
                clearDate={clearDate}
                buttonDataTestId={testId(RESULTS_DATA_TEST_ID.dynamicsFiltersDateRangeButton)}
              />
            </Grid>
            <Grid
              xs={4}
              lg={4}
              xl={4}
              sx={rowFilter}>
              <Stack justifyContent={inputTitlePosition}>
                <Typography variant="14_18_700" width={50}>
                  {t('TESTS')}
                </Typography>
              </Stack>
              <MultiSelect
                value={selectedTests}
                label={t('TESTS')}
                placeholder={t('SELECT')}
                options={testsOptions}
                onChange={changeTestCodes}
                ignoreLabelHeight
                testIdFeatureGroup={testId(RESULTS_DATA_TEST_ID.dynamicsFiltersTestsSelect)}
              />
            </Grid>
          </Grid>
        </FilterWrapper>
      </BoxPaddings>
    </>
  );
};
