import {Checkbox, FormControlLabel, FormGroup, Grid, Typography} from '@mui/material';
import {RelationTypeEnum} from '@src/api';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {getRelationTypes} from '@src/pages/Connections/fragments/Relations/helpers/functionHelpers';
import {RELATIONS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as Error} from '@src/shared/assets/icons/error.svg';
import {ReactComponent as Success} from '@src/shared/assets/icons/success.svg';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {useAppSelector} from '@src/store';
import {accountActions} from '@src/store/account/slice';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {Steps} from '@src/types';
import {ChangeEvent, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {BreakRelationModalProps} from './types';

export const BreakRelationModal = ({
  open,
  setIsModalOpen,
  contactRelationId,
}: BreakRelationModalProps) => {
  const {t, ready} = useTranslate('connections');

  const {mobile: isMobile} = useMQuery();

  const {relationId, groupedRelations} = useContactRelations(contactRelationId);
  const {relation} = useRelation(relationId);

  const [relationTypes, setRelationTypes] = useState<RelationTypeEnum[]>([]);

  const types = getRelationTypes(groupedRelations, relation);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {step, relations, brokeRelationsStatus} = useAppSelector((state) => state.relations);

  const deletedSuccessManyRelations =
    Object.values(brokeRelationsStatus ?? {}).length > 1
      ? t('DELETED_SUCCESS_MANY')
      : t('DELETED_SUCCESS');

  const deletedErrorManyRelations =
    relationTypes.length === 1
      ? t('FAILED_TO_BREAK_RELATION', {
        name: relationTypes.map((relation) => t(relation).toLowerCase()),
      })
      : t('DELETED_FAILURE');

  const confirmDeletedManyRelations =
    relationTypes.length > 1 ? t('ARE_YOU_SURE_MANY') : t('ARE_YOU_SURE');

  const handleModalCondition = () => {
    setIsModalOpen((prev) => !prev);
    setRelationTypes([]);
  };
  const goToConfirmModal = () => {
    setIsModalOpen(false);
    dispatch(relationsActions.setStep({step: Steps.OPEN}));
  };
  const closeConfirmModal = () => {
    if (step !== Steps.LOADING) {
      setRelationTypes([]);
      dispatch(relationsActions.setStep({step: Steps.INIT}));
    }
  };
  const closeFinalModal = () => {
    dispatch(relationsActions.setStep({step: Steps.INIT}));
    setRelationTypes([]);
    const index = groupedRelations.findIndex((item) => item.contact.id === contactRelationId);
    if (!groupedRelations[index]?.types?.length) {
      navigate(ROUTERS_PATH.CONNECTIONS_RELATIONS);
    }
  };

  const deleteRelation = () => {
    dispatch(relationsActions.setStep({step: Steps.LOADING}));
    if (relations && contactRelationId) {
      dispatch(
        relationsActions.requestDeleteRelation({
          relations,
          types: relationTypes,
          relationId: contactRelationId,
        }),
      );
    }
  };

  const setTypes = (newType: RelationTypeEnum, event: ChangeEvent<HTMLInputElement>) => {
    const isTypeChecked = event.target.checked;
    if (!isTypeChecked) {
      const newTypes = relationTypes.filter((type) => type !== newType);
      setRelationTypes(newTypes);
    } else if (newType) {
      setRelationTypes((prev) => [...prev, newType]);
    }
  };

  const isConfirmModalOpened = step === Steps.OPEN || step === Steps.LOADING;
  const isFinalModalOpened = step === Steps.SUCCESS || step === Steps.ERROR;

  const modalSize = isMobile ? 'xs' : 'sm';
  const dialogTitleWidth = isMobile ? '50px' : '64px';
  const rowGap = isMobile ? 36 : 16;

  useEffect(() => {
    if (isFinalModalOpened) dispatch(accountActions.requestAccountProfileData());
  }, [dispatch, isFinalModalOpened, step]);

  if (!ready) return null;
  return (
    <>
      <Dialog
        border="none"
        open={open}
        onClose={handleModalCondition}
        size={modalSize}
        data-test-id={testId(RELATIONS_DATA_TEST_ID.relationBreakRelationDialog)}
      >
        <DialogTitle
          onClose={handleModalCondition}
          width={dialogTitleWidth}
          noContent={false}
          closeButtonDataTestId={
            RELATIONS_DATA_TEST_ID.relationBreakRelationDialogHeaderCloseButton
          }
        >
          {t('BREAK_RELATION')}
        </DialogTitle>
        <DialogContent sx={sx.breakRelationContent}>
          <FormGroup>
            <Grid rowGap={rowGap} container>
              {types?.map((type) => {
                return (
                  <Grid sm={6} xs={12} key={type}>
                    <FormControlLabel
                      sx={sx.formControl}
                      control={
                        <Checkbox
                          onChange={(event) => setTypes(type, event)}
                          checked={!!relationTypes.includes(type as RelationTypeEnum)}
                          data-test-id={testId(
                            RELATIONS_DATA_TEST_ID.relationBreakRelationDialogTypeCheckbox,
                          )}
                        />
                      }
                      label={t(type)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </FormGroup>
        </DialogContent>
        <DialogActions sx={sx.breakRelationActions}>
          <Button
            fullWidth={isMobile}
            variant="outlined"
            color="secondary"
            onClick={handleModalCondition}
            data-test-id={testId(
              RELATIONS_DATA_TEST_ID.relationBreakRelationDialogFooterCancelButton,
            )}
          >
            {t('CANCEL')}
          </Button>
          <Button
            fullWidth={isMobile}
            variant="contained"
            color="secondary"
            disabled={relationTypes.length < 1}
            onClick={goToConfirmModal}
            data-test-id={testId(
              RELATIONS_DATA_TEST_ID.relationBreakRelationDialogFooterBreakButton,
            )}
          >
            {t('BREAK')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        border="none"
        open={isConfirmModalOpened}
        onClose={closeConfirmModal}
        size={modalSize}
        data-test-id={testId(RELATIONS_DATA_TEST_ID.relationDeleteRelationDialog)}
      >
        <DialogTitle
          onClose={closeConfirmModal}
          width={dialogTitleWidth}
          noContent={false}
          closeButtonDataTestId={
            RELATIONS_DATA_TEST_ID.relationDeleteRelationDialogHeaderCloseButton
          }
        >
          {t('DELETE_RELATION')}
        </DialogTitle>
        <DialogContent sx={sx.deleteRelationContent}>
          <Typography variant="16_20_500">{confirmDeletedManyRelations}</Typography>
        </DialogContent>
        <DialogActions sx={sx.deleteRelationActions}>
          <Button
            fullWidth={isMobile}
            variant="outlined"
            color="secondary"
            disabled={step === Steps.LOADING}
            onClick={closeConfirmModal}
            data-test-id={testId(RELATIONS_DATA_TEST_ID.relationDeleteRelationDialogFooterNoButton)}
          >
            {t('NO')}
          </Button>
          <Button
            fullWidth={isMobile}
            variant="contained"
            color="secondary"
            disabled={step === Steps.LOADING}
            onClick={deleteRelation}
            data-test-id={testId(
              RELATIONS_DATA_TEST_ID.relationDeleteRelationDialogFooterYesButton,
            )}
          >
            {t('YES')}
          </Button>
        </DialogActions>
      </Dialog>
      {step === Steps.ERROR && (
        <Dialog
          sx={sx.dialogError}
          open={isFinalModalOpened}
          onClose={closeFinalModal}
          size={modalSize}
          data-test-id={testId(RELATIONS_DATA_TEST_ID.relationErrorDialog)}
        >
          <DialogTitle
            width="64px"
            onClose={closeFinalModal}
            noContent={false}
            sx={sx.dialogTitleError}
            closeButtonDataTestId={RELATIONS_DATA_TEST_ID.relationErrorDialogHeaderCloseButton}
          >
            <Error />
            {t('ERROR_EXCLAMATION')}
          </DialogTitle>
          <DialogContent sx={sx.confirmDeleteContent}>
            <Typography variant="14_18_500">{deletedErrorManyRelations}</Typography>
          </DialogContent>
          <DialogActions sx={sx.dialogActionsError} onClose={closeFinalModal}>
            <Button
              fullWidth={isMobile}
              sx={sx.bth}
              variant="contained"
              color="inherit"
              onClick={closeFinalModal}
              data-test-id={testId(RELATIONS_DATA_TEST_ID.relationErrorDialogFooterOkButton)}
            >
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {step === Steps.SUCCESS && (
        <Dialog
          open={isFinalModalOpened}
          onClose={closeFinalModal}
          size={modalSize}
          data-test-id={testId(RELATIONS_DATA_TEST_ID.relationSuccessDialog)}
        >
          <DialogTitle
            width="64px"
            sx={sx.dialogTitleSuccess}
            onClose={closeFinalModal}
            noContent={false}
            closeButtonDataTestId={RELATIONS_DATA_TEST_ID.relationSuccessDialogHeaderCloseButton}
          >
            <Success />
            {t('SUCCESS_EXCLAMATION')}
          </DialogTitle>
          <DialogContent sx={sx.confirmDeleteContent}>
            <Typography variant="14_18_500">{deletedSuccessManyRelations}</Typography>
          </DialogContent>
          <DialogActions sx={sx.dialogActionsSuccess} onClose={closeFinalModal}>
            <Button
              fullWidth={isMobile}
              sx={sx.bth}
              variant="contained"
              color="inherit"
              onClick={closeFinalModal}
              data-test-id={testId(RELATIONS_DATA_TEST_ID.relationSuccessDialogFooterOkButton)}
            >
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
