/* eslint-disable no-comments/disallowComments */
import {RelationElement, DynamicsTest, ResultDetail} from '@api';
import {DynamicItem, DynamicSearchRequest} from '@src/api/__generated__/webApi';
import {getRecaptchaToken} from '@src/shared/hooks/useRecaptcha';
import {UUID} from '@src/types';
import {apiClient} from 'client/ApiClient';

import {
  EditRelationAddressData,
  EditRelationContactData,
  Page,
  RelationContactBatchCreateRequest,
  RelationContactSearch,
  RelationHealthcareInsurancePolicyChangeRequest,
  RelationProfile,
  RelationRequestCreateRequest,
  RelationRequests,
  Result,
  SendResultsByEmailRequestI,
} from './models';

export const getRelations = async () => {
  const response = await apiClient.get<RelationElement[]>('/api/relations');
  return response.data;
};

export const getRelationById = async ({relationId}: {relationId: string}) => {
  const response = await apiClient.get<RelationProfile>(`/api/relations/${relationId}`);
  return response.data;
};

export const deleteRelationByType = async ({relationId}: {relationId: string}): Promise<number | null> => {
  const response = await apiClient.delete(`/api/relations/${relationId}`);
  return response.status;
};
export const getResultsById = async ({relationId}: {relationId: UUID}) => {
  const response = await apiClient.get<Result[]>(`/api/relations/${relationId}/results`);
  return response.data;
};

export const getResultDetails = async ({relationId, resultId}: {relationId: UUID, resultId: UUID}) => {
  const response = await apiClient.get<ResultDetail>(`/api/relations/${relationId}/results/${resultId}`);
  return response.data;
};

export const getDynamics = async ({relationId}: {relationId: UUID}) => {
  const response = await apiClient.get<DynamicsTest[]>(`/api/relations/${relationId}/dynamics`);
  return response.data;
};

export const editRelationContactData = async ({relationId, data}: {relationId: string, data: EditRelationContactData}) => {
  const response = await apiClient.post(`/api/relations/${relationId}/contact`, data);
  return response.data;
};

export const editRelationAddressData = async ({relationId, data}: {relationId: string, data: EditRelationAddressData}) => {
  const response = await apiClient.post(`/api/relations/${relationId}/contact/address-change`, data);
  return response.data;
};

export const getRelationRequests = async () => {
  const response = await apiClient.get<RelationRequests>('/api/relations/requests');
  return response.data;
};

export const approveRelationRequest = async ({requestId}: {requestId: UUID}) => {
  const response = await apiClient.post(`/api/relations/requests/${requestId}/approve`);
  return response.data;
};

export const declineRelationRequest = async ({requestId}: {requestId: UUID}) => {
  const response = await apiClient.post(`/api/relations/requests/${requestId}/decline`);
  return response.data;
};

export const deleteRelationRequest = async ({requestId}: {requestId: UUID}) => {
  const response = await apiClient.post(`/api/relations/requests/${requestId}/delete`);
  return response.data;
};

export const editRelationHealthPoliciesData = async ({relationId, data}: {relationId: string, data: RelationHealthcareInsurancePolicyChangeRequest}) => {
  const response = await apiClient.post(`/api/relations/${relationId}/contact/health-policies-change`, data);
  return response.data;
};

export const searchRelationContacts = async ({data}: {data: RelationContactSearch}) => {
  const recaptcha = await getRecaptchaToken();
  const response = await apiClient.post<Page>('/api/relations/search-contacts', {...data, recaptcha});
  return response.data;
};

export const sendRelationRequest = async ({data}: {data: RelationRequestCreateRequest}) => {
  const recaptcha = await getRecaptchaToken();
  const response = await apiClient.post('/api/relations/send-request', {...data, recaptcha});
  return response.data;
};

export const createRelationContact = async ({
  data,
  force,
}: {
  data: RelationContactBatchCreateRequest
  force?: boolean
}) => {
  const recaptcha = await getRecaptchaToken();
  const response = await apiClient.post('/api/relations/create-contact', {...data, recaptcha}, {params: {force}});
  return response.data;
};

export const sendResultsByEmail = async ({
  relationId,
  resultId,
  data,
}: SendResultsByEmailRequestI) => {
  await apiClient.post(
    `/api/relations/${relationId}/results/${resultId}/fn/send`,
    data,
  );
  return true;
};

/**
 * @description Search relation dynamics
 *
 * @tags Relations
 * @name SearchRelationDynamicsById
 * @summary Relation dynamics
 * @request POST:/api/relations/{relationId}/dynamics/search
 * @docs https://patient-web.test.private.lifedl.net/swagger-ui/index.html#Relations/searchRelationDynamicsById
 * @response `200` `(DynamicItem)[]` OK
 * @response `401` `undefined` Unauthorized
 * @response `500` `BaseError` Internal Server Error
 */
export const searchRelationDynamicsById = async (
  {relationId, data}: {
    relationId: string
    data: DynamicSearchRequest
  },
) => {
  const response = await apiClient.post<DynamicItem[]>(`/api/relations/${relationId}/dynamics/search`, data);
  return response.data;
};

export * as relationsApi from './';
export * from './models';
