import {Stack, Typography} from '@mui/material';
import {RejectedReason} from '@src/api/__generated__/webApi';
import {useTranslate} from '@src/i18n/useTranslate';
import {DETAILS_DATA_TEST_ID} from '@src/pages/Details/testIds';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {FC, useEffect} from 'react';
import {Button, Dialog, DialogTitle, MobileBottomSheet, ModalFooter} from 'ui-kit';

import {sx} from './styles';

interface RejectedReasonsDialogProps {
  rejectedReasons: RejectedReason[] | null
  onClose: VoidFunction
  testIdFeatureGroup: string
}

export const RejectedReasonsDialog: FC<RejectedReasonsDialogProps> = ({
  onClose,
  rejectedReasons,
  testIdFeatureGroup,
}) => {
  const {t} = useTranslate('details');
  const {mobile, tablet, desktop} = useMQuery();

  useEffect(() => {
    if (desktop) {
      onClose();
    }
  }, [desktop]);

  const content = (
    <Stack p={'12px 24px'} gap={12}>
      {rejectedReasons?.map((reason, index) => (
        <Stack key={index} gap={3}>
          <Typography variant={'14_18_700'}>{reason.name}</Typography>
          <Typography variant={'14_18_500'}>{reason.comment}</Typography>
        </Stack>
      ))}
    </Stack>
  );

  if (tablet) {
    return (
      <Dialog
        open={!!rejectedReasons}
        onClose={onClose}
        size={'sm'}
        data-test-id={testId(testIdFeatureGroup)}
      >
        <DialogTitle
          onClose={onClose}
          data-test-id={testId(
            testIdFeatureGroup + DETAILS_DATA_TEST_ID.rejectedReasonsDialogTitleClose,
          )}
        >
          {t('REASONS_FOR_REJECTED')}
        </DialogTitle>
        {content}
        <Stack sx={sx.dialogActionContainer}>
          <Button
            color={'secondary'}
            onClick={onClose}
            data-test-id={testId(
              testIdFeatureGroup + DETAILS_DATA_TEST_ID.rejectedReasonsDialogBackButton,
            )}
          >
            {t('BACK')}
          </Button>
        </Stack>
      </Dialog>
    );
  }

  if (mobile) {
    return (
      <MobileBottomSheet
        open={!!rejectedReasons}
        onClose={onClose}
        data-test-id={testId(testIdFeatureGroup)}
      >
        <DialogTitle
          onClose={onClose}
          data-test-id={testId(
            testIdFeatureGroup + DETAILS_DATA_TEST_ID.rejectedReasonsDialogTitleClose,
          )}
        >
          {t('REASONS_FOR_REJECTED')}
        </DialogTitle>
        {content}
        <ModalFooter>
          <Button
            fullWidth
            color={'secondary'}
            onClick={onClose}
            data-test-id={testId(
              testIdFeatureGroup + DETAILS_DATA_TEST_ID.rejectedReasonsDialogBackButton,
            )}
          >
            {t('BACK')}
          </Button>
        </ModalFooter>
      </MobileBottomSheet>
    );
  }

  return null;
};
