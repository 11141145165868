import {AutocompleteInputView} from '@src/components/Google/Autocomplete/AutocompleteInputView';
import {useGoogleAutocomplete} from '@src/components/Google/Autocomplete/useGoogleAutocomplete';
import {FC, useEffect, useCallback, useState, ChangeEvent, Dispatch, SetStateAction} from 'react';

export const GoogleAutocomplete: FC<{
  label?: string
  onChange?: (placeResult: google.maps.places.PlaceResult | null) => void
  placeholder?: string
  error?: string
  value?: string
  setIsOptionSelected: Dispatch<SetStateAction<boolean>>
  isOptionSelected: boolean
  handleChangeAddress1: (newValue: string) => void
}> = ({label, onChange, placeholder, error, value, setIsOptionSelected, isOptionSelected, handleChangeAddress1, ...props}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const {ref, options, getPlacePredictions} = useGoogleAutocomplete({onChange});

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    handleChangeAddress1(newValue);
    setIsOptionSelected(false);

    if (!newValue?.trim()) {
      onChange?.(null);
      getPlacePredictions('');
    } else {
      getPlacePredictions(newValue);
    }
  };

  const getError = useCallback(() => {
    if (!inputValue.trim()) {
      return error;
    }
    if (!isOptionSelected && inputValue && options.length > 0) {
      return error;
    }
    if (!isOptionSelected && options.length === 0) {
      return error;
    }
    if (isOptionSelected && error) {
      return error;
    }
    return undefined;
  }, [inputValue, options, error, isOptionSelected]);

  useEffect(() => {
    const errorMessage = getError();
    setErrorText(errorMessage);
  }, [getError]);

  const onFocusHandler = useCallback(() => {
    const main = document.querySelector('#main') as HTMLElement;
    const scrollContainer = document.querySelector('#scroll-container') as HTMLElement;
    if (main) {
      document.body.style.overflowY = 'hidden';
      main.style.overflowY = 'hidden';
    }
    if (scrollContainer) {
      document.body.style.overflowY = 'hidden';
      scrollContainer.style.overflowY = 'hidden';
    }
  }, []);

  const onBlurHandler = useCallback(() => {
    const main = document.querySelector('#main') as HTMLElement;
    const scrollContainer = document.querySelector('#scroll-container') as HTMLElement;
    if (main) {
      document.body.style.overflowY = 'auto';
      main.style.overflowY = 'auto';
    }
    if (scrollContainer) {
      document.body.style.overflowY = 'auto';
      scrollContainer.style.overflowY = 'auto';
    }
  }, []);

  useEffect(() => {
    const inputEl = ref.current;
    if (inputEl) {
      inputEl.addEventListener('focus', onFocusHandler);
      inputEl.addEventListener('blur', onBlurHandler);
    }

    return () => {
      if (inputEl) {
        inputEl.removeEventListener('focus', onFocusHandler);
        inputEl.removeEventListener('blur', onBlurHandler);
      }
    };
  }, [ref, onFocusHandler, onBlurHandler]);

  useEffect(() => {
    if (value !== inputValue && isOptionSelected) {
      setInputValue(value || '');
    }
  }, [value, isOptionSelected]);

  return (
    <AutocompleteInputView
      value={inputValue}
      label={label}
      ref={ref}
      error={errorText}
      placeholder={placeholder}
      onChange={handleInputChange}
      {...props}
    />
  );
};
