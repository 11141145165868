import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  boxContainer: {
    padding: '24',
    flexWrap: 'unset',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  checkbox: {
    padding: 5,
    transform: 'translate(-10px, 2px)',
    alignSelf: 'baseline',
  },
  gridContainer: {
    padding: '16px 0 12px 24px',
    alignItems: 'center',
  },
};
