import {Typography, Box} from '@mui/material';
import {DialogTitle, Dialog, DialogActions} from '@src/components/Dialog';
import {INSURANCE_DELETE_DIALOG_DATA_TEST_ID} from '@src/features/InsuranceDeleteDialog/testIds';
import {TranslationKeys} from '@src/i18n/TranslationKeys';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {FC} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {InsuranceDeleteDialogProps} from './types';

export const InsuranceDeleteDialog: FC<InsuranceDeleteDialogProps> = ({
  onRemove,
  isOpen,
  isDeleting,
  onClose,
  translate,
  testIdFeatureGroup,
}) => {
  const {t} = useTranslate(translate as keyof TranslationKeys);
  const {mobile: isMobile} = useMQuery();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      keepMounted={false}
      size="sm"
      border="none"
      data-test-id={testId(
        testIdFeatureGroup + INSURANCE_DELETE_DIALOG_DATA_TEST_ID.insuranceDeleteDialog,
      )}
    >
      <DialogTitle
        onClose={onClose}
        width="66px"
        closeButtonDataTestId={testId(
          testIdFeatureGroup +
            INSURANCE_DELETE_DIALOG_DATA_TEST_ID.insuranceDeleteDialogHeaderCloseButton,
        )}
      >
        {t('DELETE_INSURANCE' as TKeys)}
      </DialogTitle>
      <Box sx={sx.dialogContent}>
        <Typography variant="16_24_500">
          {t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_INSURANCE' as TKeys)}
        </Typography>
      </Box>
      <DialogActions sx={sx.dialogActions} spaceBetween>
        <Button
          fullWidth={isMobile}
          variant="outlined"
          disabled={isDeleting}
          color="secondary"
          onClick={onClose}
          data-test-id={testId(
            testIdFeatureGroup +
              INSURANCE_DELETE_DIALOG_DATA_TEST_ID.insuranceDeleteDialogFooterCancelButton,
          )}
        >
          {t('CANCEL')}
        </Button>
        <Button
          fullWidth={isMobile}
          variant="contained"
          disabled={isDeleting}
          color="secondary"
          onClick={onRemove}
          data-test-id={testId(
            testIdFeatureGroup +
              INSURANCE_DELETE_DIALOG_DATA_TEST_ID.insuranceDeleteDialogFooterDeleteButton,
          )}
        >
          {t('DELETE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
