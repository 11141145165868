export const DYNAMICS_DATA_TEST_ID = {
  dynamicsFiltersTestsSelect: 'dynamics__filters_tests-select',
  dynamicsFiltersTestsSelectDropdownCheckUncheckAll: 'dynamics__filters_tests-select_dropdown_check-uncheck-all',
  dynamicsFiltersTestsSelectDropdownItem: 'dynamics__filters_tests-select_dropdown_item',
  dynamicsBreadcrumbsResults: 'dynamics__breadcrumbs_results',
  dynamicsBreadcrumbsResultDetails: 'dynamics__breadcrumbs_result-details',
  dynamicsDialogFiltersTestsSelect: 'dynamics__dialog_filters_tests-select',
  dynamicsDialogFiltersTestsSelectDropdownCheckUncheckAll: 'dynamics__dialog_filters_tests-select_dropdown_check-uncheck-all',
  dynamicsDialogFiltersTestsSelectDropdownItem: 'dynamics__dialog_filters_tests-select_dropdown_item',
  dynamicsHeaderGraphButton: 'dynamics__header_graph-button',
  dynamicsHeaderTableButton: 'dynamics__header_table-button',
  dynamicsHeaderOpenFiltersButton: 'dynamics__header_open-filters-button',
  dynamicsFiltersRefreshButton: 'dynamics__filters_refresh-button',
  dynamicsFiltersPeriodSelect: 'dynamics__filters_period-select',
  dynamicsFiltersDateRangeButton: 'dynamics__filters_date-range-button',
  dynamicsFiltersManualToggle: 'dynamics__filters_manual-toggle',
  dynamicsDialogFiltersDateRange: 'dynamics__dialog_filters_date-range',
} as const;
