export const SIDE_MENU_DATA_TEST_ID = {
  sideMenuShowHideButton: 'side-menu__show-hide-button',
  sideMenuOverview: 'side-menu__overview',
  sideMenuTasks: 'side-menu__tasks',
  sideMenuResults: 'side-menu__results',
  sideMenuMessages: 'side-menu__results',
  sideMenuJournal: 'side-menu__results',
  sideMenuInsurance: 'side-menu__insurance',
  sideMenuConnections: 'side-menu__connections',
  sideMenuHealthCases: 'side-menu__health-cases',
  sideMenuStorage: 'side-menu__storage',
  sideMenuSettings: 'side-menu__settings',
} as const;
