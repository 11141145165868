import {Box, Stack, Typography} from '@mui/material';
import {FC, useMemo} from 'react';

import {StyledSlider, sx} from './styles';
import {SliderInputT} from './types';

export const SliderInput: FC<SliderInputT> = ({
  label,
  sliderSx,
  sx: sxContainer,
  isDisplayedValue,
  ...props
}) => {
  const valueLabel = useMemo(() => {
    if (typeof props.valueLabelFormat === 'function' && props.value) {
      return props.valueLabelFormat(props.value as number, 0);
    }
    return props.value;
  }, [props]);
  return (
    <Stack sx={sxContainer}>
      <Box sx={sx.lableBox}>
        <Typography variant="14_18_700">{label}</Typography>
        <Typography variant="14_18_500">{isDisplayedValue ? props.value : valueLabel}</Typography>
      </Box>
      <StyledSlider sx={sliderSx} {...props} />
    </Stack>
  );
};
