export enum StepDialog {
  OPEN = 'open',
  LOADING = 'loading',
  INIT = 'init',
};

export interface InsuranceDeleteDialogProps {
  onRemove: VoidFunction
  isOpen: boolean
  isDeleting: boolean
  onClose: VoidFunction
  translate: string
  testIdFeatureGroup: string
};
