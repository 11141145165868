import {Stack, Box} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {RELATIONS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {Loader} from '../Loader';
import {isShowButtonEdit} from '../helpers';

import {RelationAboutWithAccess} from './RelationAboutWithAccess/RelationAboutWithAccess';
import {RelationAboutWithoutAccess} from './RelationAboutWithoutAccess/RelationAboutWithoutAccess';
import {sx} from './styles';

export const RelationAbout = () => {
  const {t, ready} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();

  const navigate = useNavigate();
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const {relation, fetching} = useRelation(relationId);

  const isAccesses = relation?.accesses?.length;

  const handleClickEditButton = () => {
    if (id) {
      navigate(generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT_EDIT, {id}));
    }
  };

  if (fetching || !ready) {
    return <Loader />;
  }

  return (
    <Stack sx={sx.container}>
      {isAccesses
        ? (
          <RelationAboutWithAccess relation={relation} />
        )
        : (
          <RelationAboutWithoutAccess relation={relation} />
        )}
      {isShowButtonEdit({relation}) && (
        <Box sx={sx.editBthContainer}>
          <Button
            fullWidth={isMobile}
            sx={sx.buttonEdit}
            variant="outlined"
            color="primary"
            onClick={handleClickEditButton}
            data-test-id={testId(RELATIONS_DATA_TEST_ID.relationInfoPersonalEditButton)}
          >
            {t('EDIT')}
          </Button>
        </Box>
      )}
    </Stack>
  );
};
