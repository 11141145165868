import {FullContainerLoader} from '@src/components/FullContainerLoader';
import {useTranslate} from '@src/i18n/useTranslate';
import {sortInsurances} from '@src/pages/Connections/fragments/Relations/helpers/functionHelpers';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {checkIsPersonAdult} from '@src/shared/utils/checkIsPersonAdult';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {useAccountProfile} from '@src/store/account/hooks';
import {useContactRelations} from '@src/store/relations/hooks';
import {useResults} from '@src/store/results/hooks';
import {useMemo} from 'react';

import {OverviewDesktop} from './OverviewDesktop';
import {OverviewDesktopLg} from './OverviewDesktopLg';
import {OverviewDesktopXl} from './OverviewDesktopXl';
import {OverviewMobile} from './OverviewMobile';
import {OverviewTabletLandscape} from './OverviewTabletLandscape';

export const Overview = () => {
  const {ready} = useTranslate('overview');
  const {
    mobile: isMobile,
    tabletPortrait: isTabletPortrait,
    tablet: isTablet,
    lg: isDesktopLg,
    xl: isDesktopXl,
  } = useMQuery();
  const {accountProfile, fetching: isAccountLoading} = useAccountProfile();
  const {data, isLoading: isResultsLoading} = useResults();
  const {fetching: relationsFetching} = useContactRelations('');
  const {isSidebarOpen} = useSidebarClassObserver();

  const activeInsurances = useMemo(() => {
    const sorted = sortInsurances(accountProfile?.healthcareInsurancePolicies || []);
    return (
      sorted?.filter(
        (insurance) =>
          compareDatesWithToday(insurance.endDate, false, true) || insurance.endDate === null,
      ) || []
    );
  }, [accountProfile?.healthcareInsurancePolicies]);

  const currentPersonAge = accountProfile?.contact?.birthDate;
  const isPersonOlder18 = !currentPersonAge ? true : !!checkIsPersonAdult(currentPersonAge);

  if (!ready || isResultsLoading || isAccountLoading || relationsFetching) {
    return <FullContainerLoader />;
  }

  const props = {
    accountProfile,
    data,
    insurances: activeInsurances,
    isPersonOlder18,
    isSidebarOpen,
  };

  if (isMobile || isTabletPortrait) {
    return <OverviewMobile {...props} />;
  }

  if (isTablet) {
    return <OverviewTabletLandscape {...props} />;
  }

  if (isDesktopLg) {
    return <OverviewDesktopLg {...props} />;
  }

  if (isDesktopXl) {
    return <OverviewDesktopXl {...props} />;
  }

  return <OverviewDesktop {...props} />;
};
