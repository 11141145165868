export const EDIT_DOCUMENT_DIALOG_DATA_TEST_ID = {
  editDocumentDialogNameInput: '_edit-document-dialog_name-input',
  editDocumentDialogCategorySelect: '_edit-document-dialog_category-select',
  editDocumentDialogCategorySelectDropdownItem: '_edit-document-dialog_category-select_dropdown-item',
  editDocumentDialogAutomaticRecognizeRadio: '_edit-document-dialog_automatic-recognize_radio',
  editDocumentDialogManualEntryRadio: '_edit-document-dialog_manual-entry_radio',
  editDocumentDialog: '_edit-document-dialog',
  editDocumentDialogAddBiomarkerButton: '_edit-document-dialog_add-biomarker_button',
  editDocumentDialogDocumentDate: '_edit-document-dialog_document-date',
  editDocumentDialogDescriptionInput: '_edit-document-dialog_description-input',
  editDocumentDialogHealthCases: '_edit-document-dialog_health-cases',
  editDocumentDialogUploadButton: '_edit-document-dialog_upload-button',
  editDocumentDialogDeleteFileButton: '_edit-document-dialog_delete-file-button',
  editDocumentDialogCancelButton: '_edit-document-dialog_cancel-button',
  editDocumentDialogSaveButton: '_edit-document-dialog_save-button',
  editDocumentDialogHeaderCloseButton: '_edit-document-dialog_header_close-button',
} as const;
