import {Box, Stack, Theme, Typography} from '@mui/material';
import {TestStatus} from '@src/api';
import {Dl, RowInformation} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {PopoverTooltip, usePopoverTooltip} from '@src/components/PopoverTooltip';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {getReferenceText, getResultColor, getStatus} from '@src/pages/Details/helpers';
import {DETAILS_DATA_TEST_ID} from '@src/pages/Details/testIds';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {testId} from '@src/shared/utils/testId';
import {paletteColors} from '@src/theme';
import {FC, Fragment, useMemo} from 'react';
import {Checkbox} from 'ui-kit';

import {Accordion, AccordionDetails} from '../StyledAccordionSummary/StyledAccordionSummary';
import {INITIAL_COLOR} from '../constants';

import {stylesSx} from './styles';
import {ManualRowTableProps} from './types';

export const TableManualRow: FC<ManualRowTableProps> = ({
  data,
  className,
  testCodes,
  changeCodesForDynamics,
  sx,
  testIdFeatureGroup = DETAILS_DATA_TEST_ID.detailsTable,
}) => {
  const {mobile, tabletPortrait, desktop: isDesktop, lg: isDesktopLg} = useMQuery();
  const {t} = useTranslate('details');
  const {onOpen: handleClick, open, ...forPopover} = usePopoverTooltip();
  const {isSidebarOpen} = useSidebarClassObserver();

  const isChecked = testCodes?.includes(data.loinc);

  const handleChangeCheckbox = (checked: boolean, loinc: string) => {
    if (changeCodesForDynamics) {
      changeCodesForDynamics(loinc, checked);
    }
  };

  const gridTemplateColumns = useMemo(() => {
    if (isDesktop && !isDesktopLg) return '250px auto 100px 160px 140px';
    if (isDesktopLg && !isSidebarOpen) return '250px minmax(160px, 1fr) 100px 160px 140px';
    if (isDesktopLg && isSidebarOpen) return '190px minmax(188px, 1fr) 100px 160px 140px';
    return '24.89% 28.17% 6.05% 12.59% 18.25%';
  }, [isDesktop, isDesktopLg, isSidebarOpen]);

  const isCritical = data.criticalReference?.isOutOfRef;
  const colorInconsiderable = paletteColors.grey[700];

  const {name, value, comment, reference, criticalReference, code, loinc, units, status} = data;

  const isPending = status === TestStatus.IN_PROGRESS;
  const isBadStatus = status !== TestStatus.COMPLETED;
  const currentUnits = isPending ? '' : units;

  const critical = isCritical ? '#FCF2F5' : isPending ? paletteColors.grey[100] : INITIAL_COLOR;

  const resultColor = getResultColor(data);
  const currentValue = getStatus(status, value);

  const valueNode = (
    <>
      <div style={{color: resultColor, fontWeight: resultColor ? 700 : INITIAL_COLOR}}>
        {t(currentValue as TKeys<'details'>) || ''}
      </div>
    </>
  );

  if (mobile || tabletPortrait) {
    return (
      <Fragment>
        <Accordion
          sx={(t: Theme) => ({
            borderBottom: `1px solid ${t.colors.borderLight}`,
          })}
          data-test-id={testId(testIdFeatureGroup + DETAILS_DATA_TEST_ID.accordion)}
        >
          <AccordionDetails>
            <Box sx={stylesSx.boxPaddings}>
              <Dl sx={stylesSx.dlPosition}>
                <Checkbox
                  sx={stylesSx.checkboxPosition}
                  value={isChecked}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={(_, checked) => handleChangeCheckbox(checked, loinc)}
                  data-test-id={testId(testIdFeatureGroup + DETAILS_DATA_TEST_ID.checkboxTest)}
                />
                <dt>
                  <Typography variant="12_16_700">{t('TEST')}</Typography>
                </dt>
                <dd>
                  <Typography variant="12_16_500">{name}</Typography>
                </dd>
              </Dl>
              <Dl sx={{flexDirection: !valueNode.props.children[1] ? 'row' : 'column'}}>
                <dt>
                  <RowInformation
                    valueTypographyVariant={'12_16_500'}
                    variant={'12_16_700'}
                    value={valueNode}
                    name={t('RESULTS')}
                    noMargin
                    noWrap
                    valueSx={{
                      whiteSpace: 'break-spaces',
                    }}
                  />
                </dt>
              </Dl>
              <Dl>
                <dt>
                  <Typography variant="12_16_700">{t('UNITS')}</Typography>
                </dt>
                <dd>
                  <Typography variant="12_16_500">{currentUnits}</Typography>
                </dd>
              </Dl>
              <Dl>
                <RowInformation
                  valueTypographyVariant={'12_16_500'}
                  variant={'12_16_700'}
                  value={comment || t('NO_COMMENTS')}
                  name={t('COMMENTS')}
                  noMargin
                  noWrap
                  valueSx={{
                    whiteSpace: 'break-spaces',
                  }}
                />
              </Dl>
              <Dl
                sx={{
                  flexDirection: !reference && !criticalReference ? 'row' : 'column',
                  mb: '0px !important',
                }}
              >
                <dt>
                  <Typography variant="12_16_700">{t('REFERENCE_INTERVAL')}</Typography>
                </dt>
                <dd>
                  <Box sx={{whiteSpace: 'pre-wrap'}}>
                    <Typography variant="12_16_500" color={colorInconsiderable}>
                      {getReferenceText(data, t)}
                    </Typography>
                  </Box>
                </dd>
              </Dl>
            </Box>
          </AccordionDetails>
        </Accordion>
        <PopoverTooltip {...forPopover} open={open && Boolean(comment)}>
          {valueNode}
          <br />
          {comment}
        </PopoverTooltip>
      </Fragment>
    );
  }

  return (
    <>
      <Box
        minWidth={{lg: 1033}}
        paddingX={{xs: 25, md: 25, lg: 34}}
        paddingRight={{lg: 36, xs: 36, md: 24}}
        paddingY={{lg: 12, xs: 8, md: 12}}
        sx={sx}
        className={className}
        style={{
          gridTemplateColumns,
          backgroundColor: critical,
        }}
      >
        <Box>
          <Box>
            <Checkbox
              value={isChecked}
              onClick={(event) => {
                event.stopPropagation();
              }}
              disabled={isBadStatus}
              label={name || '--'}
              onChange={(_, checked) => {
                handleChangeCheckbox(checked, loinc);
              }}
              data-test-id={testId(testIdFeatureGroup + DETAILS_DATA_TEST_ID.checkboxTest)}
            />
          </Box>
        </Box>
        <Stack sx={{gap: 6}} onClick={handleClick}>
          {valueNode}
        </Stack>
        <Box>{currentUnits}</Box>
        {!mobile && !tabletPortrait && (
          <>
            <Box>
              <EllipsisTextTooltip
                ellipsisText={isPending ? '' : comment || t('NO_COMMENTS')}
                heightCheck
                variant={'14_18_500'}
                sx={stylesSx.commentText}
              />
            </Box>
            <Box sx={{whiteSpace: 'pre-wrap'}}>{getReferenceText(data, t)}</Box>
          </>
        )}
      </Box>
      <PopoverTooltip {...forPopover} open={open && Boolean(comment)}>
        {valueNode}
        <br />
        {comment}
      </PopoverTooltip>
    </>
  );
};
