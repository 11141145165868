import {Box, Stack} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {RELATIONS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/testIds';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {Loader} from '../Loader';
import {RelationContactsEdit} from '../RelationEditLayouts/RelationContactsEdit/RelationContactsEdit';
import {isShowButtonEdit} from '../helpers';

import {RelationContactsWithAccess} from './RelationContactsWithAccess';
import {RelationContactsWithoutAccess} from './RelationContactsWithoutAccess';
import {sx} from './styles';

export const RelationContacts = () => {
  const [isEditing, setIsEditing] = useState(false);

  const {t, ready} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const {relation, fetching} = useRelation(relationId);

  const isAccesses = relation?.accesses?.length;

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  if ((fetching && !isEditing) || !ready) {
    return <Loader />;
  }

  return (
    <Stack sx={sx.container}>
      {!isEditing && isAccesses
        ? (
          <RelationContactsWithAccess relation={relation} />
        )
        : (
          <RelationContactsWithoutAccess relation={relation} />
        )}
      {!isEditing && isShowButtonEdit({relation}) && (
        <Box sx={sx.buttonsContainer}>
          <Button
            fullWidth={isMobile}
            variant="outlined"
            color="primary"
            onClick={handleEditClick}
            data-test-id={testId(RELATIONS_DATA_TEST_ID.relationInfoContactsAddEditButton)}
          >
            {relation?.actualAddresses?.main ? t('EDIT') : t('ADD')}
          </Button>
        </Box>
      )}
      {isEditing && (
        <RelationContactsEdit handleCancelClick={handleCancelClick} fetching={fetching} />
      )}
    </Stack>
  );
};
