import {Box, Stack} from '@mui/material';
import {ReactNode} from 'react';

import {sx} from './styles';

interface MobileDialogProps {
  children: ReactNode
  onClose: () => void
  isOpen: boolean
  padding?: string
}

export const MobileDialog = ({children, onClose, isOpen, padding, ...props}: MobileDialogProps) => {
  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <Box
        sx={sx.dialogWrapper}
        onClick={handleClose}
      />
      <Stack sx={sx.dialog} p={padding || 0} {...props}>
        {children}
      </Stack>
    </>
  );
};
