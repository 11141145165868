import {Box, SelectChangeEvent, Stack, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {MenuTypes, StorageFileCategory} from '@src/api';
import {DatePickerRange} from '@src/components';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {HEALTH_CASE_DATA_TEST_ID} from '@src/pages/HealthCase/testIds';
import {getMenuTypesOptions} from '@src/pages/HealthCase/utils/getMenuTypesOptions';
import {FiltersContainer} from '@src/pages/HealthCases/components/Filters/styles';
import {categoryOptions} from '@src/pages/Storage/helpers';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {STORAGE_CATEGORY_TYPE_MAP} from '@src/shared/constants/enumsMaps';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {useAppDispatch, useAppSelector} from '@src/store';
import {INITIAL_DATES, INITIAL_FILTERS} from '@src/store/storage/constants';
import {storageActions} from '@src/store/storage/slice';
import {StorageFilters} from '@src/store/storage/types';
import {debounce} from 'lodash-es';
import {ChangeEvent, MouseEvent, useMemo, useEffect, useState} from 'react';
import {
  ActionsButton,
  ActionsRow,
  IconButton,
  MenuItem,
  OptionValue,
  SearchControl,
  SelectControl,
} from 'ui-kit';

import {sx} from './styles';

export const DocumentsFilters = () => {
  const {t} = useTranslate('healthCases');
  const {t: tEnums} = useTranslate('enums');

  const [isOpen, setIsOpen] = useState(false);

  const {
    files: {filters, items},
    selectedHealthCases,
  } = useAppSelector((state) => state.storage);
  const [tempSelectedHealthCases, setTempSelectedHealthCases] = useState<OptionValue[]>([]);
  const [dialogFilters, setDialogFilters] = useState(filters);
  const dispatch = useAppDispatch();
  const {mobile, tabletPortrait} = useMQuery();
  const onPortrait = mobile || tabletPortrait;

  const handleDispatchSetFilters = ({
    newState,
    updatedValue,
  }: {
    newState?: StorageFilters
    updatedValue?: { key: keyof StorageFilters, value: any }
  }) => {
    dispatch(storageActions.setFilters({newState, updatedValue}));
  };

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (value: string) => {
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('');
  };

  const debouncedHandleChangeSearch = useMemo(
    () =>
      debounce((value: string) => {
        handleDispatchSetFilters({
          updatedValue: {
            key: 'phrase',
            value,
          },
        });
      }, 500),
    [handleDispatchSetFilters],
  );

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedHandleChangeSearch(value);
  };

  const handleChangeMenu = (_: MouseEvent<HTMLElement>, value: MenuTypes) => {
    handleDispatchSetFilters({updatedValue: {key: 'menu', value}});
  };

  const handleClearAllFilters = () => {
    handleDispatchSetFilters({newState: INITIAL_FILTERS});
    dispatch(storageActions.clearSelectedHealthCases());
    setTempSelectedHealthCases([]);
  };

  const handleChangeDate = (name: 'from' | 'to') => (date: Date | null) => {
    setDialogFilters((prevState) => ({...prevState, [name]: date}));
  };

  const handleClearDate = () => {
    setDialogFilters((prevState) => ({...prevState, ...INITIAL_DATES}));
  };

  const handleChangeCategory = (event: SelectChangeEvent<StorageFileCategory>) => {
    const value = event.target.value as StorageFileCategory;
    setDialogFilters((prevState) => ({...prevState, category: value}));
  };

  const healthCasesOptions = useMemo(() => {
    const allHealthCases = items?.flatMap((item) => item?.healthCases) || [];

    const uniqueHealthCasesMap = new Map(
      allHealthCases.map((healthCase) => [healthCase?.name, healthCase]),
    );

    const uniqueHealthCases = Array.from(uniqueHealthCasesMap.values());

    return uniqueHealthCases.map((healthCase) => ({
      value: healthCase?.id || '',
      label: healthCase?.name || '',
    }));
  }, [items]);

  const handleOpenFilter = () => {
    const selectedHealthCasesOptions = healthCasesOptions.filter((hCaseOption) =>
      selectedHealthCases.includes(hCaseOption.value),
    );
    setTempSelectedHealthCases(selectedHealthCasesOptions);
    setDialogFilters(filters);
    setIsOpen(true);
  };

  const handleCloseFilter = () => {
    setIsOpen(false);
  };

  const filteredCasesOptions = useMemo(() => {
    if (searchValueHealthCase) {
      return healthCasesOptions.filter((option) =>
        option.label.toLowerCase().includes(searchValueHealthCase.toLowerCase()),
      );
    }
    return healthCasesOptions;
  }, [healthCasesOptions, searchValueHealthCase]);

  const handleHealthCaseSelect = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    const selectedHealthCases = healthCasesOptions.filter((option) => value.includes(option.value));

    setTempSelectedHealthCases(selectedHealthCases);
    setDialogFilters((prevFilter) => ({
      ...prevFilter,
      healthCase: selectedHealthCases,
    }));
  };

  const handleCasesCheckToggle = () => {
    if (tempSelectedHealthCases.length > healthCasesOptions.length / 2) {
      setTempSelectedHealthCases([]);
    } else {
      setTempSelectedHealthCases(healthCasesOptions);
    }
  };

  const handleUpdateFilters = (newFilters: StorageFilters) => {
    handleDispatchSetFilters({newState: newFilters});
  };

  const handleClearFilters = () => {
    setDialogFilters(INITIAL_FILTERS);
    setTempSelectedHealthCases([]);
  };

  const handleApply = () => {
    setIsOpen(false);
    handleUpdateFilters(dialogFilters);
    dispatch(
      storageActions.setSelectedHealthCases(tempSelectedHealthCases.map((item) => item.value)),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(storageActions.setFilters({newState: INITIAL_FILTERS}));
      dispatch(storageActions.clearSelectedHealthCases());
      setTempSelectedHealthCases([]);
    };
  }, [dispatch]);

  const renderFilterDialog = () => {
    return (
      <FiltersDialogContainer
        isOpen={isOpen}
        onClose={handleCloseFilter}
        title={t('FILTERS')}
        onApply={handleApply}
        onClear={handleClearFilters}
        testIdFeatureGroup={HEALTH_CASE_DATA_TEST_ID.healthCaseAddDocuments}
      >
        <Stack gap={24}>
          <Stack gap={2}>
            <FormSelectControl<keyof typeof STORAGE_CATEGORY_TYPE_MAP>
              name={'category'}
              placeholder={t('SELECT')}
              label={t('CATEGORY')}
              compact={true}
              value={dialogFilters.category}
              onChange={handleChangeCategory}
              renderValue={(v) => (v ? tEnums(STORAGE_CATEGORY_TYPE_MAP[v]) : '')}
              data-test-id={testId(
                HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogCategorySelect,
              )}
            >
              {categoryOptions.map(({name, value}, key) => (
                <MenuItem
                  key={key}
                  value={value}
                  data-test-id={testId(
                    HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogCategorySelectDropdownItem,
                  )}
                >
                  {tEnums(name)}
                </MenuItem>
              ))}
            </FormSelectControl>
          </Stack>
          <Stack gap={2}>
            <SelectControl<string>
              data-test-id={testId(
                HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogHealthCaseSelect,
              )}
              value={tempSelectedHealthCases}
              onChange={handleHealthCaseSelect}
              multiple
              label={t('HEALTH_CASES')}
              placeholder={t('SELECT')}
              onSearch={handleChangeSearchValue}
              onClose={handleClearSearchValue}
              dropdownFooterRow={
                <ActionsRow>
                  <ActionsButton
                    data-test-id={testId(
                      HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogHealthCaseSelectDropdownCheckUncheckAll,
                    )}
                    onClick={handleCasesCheckToggle}
                    noClose={onPortrait}
                  >
                    {tempSelectedHealthCases.length > healthCasesOptions.length / 2
                      ? t('UNCHECK_ALL')
                      : t('CHECK_ALL')}
                  </ActionsButton>
                </ActionsRow>
              }
            >
              {filteredCasesOptions.length
                ? (
                  filteredCasesOptions.map(({value, label}) => (
                    <MenuItem
                      key={value}
                      value={value}
                      data-test-id={testId(
                        HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogHealthCaseSelectDropdownItem,
                      )}
                    >
                      {label}
                    </MenuItem>
                  ))
                )
                : (
                  <ActionsRow>{t('NO_SUITABLE_RESULTS')}</ActionsRow>
                )}
            </SelectControl>
          </Stack>
          <Stack>
            <DatePickerRange
              sx={{mb: '0 !important'}}
              isIconVisible={false}
              variant="outlined"
              label={t('DATE_RANGE')}
              startDate={dialogFilters.from}
              endDate={dialogFilters.to}
              setStartDate={handleChangeDate('from')}
              setEndDate={handleChangeDate('to')}
              clearDate={handleClearDate}
              labelTop
              startRangeWithCurrentMonth
              dataTestId={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogDateRange)}
            />
          </Stack>
        </Stack>
      </FiltersDialogContainer>
    );
  };

  return (
    <>
      <FiltersContainer>
        <Stack sx={sx.searchToggleContainer}>
          <Box sx={sx.searchWrapper}>
            <SearchControl
              placeholder={t('SEARCH')}
              value={filters.phrase}
              onChange={handleChangeSearch}
              data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogSearchInput)}
            />
            {onPortrait && (
              <IconButton
                color={'secondary'}
                variant={'outlined'}
                onClick={handleOpenFilter}
                data-test-id={testId(
                  HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogOpenFiltersButton,
                )}
              >
                <SettingsIcon />
              </IconButton>
            )}
          </Box>
          <ToggleButtonGroup
            color={'secondary'}
            fullWidth={onPortrait}
            value={filters.menu}
            exclusive
            onChange={handleChangeMenu}
          >
            {getMenuTypesOptions(t).map((option) => (
              <ToggleButton
                sx={sx.toggleButton}
                key={option.value}
                value={option.value}
                data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogTypeButton)}
              >
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {!onPortrait && (
            <>
              <IconButton
                color={'secondary-light'}
                variant={'outlined'}
                onClick={handleOpenFilter}
                data-test-id={testId(
                  HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogOpenFiltersButton,
                )}
              >
                <SettingsIcon />
              </IconButton>
              <IconButton
                color={'secondary-light'}
                onClick={handleClearAllFilters}
                data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsDialogClearButton)}
              >
                <RefreshIcon />
              </IconButton>
            </>
          )}
        </Stack>
      </FiltersContainer>
      {renderFilterDialog()}
    </>
  );
};
