export const HEALTH_CASE_DATA_TEST_ID = {
  healthCaseResultsDialogHealthCaseSelect: 'health-case__result-dialog_health-case_select',
  healthCaseResultsDialogHealthCaseSelectDropdownCheckUncheckAll: 'health-case__result-dialog_health-case_select_dropdown_check-uncheck-all',
  healthCaseResultsDialogHealthCaseSelectDropdownItem: 'health-case__result-dialog_health-case_select_dropdown_item',
  healthCaseResultsDialogPhysicianSelect: 'health-case__result-dialog_physician_select',
  healthCaseResultsDialogPhysicianSelectDropdownItem: 'health-case__result-dialog_physician_select_dropdown_item',
  healthCaseResultsDialogOpenFiltersButton: 'health-case__result-dialog_open-filters-button',
  healthCaseResultsDialogClearButton: 'health-case__result-dialog_clear-button',
  healthCaseResultsDialog: 'health-case__result-dialog',
  healthCaseResultsDialogDateRange: 'health-case__result-dialog_date-range',
  healthCaseDocumentsDialogHealthCaseSelect: 'health-case__documents-dialog_health-case_select',
  healthCaseDocumentsDialogHealthCaseSelectDropdownCheckUncheckAll: 'health-case__documents-dialog_health-case_select_dropdown_check-uncheck-all',
  healthCaseDocumentsDialogHealthCaseSelectDropdownItem: 'health-case__documents-dialog_health-case_select_dropdown_item',
  healthCaseBreadcrumbsHealthCases: 'health-case__breadcrumbs_health-cases',
  healthCaseNameInput: 'health-case__name-input',
  healthCaseDate: 'health-case__date',
  healthCaseDescription: 'health-case__description-input',
  healthCaseAddDocumentsButton: 'health-case__add-documents-button',
  healthCaseDocumentsItem: 'health-case__documents_item',
  healthCaseDocumentsItemMenuButton: 'health-case__documents_item_menu-button',
  healthCaseDocumentsItemMenuDropdown: 'health-case__documents_item_menu_dropdown',
  healthCaseDocumentsItemMenuView: 'health-case__documents_item_menu_view',
  healthCaseDocumentsItemMenuDetails: 'health-case__documents_item_menu_details',
  healthCaseDocumentsItemMenuRemove: 'health-case__documents_item_menu_remove',
  healthCaseDocumentsItemMenuMobile: 'health-case__documents_item_menu_mobile',
  healthCaseDocumentsStorageLink: 'health-case__documents_storage-link',
  healthCaseAddResultsButton: 'health-case__add-results-button',
  healthCaseResultsListItem: 'health-case__results_list_item',
  healthCaseResultsListItemMenuButton: 'health-case__results_list_item_menu-button',
  healthCaseResultsListItemMobileMenu: 'health-case__results_list_item_mobile-menu',
  healthCaseResultsListItemMobileMenuRemove: 'health-case__results_list_item_menu_remove',
  healthCaseResultsTableRowRemoveButton: 'health-case__results_table_row_remove-button',
  healthCaseResultsTableRow: 'health-case__results_table_row',
  healthCaseResultsShowAllButton: 'health-case__results_show-all-button',
  healthCaseDeleteButton: 'health-case__delete-button',
  healthCaseSaveButton: 'health-case__save-button',
  healthCaseResultsDialogTableRowCheckbox: 'health-case__result-dialog_table_row_checkbox',
  healthCaseResultsDialogTableRow: 'health-case__result-dialog_table_row',
  healthCaseResultsDialogListItemCheckbox: 'health-case__result-dialog_list_item_checkbox',
  healthCaseResultsDialogListItem: 'health-case__result-dialog_list_item',
  healthCaseResultsDialogCancelButton: 'health-case__result-dialog_cancel-button',
  healthCaseResultsDialogClearAllButton: 'health-case__result-dialog_clear-all-button',
  healthCaseResultsDialogAddResultsButton: 'health-case__result-dialog_add-results-button',
  healthCaseAddResults: 'health-case__add-results',
  healthCaseAddResultsDialog: 'health-case__add-results-dialog',
  healthCaseAddResultsDialogHeaderCloseButton: 'health-case__add-results-dialog_header_close-button',
  healthCaseAddDocuments: 'health-case__add-documents',
  healthCaseDocumentsDialogCategorySelect: 'health-case__documents-dialog_category_select',
  healthCaseDocumentsDialogCategorySelectDropdownItem: 'health-case__documents-dialog_category_select_dropdown_item',
  healthCaseDocumentsDialogDateRange: 'health-case__documents-dialog_date-range',
  healthCaseDocumentsDialogSearchInput: 'health-case__documents-dialog_search-input',
  healthCaseDocumentsDialogOpenFiltersButton: 'health-case__documents-dialog_open-filters-button',
  healthCaseDocumentsDialogTypeButton: 'health-case__documents-dialog_type-button',
  healthCaseDocumentsDialogClearButton: 'health-case__documents-dialog_clear-button',
  healthCaseDocumentsDialogListItem: 'health-case__documents-dialog_list_item',
  healthCaseDocumentsDialogListItemCheckbox: 'health-case__documents-dialog_list_item_checkbox',
  healthCaseDocumentsDialogCancelButton: 'health-case__documents-dialog_cancel-button',
  healthCaseDocumentsDialogAddDocumentsButton: 'health-case__documents-dialog_add-documents-button',
  healthCaseDocumentsDialogAddDocuments: 'health-case__documents',
  healthCaseDocumentsDialogAddDocumentsDialog: 'health-case__documents-dialog',
  healthCaseDocumentsDialogAddDocumentsDialogHeaderCloseButton: 'health-case__documents-dialog_header_close-button',
  healthCaseDeleteDialog: 'health-case__delete-dialog',
  healthCaseDeleteDialogDeleteButton: 'health-case__delete-dialog_delete-button',
  healthCaseDeleteDialogCancelButton: 'health-case__delete-dialog_cancel-button',
  healthCaseDeleteDialogHeaderCloseButton: 'health-case__delete-dialog_header_close-button',

} as const;
