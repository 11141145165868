export const STORAGE_DOCUMENT_DATA_TEST_ID = {
  storageDocument: 'storage-document_',
  storageDocumentDownloadButton: 'storage-document__download-button',
  storageDocumentBreadcrumbsStorage: 'storage-document__breadcrumbs_storage',
  storageDocumentFavoriteButton: 'storage-document__favorite-button',
  storageDocumentDeleteButton: 'storage-document__delete-button',
  storageDocumentShareButton: 'storage-document__share-button',
  storageDocumentEditButton: 'storage-document__edit-button',
  storageDocumentFileImage: 'storage-document__file-image',
} as const;
