export const MULTISELECT_DATA_TEST_ID = {
  multiselectInput: '_multiselect_input',
  multiselectDropdownSearchInput: '_multiselect_search-input',
  multiselectDropdownItem: '_multiselect_dropdown-item',
  multiselectDropdownItemCheckbox: '_multiselect_dropdown-item_checkbox',
  multiselectApplyButton: '_multiselect_apply-button',
  multiselectClearAllButton: '_multiselect_clear-all-button',
  multiselectCancelButton: '_multiselect_cancel-button',
  multiselectDropdown: '_multiselect_dropdown',
} as const;
