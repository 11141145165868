import {DatePickerRange} from '@components';
import {Stack, Typography, SelectChangeEvent} from '@mui/material';
import {Result} from '@src/api';
import {FormSelectControl} from '@src/components/FormSelectControl';
import Grid from '@src/components/Grid';
import {BoxPaddings} from '@src/components/WhiteBox';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {HEALTH_CASE_DATA_TEST_ID} from '@src/pages/HealthCase/testIds';
import {dateInInterval} from '@src/pages/Results/Results.helpers';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {useMQuery} from '@src/shared/hooks';
import {getFullName} from '@src/shared/utils';
import {testId} from '@src/shared/utils/testId';
import {useAppDispatch} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {useResults} from '@src/store/results/hooks';
import {useTranslate} from 'i18n/useTranslate';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  ActionsButton,
  ActionsRow,
  Button,
  IconButton,
  MenuItem,
  OptionValue,
  SelectControl,
} from 'ui-kit';

import {initialFilter} from './constants';
import {sx} from './styles';
import {IFilterResults} from './types';

export const ResultsFilter = () => {
  const {t, ready} = useTranslate('results');
  const {mobile, tabletPortrait} = useMQuery();
  const onPortrait = mobile || tabletPortrait;
  const [isOpen, setIsOpen] = useState(false);

  const {data} = useResults();
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState(initialFilter);
  const [dialogFilters, setDialogFilters] = useState(filter);

  const handleCloseFilter = () => setIsOpen(false);
  const handleOpenFilter = () => {
    setDialogFilters(filter);
    setIsOpen(true);
  };

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (value: string) => {
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('');
  };

  useEffect(() => {
    if (data) {
      dispatch(healthCasesActions.setResult(data));
    }
  }, [data]);

  const getPhysicians = useMemo(() => {
    const uniquePhysiciansMap = new Map(data?.map((item) => [item?.physician?.id, item.physician]));

    const uniquePhysicians = [...uniquePhysiciansMap.values()];

    const physiciansOptions = uniquePhysicians.map((physician) => ({
      value: physician?.id || '',
      label: getFullName(physician),
    }));

    return physiciansOptions.filter((item) => item.label && item.value);
  }, [data]);

  const healthCases = useMemo<OptionValue[]>(() => {
    const allHealthCases = data?.flatMap((item) => item?.healthCases) || [];

    const uniqueHealthCasesMap = new Map(
      allHealthCases.map((healthCase) => [healthCase?.name, healthCase]),
    );

    const uniqueHealthCases = Array.from(uniqueHealthCasesMap.values());

    return uniqueHealthCases.map((healthCase) => ({
      value: healthCase?.id || '',
      label: healthCase?.name || '',
    }));
  }, [data]);

  const filteredCasesOptions = useMemo(() => {
    if (searchValueHealthCase) {
      return healthCases.filter((option) =>
        option.label.toLowerCase().includes(searchValueHealthCase.toLowerCase()),
      );
    }
    return healthCases;
  }, [healthCases, searchValueHealthCase]);

  const handleFilterUpdate = (newFieldValue: Partial<IFilterResults>) => {
    if (isOpen) {
      setDialogFilters((prev) => ({
        ...prev,
        ...newFieldValue,
      }));
      return;
    }
    setFilter((prev) => ({
      ...prev,
      ...newFieldValue,
    }));
  };

  const handlePhysicianSelect = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const selectedPhysician = getPhysicians.find((item) => item.value === selectedValue);

    if (selectedPhysician) {
      handleFilterUpdate({physician: selectedPhysician});
    }
  };

  const handleHealthCaseSelect = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;

    const selectedHealthCases = healthCases.filter((option) => value.includes(option.value));
    handleFilterUpdate({healthCases: selectedHealthCases});
  };

  const filterByPhysician = (resultItem: Result, physician: OptionValue | null) => {
    if (!physician?.value) return true;
    return resultItem.physician?.id === physician.value;
  };

  const filterByHealthCase = (resultItem: Result, selectedHealthCases: OptionValue[]) => {
    if (!selectedHealthCases || selectedHealthCases.length === 0) return true;
    return resultItem.healthCases?.some((hc) =>
      selectedHealthCases.some((selectedCase) => selectedCase.value === hc?.id),
    );
  };

  const filterByCollectionDate = (
    resultItem: Result,
    collectionDateFrom: Date | null,
    collectionDateTo: Date | null,
  ) => {
    if (!collectionDateFrom && !collectionDateTo) return true;
    return dateInInterval(
      collectionDateFrom,
      collectionDateTo,
      resultItem.collected || resultItem.date,
    );
  };

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setDialogFilters((prev) => ({...prev, [name]: date}));
  };

  const handleClearCollectionDate = () => {
    setDialogFilters((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handleApplyFilter = useCallback(() => {
    const {physician, healthCases, collectionDateFrom, collectionDateTo} = filter;

    const doFiltration = (resultItem: Result) => {
      return (
        filterByPhysician(resultItem, physician) &&
        filterByHealthCase(resultItem, healthCases) &&
        filterByCollectionDate(resultItem, collectionDateFrom, collectionDateTo)
      );
    };

    if (data) dispatch(healthCasesActions.setResult(data.filter(doFiltration)));
  }, [data, filter, isOpen]);

  const handleClickApply = () => {
    setFilter(dialogFilters);
    handleCloseFilter();
  };

  const handleClearFilter = () => {
    if (isOpen) {
      setDialogFilters(initialFilter);
      return;
    }
    setFilter(initialFilter);
  };

  useEffect(() => {
    if (!isOpen) {
      handleApplyFilter();
    }
  }, [filter]);

  const handleCasesCheckToggle = (state: IFilterResults['healthCases']) => {
    if (state.length > healthCases.length / 2) {
      handleFilterUpdate({healthCases: []});
    } else {
      handleFilterUpdate({healthCases});
    }
  };

  if (!ready) return null;

  const physicianSelect = (value: IFilterResults['physician']) => (
    <FormSelectControl<OptionValue<string>>
      name={'physician'}
      placeholder={t('SELECT')}
      label={t('PHYSICIAN')}
      value={value || undefined}
      onChange={handlePhysicianSelect}
      data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogPhysicianSelect)}
    >
      {getPhysicians.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-test-id={testId(
            HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogPhysicianSelectDropdownItem,
          )}
        >
          {option.label}
        </MenuItem>
      ))}
    </FormSelectControl>
  );

  const healthCaseSelect = (value: IFilterResults['healthCases']) => (
    <SelectControl<string>
      data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogHealthCaseSelect)}
      value={value}
      onChange={handleHealthCaseSelect}
      multiple
      label={t('HEALTH_CASES')}
      placeholder={t('SELECT')}
      onSearch={handleChangeSearchValue}
      onClose={handleClearSearchValue}
      dropdownFooterRow={
        <ActionsRow>
          <ActionsButton
            data-test-id={testId(
              HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogHealthCaseSelectDropdownCheckUncheckAll,
            )}
            onClick={() => handleCasesCheckToggle(value)}
            noClose={onPortrait}
          >
            {value.length > healthCases.length / 2 ? t('UNCHECK_ALL') : t('CHECK_ALL')}
          </ActionsButton>
        </ActionsRow>
      }
    >
      {filteredCasesOptions.length
        ? (
          filteredCasesOptions.map(({value, label}) => (
            <MenuItem
              key={value}
              value={value}
              data-test-id={testId(
                HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogHealthCaseSelectDropdownItem,
              )}
            >
              {label}
            </MenuItem>
          ))
        )
        : (
          <ActionsRow>{t('NO_SUITABLE_RESULTS')}</ActionsRow>
        )}
    </SelectControl>
  );

  return (
    <>
      {!onPortrait && (
        <BoxPaddings noBottom noTop sx={sx.boxContainer}>
          <Grid
            container
            gap={36}
            flexGrow={1}
            sx={sx.gridContainer}>
            <Grid
              xs={4.5}
              lg={4.5}
              xl={4.5}
              sx={sx.inputWrapper}>
              {physicianSelect(filter.physician)}
            </Grid>
            <Grid
              xs={4.5}
              lg={4.5}
              xl={4.5}
              sx={sx.inputWrapper}>
              {healthCaseSelect(filter.healthCases)}
            </Grid>
            <Stack flexDirection={'row'} gap={12} pt={22}>
              <IconButton
                color={'secondary-light'}
                variant={'outlined'}
                onClick={handleOpenFilter}
                data-test-id={testId(
                  HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogOpenFiltersButton,
                )}
              >
                <SettingsIcon />
              </IconButton>
              <IconButton
                color={'secondary-light'}
                onClick={handleClearFilter}
                data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogClearButton)}
              >
                <RefreshIcon />
              </IconButton>
            </Stack>
          </Grid>
        </BoxPaddings>
      )}
      {onPortrait && (
        <Stack p={18}>
          <Button
            variant={'outlined'}
            color={'secondary'}
            onClick={handleOpenFilter}
            startIcon={
              <SettingsIcon
                data-test-id={testId(
                  HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogOpenFiltersButton,
                )}
              />
            }
          >
            {t('FILTERS')}
          </Button>
        </Stack>
      )}
      <FiltersDialogContainer
        isOpen={isOpen}
        onClose={handleCloseFilter}
        title={t('FILTERS')}
        onApply={handleClickApply}
        onClear={handleClearFilter}
        testIdFeatureGroup={HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialog}
      >
        <Stack gap={24}>
          {onPortrait && (
            <>
              <Grid xs={12} sm={6}>
                {physicianSelect(dialogFilters.physician)}
              </Grid>
              <Grid xs={12} sm={6}>
                {healthCaseSelect(dialogFilters.healthCases)}
              </Grid>
            </>
          )}
          <Stack gap={44}>
            <Stack>
              <Typography variant="14_18_700">{t('COLLECTION_DATE_CALENDAR')}</Typography>
              <DatePickerRange
                variant="outlined"
                startDate={dialogFilters.collectionDateFrom}
                endDate={dialogFilters.collectionDateTo}
                setStartDate={handleChangeDate('collectionDateFrom')}
                setEndDate={handleChangeDate('collectionDateTo')}
                clearDate={handleClearCollectionDate}
                isLabelOutside={false}
                isIconVisible={false}
                dataTestId={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogDateRange)}
              />
            </Stack>
          </Stack>
        </Stack>
      </FiltersDialogContainer>
    </>
  );
};
