import {Stack, SvgIcon, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {Product, Test, TestStatus} from '@src/api';
import {RejectedReason} from '@src/api/__generated__/webApi';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {RESULTS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/resultTestIds';
import {getReferenceText, getResultColor, getStatus} from '@src/pages/Details/helpers';
import {ReactComponent as ArrowIcon} from '@src/shared/assets/icons/arrow-down.svg';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {testId} from '@src/shared/utils/testId';
import {ColumnDef, Row, Table as TanstackTable} from '@tanstack/react-table';
import {ChangeEvent, FC, useMemo} from 'react';
import {Checkbox, IconButtonNew, Tooltip} from 'ui-kit';
import InfoIcon from 'ui-kit/lib/icons/24/Warning/Info';

import {checkBadStatus} from './helpers';
import {sx} from './styles';

interface ResultDetailsTableProps {
  data: Product[]
  handleToggleAllRowsSelection: (table: TanstackTable<Product>, checked: boolean) => void
  handleToggleRowSelection: (row: Product, checked: boolean) => void
  onSetDialogRejectedReasons: (rejectedReasons: RejectedReason[]) => void
}

export const ResultDetailsTable: FC<ResultDetailsTableProps> = ({
  data,
  handleToggleAllRowsSelection,
  handleToggleRowSelection,
  onSetDialogRejectedReasons,
}) => {
  const {t} = useTranslate('details');

  const columns = useMemo<Array<ColumnDef<any>>>(
    () => [
      {
        accessorKey: 'name',
        minSize: 400,
        maxSize: 400,
        header: ({table}) => {
          return (
            <Checkbox
              {...{
                sx: sx.headerCheckBox,
                dataTestId: testId(RESULTS_DATA_TEST_ID.detailsCheckboxAll),
                checked: table.getIsAllRowsSelected(),
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  handleToggleAllRowsSelection(table, e.target.checked),
                label: t('TEST_PANEL'),
              }}
            />
          );
        },
        cell: ({row, getValue}) => {
          const isTest = !row.subRows.length;
          const leftPaddingsByDepth = [4, 38];

          const status = row.original.status;
          const isBadStatus = checkBadStatus(status);

          const isSubRowsBadStatus = row.subRows.length
            ? !row.subRows.find((sRow) => !checkBadStatus(sRow.original.status))
            : false;

          return (
            <Stack
              sx={{
                paddingLeft: isTest ? '102px' : `${leftPaddingsByDepth[row.depth] || 0}px`,
                ...sx.testNameWrapper,
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {row.getCanExpand()
                  ? (
                    <SvgIcon
                      data-test-id={testId(RESULTS_DATA_TEST_ID.detailsTableExpandButton)}
                      sx={{
                        transform: row.getIsExpanded() ? 'rotate(0deg)' : 'rotate(-90deg)',
                        fontSize: '20px',
                        marginRight: '11px',
                        color: '#C7CDD6',
                      }}
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: {cursor: 'pointer'},
                      }}
                    >
                      {row.getIsExpanded() ? <ArrowIcon /> : <ArrowIcon />}
                    </SvgIcon>
                  )
                  : (
                    ''
                  )}
                <Checkbox
                  {...{
                    dataTestId: testId(RESULTS_DATA_TEST_ID.detailsCheckboxTest),
                    disabled: isBadStatus || isSubRowsBadStatus,
                    checked: row.getIsSelected(),
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      row.getToggleSelectedHandler()(e);
                      handleToggleRowSelection(row.original, e.target.checked);
                    },
                    label: getValue<string>() || '',
                  }}
                />
              </Stack>
            </Stack>
          );
        },
        enableSorting: false,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'value',
        id: 'Results',
        cell: ({row}) => {
          if (row.subRows.length) {
            return null;
          }
          const value = row.original.value;
          const status = row.original.status;
          const isRejected = status === TestStatus.REJECTED;
          const rejectedReasons: RejectedReason[] = row.original.rejectedReasons;
          const isBadStatus = checkBadStatus(status);

          const resultColor = getResultColor(row.original);
          const currentValue = getStatus(status, value);
          const fontVariant = resultColor && !isBadStatus ? '14_18_700' : '14_18_500';

          return (
            <Typography
              variant={fontVariant}
              sx={{
                color: resultColor,
                paddingTop: 7,
                fontStyle: isBadStatus ? 'italic' : 'inherit',
              }}
            >
              {t(currentValue as TKeys<'details'>) || '--'}
              {isRejected && rejectedReasons && (
                <Tooltip
                  title={
                    <Box component={'ul'} sx={{...sx.rejectedReasonsList, pl: rejectedReasons.length > 1 ? 25 : 0}}>
                      {rejectedReasons.map(({name, comment}, index) => (
                        <Box key={index} component={rejectedReasons.length > 1 ? 'li' : 'span'}>
                          <b>
                            {name}
                            {comment ? ': ' : '.'}
                          </b>
                          {comment}
                        </Box>
                      ))}
                    </Box>
                  }
                  placement={'right'}
                  sx={sx.rejectedTooltip}
                >
                  <IconButtonNew
                    sx={sx.infoIconButton}
                    size={'lg'}
                    color={'quaternary'}
                    onClick={() => onSetDialogRejectedReasons(rejectedReasons)}
                  >
                    <InfoIcon />
                  </IconButtonNew>
                </Tooltip>
              )}
            </Typography>
          );
        },
        header: () => <Box>{t('RESULTS')}</Box>,
        footer: (props) => props.column.id,
        minSize: 136,
        enableSorting: false,
      },
      {
        accessorKey: 'units',
        id: 'units',
        minSize: 130,
        maxSize: 130,
        cell: ({row}) => {
          if (row.subRows.length) {
            return null;
          }
          const status = row.original.status;
          const isBadStatus = checkBadStatus(status);
          if (isBadStatus) {
            return null;
          }

          const units = row.original.units;
          return (
            <Typography variant="14_18_500" pt={5}>
              {units}
            </Typography>
          );
        },
        header: () => <Box>{t('UNITS')}</Box>,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: 'comment',
        id: 'comment',
        minSize: 136,
        cell: (info) => {
          const {row} = info;
          if (row.subRows.length) {
            return null;
          }
          const status = row.original.status;
          const isBadStatus = checkBadStatus(status);
          if (isBadStatus) {
            return null;
          }

          const comment = row.original.comment;
          return (
            <EllipsisTextTooltip
              ellipsisText={comment || t('NO_COMMENTS')}
              heightCheck
              variant={'14_18_500'}
              sx={sx.commentText}
            />
          );
        },
        header: () => <Box>{t('COMMENTS')}</Box>,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: 'reference',
        id: 'reference',
        minSize: 230,
        maxSize: 230,
        enableSorting: false,
        header: () => <Box>{t('REFERENCE_INTERVAL')}</Box>,
        cell: ({row}) => {
          if (row.subRows.length) {
            return null;
          }
          const status = row.original.status;
          const isBadStatus = checkBadStatus(status);
          if (isBadStatus) {
            return null;
          }
          return <Box sx={{whiteSpace: 'pre-wrap'}}>{getReferenceText(row.original, t)}</Box>;
        },
      },
    ],
    [],
  );

  const isError = (row: Test) => {
    return !!row.criticalReference?.isOutOfRef;
  };

  const getEnableRowSelection = (row: Row<Test>) => {
    if (row.subRows.length) {
      return !!row.subRows.find((sRow) => !checkBadStatus(sRow.original.status));
    }
    return !checkBadStatus(row.original.status);
  };

  const getRowSx = (row: Row<Test>) => {
    if (row.original.status === TestStatus.IN_PROGRESS) {
      return sx.inProgressRow;
    }
    return {};
  };

  return (
    <NewTable
      columns={columns}
      data={data}
      heightColumns="auto"
      isError={isError}
      subRowKeys={['tests', 'products']}
      enableRowSelection={getEnableRowSelection}
      rowSx={getRowSx}
    />
  );
};
