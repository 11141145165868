import {Box, useTheme} from '@mui/material';
import {EmptySearch} from '@src/components';
import {DateTimeFormat, formatDate} from '@src/components/DateFormat';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {Cell} from '@src/pages/Dynamics/Board/TableComponents';
import {generateRefText} from '@src/pages/Dynamics/Dynamics.helper';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {getCriticalColor} from '@src/shared/utils/results';
import {DynamicResultWithAnalysesData} from '@src/store/results/helpers';
import {createColumnHelper} from '@tanstack/react-table';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useMemo} from 'react';

import {sx} from './styles';
import {ResultDynamicsTableProps} from './types';

export const ResultDynamicsTable: FC<ResultDynamicsTableProps> = ({dynamicsWithTests, isLoading}) => {
  const {t} = useTranslate('details');
  const theme = useTheme();

  const preparedForTableData = useMemo(() => {
    return dynamicsWithTests.map(({resultLaboratoryAnalyses, refMin, refText, criticalRefMin, refMax, criticalRefMax, testName, unit, ...otherData}) => {
      const transformedResults = resultLaboratoryAnalyses.reduce<
      Record<string, DynamicResultWithAnalysesData>
      >((acc, result, currentIndex) => {
        acc[String(currentIndex)] = result;
        return acc;
      }, {});

      const refData = {
        refMin, refText, criticalRefMin, refMax, criticalRefMax,
      };

      return {
        testName,
        unit,
        refData,
        otherData,
        ...transformedResults,
      };
    });
  }, [dynamicsWithTests]);

  const columnHelper = createColumnHelper<typeof preparedForTableData>();

  const columnsForTable = useMemo(() => {
    if (!preparedForTableData?.[0]) {
      return [];
    }
    const {refData, otherData, unit, testName, ...datesValues} = preparedForTableData?.[0];
    return [
      columnHelper.accessor('testName', {
        id: 'testName',
        header: t('TEST'),
        minSize: 300,
        maxSize: 300,
        enableSorting: false,
        cell: ({getValue}) => {
          return (
            <EllipsisTextTooltip
              variant='14_18_500'
              ellipsisText={getValue() as string}
            />
          );
        },
      }),
      columnHelper.accessor('unit', {
        id: 'units',
        header: t('UNITS'),
        minSize: 150,
        maxSize: 150,
        enableSorting: false,
        cell: ({getValue}) => getValue() || '',
      }),
      columnHelper.accessor('refData', {
        id: 'reference',
        header: t('REFERENCE_RANGE'),
        minSize: 200,
        maxSize: 200,
        enableSorting: false,
        cell: ({getValue}) => {
          const {
            refMax,
            refMin,
            refText,
          } = getValue() as any;
          return generateRefText({refMax, refMin, refText});
        },
      }),
      ...Object.entries(datesValues).map(([dateColumnIndex, value]) => {
        const typedValue = value as DynamicResultWithAnalysesData;

        // eslint-disable-next-line no-comments/disallowComments
        // @ts-expect-error
        return columnHelper.accessor(dateColumnIndex, {
          minSize: 160,
          maxSize: 160,
          header: formatDate(typedValue.biomaterialSamplingDate, DateTimeFormat.USDateSlashed) || '',
          enableSorting: false,
          cell: (row) => {
            const typedCellValue = row.getValue() as DynamicResultWithAnalysesData;
            if (!typedCellValue) {
              return null;
            }
            const criticalColor = getCriticalColor(typedCellValue, theme);
            return (
              <Cell
                value={typedCellValue.value || ''}
                comment={typedCellValue.comment || ''}
                color={criticalColor}
                isCriticalRefMark={typedCellValue.isCriticalRefMark}
              />
            );
          },
        });
      }),
    ];
  }, [preparedForTableData]);

  if (isLoading || dynamicsWithTests.length === 0) {
    return (
      <Box sx={sx.emptySearch}>
        <EmptySearch isLoading={isLoading} isNew />
      </Box>
    );
  }

  return (
    <NewTable
      columns={columnsForTable}
      data={preparedForTableData}
    />
  );
};
