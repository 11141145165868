export const HEALTH_CASE_SELECT_DATA_TEST_ID = {
  healthCaseSelectSearchInput: '_health-case-select_search-input',
  healthCaseSelectDropdownItem: '_health-case-select_dropdown-item',
  healthCaseSelectShowDropdownButton: '_health-case-select_show-dropdown-button',
  healthCaseSelect: '_health-case-select',
  healthCaseSelectDropdownCreateButton: '_health-case-select_dropdown_create-button',
  healthCaseSelectCreateDialog: '_health-case-select_create-dialog',
  healthCaseSelectCreateDialogNameInput: '_health-case-select_create-dialog_name-input',
  healthCaseSelectCreateDialogAddButton: '_health-case-select_create-dialog_add-button',
  healthCaseSelectCreateDialogTitleCloseButton: '_health-case-select_create-dialog_title_close-button',
  healthCaseSelectCreateDialogFooterCloseButton: '_health-case-select_create-dialog_footer_close-button',
} as const;
