/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {HEALTH_CASE_DATA_TEST_ID} from '@src/pages/HealthCase/testIds';
import {useFileData} from '@src/pages/StorageDocument/hooks';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowSquareOutIcon} from '@src/shared/assets/icons/arrow_square_out_btn.svg';
import {ReactComponent as CloseIcon} from '@src/shared/assets/icons/close.svg';
import {ReactComponent as InformationIcon} from '@src/shared/assets/icons/info.svg';
import {ReactComponent as MenuOptionIcon} from '@src/shared/assets/icons/vertical-dots.svg';
import {useMQuery} from '@src/shared/hooks';
import {MobileDialog} from '@src/shared/ui/MobileDialog/MobileDialog';
import {DATE_FORMAT, dateToFormat} from '@src/shared/utils';
import {openBase64NewTab} from '@src/shared/utils/pdfHelpers';
import {testId} from '@src/shared/utils/testId';
import {useAppDispatch} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {initialColors} from '@src/theme';
import {MouseEvent, useCallback, useEffect, useState} from 'react';
import {generatePath} from 'react-router-dom';
import {IconButtonNew, Tooltip} from 'ui-kit';

import {sx} from './styles';
import {FileCardProps} from './types';

export const FileCard = ({data, openMenuId, getOpenMenuId}: FileCardProps) => {
  const {handleFetchFileData} = useFileData(data.id);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const {mobile} = useMQuery();
  const isOpenMenu = openMenuId === data.id;
  const dispatch = useAppDispatch();
  const {t} = useTranslate('healthCases');

  const menuItemStyle = {
    ...sx.menuItem,
    borderTop: `1px solid ${initialColors.blueLight}`,
  };

  const mobileMenuItemStyle = {
    ...sx.menuItem,
    borderTop: `1px solid ${initialColors.blueLight}`,
  };

  const handleMenuToggle = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (e.currentTarget) {
      getOpenMenuId(data.id);
      setAnchorEl(e.currentTarget);
    }
  }, []);

  const handleRemove = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    dispatch(healthCasesActions.removeDocument(data.id));
    getOpenMenuId(null);
  };

  const handleViewPage = () => {
    if (!data.id) return;
    const url = generatePath(ROUTERS_PATH.STORAGE_DOCUMENT, {
      id: data.id,
    });
    window.open(url, '_blank');
    getOpenMenuId(null);
  };

  const handleOpenFile = async () => {
    try {
      const newFileData = await handleFetchFileData();
      if (newFileData) {
        openBase64NewTab(newFileData.data, newFileData.contentType);
      }
    } catch (e) {
      console.error(e);
    } finally {
      getOpenMenuId(null);
    }
  };

  useEffect(() => {
    if (openMenuId !== data.id) {
      setAnchorEl(null);
    }
  }, [openMenuId]);

  return (
    <>
      <Stack key={data.id} gap={4}>
        <Tooltip title={data.name} placement="top">
          <Stack
            sx={sx.imageContainer}
            onClick={handleOpenFile}
            data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItem)}
          >
            <Box
              sx={sx.image}
              component={'img'}
              src={data?.src}
              alt={data.name} />
          </Stack>
        </Tooltip>
        <Typography sx={sx.name} variant="14_18_600">
          {data.name}
        </Typography>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography variant="12_18_500" color={'grey.400'}>
            {dateToFormat(DATE_FORMAT, data.date || '')}
          </Typography>
          <IconButtonNew
            onClick={handleMenuToggle}
            aria-haspopup="true"
            color={'tertiary'}
            sx={sx.menuOptionBtn}
            size={'xs'}
            data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItemMenuButton)}
          >
            <MenuOptionIcon />
          </IconButtonNew>
          {!mobile && (
            <Popper
              data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItemMenuDropdown)}
              open={isOpenMenu}
              anchorEl={anchorEl}
              role={undefined}
              placement="bottom-start"
              transition
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [-10, 0],
                  },
                },
              ]}
            >
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => getOpenMenuId(null)}>
                      <MenuList
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        sx={{padding: 0}}
                      >
                        <MenuItem
                          onClick={handleOpenFile}
                          sx={sx.menuItem}
                          data-test-id={testId(
                            HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItemMenuView,
                          )}
                        >
                          <SvgIcon color="action" sx={sx.svgIcon}>
                            <ArrowSquareOutIcon />
                          </SvgIcon>
                          <Typography variant="14_18_500">{t('VIEW')}</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleViewPage}
                          sx={sx.menuItem}
                          data-test-id={testId(
                            HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItemMenuDetails,
                          )}
                        >
                          <SvgIcon color="action" sx={sx.svgIcon}>
                            <InformationIcon />
                          </SvgIcon>
                          <Typography variant="14_18_500">
                            {t('OPEN_DETAILS_IN_STORAGE')}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleRemove}
                          sx={menuItemStyle}
                          data-test-id={testId(
                            HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItemMenuRemove,
                          )}
                        >
                          <SvgIcon color="action" sx={sx.svgIcon}>
                            <CloseIcon />
                          </SvgIcon>
                          <Typography variant="14_18_500">
                            {t('REMOVE_FROM_HEALTH_CASE')}
                          </Typography>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
        </Stack>
      </Stack>
      {mobile && (
        <MobileDialog
          isOpen={isOpenMenu}
          onClose={() => getOpenMenuId(null)}
          padding="0"
          data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItemMenuMobile)}
        >
          <Stack>
            <Typography variant={'16_20_700'} sx={{padding: '18px 12px'}} color={'grey.700'}>
              {data.name}
            </Typography>
            <MenuList
              id="composition-menu"
              aria-labelledby="composition-button"
              sx={sx.mobileMenuList}
            >
              <MenuItem
                onClick={handleOpenFile}
                sx={sx.mobileMenuItem}
                data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItemMenuView)}
              >
                <SvgIcon color="action" sx={sx.svgIcon}>
                  <ArrowSquareOutIcon />
                </SvgIcon>
                <Typography variant="14_18_500">{t('VIEW')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={handleViewPage}
                sx={sx.mobileMenuItem}
                data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItemMenuDetails)}
              >
                <SvgIcon color="action" sx={sx.svgIcon}>
                  <InformationIcon />
                </SvgIcon>
                <Typography variant="14_18_500">{t('OPEN_DETAILS_IN_STORAGE')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={handleRemove}
                sx={mobileMenuItemStyle}
                data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseDocumentsItemMenuRemove)}
              >
                <SvgIcon color="action" sx={sx.svgIcon}>
                  <CloseIcon />
                </SvgIcon>
                <Typography variant="14_18_500">{t('REMOVE_FROM_HEALTH_CASE')}</Typography>
              </MenuItem>
            </MenuList>
          </Stack>
        </MobileDialog>
      )}
    </>
  );
};
