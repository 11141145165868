/* eslint-disable @typescript-eslint/dot-notation */
import {Stack, Typography, debounce, Box} from '@mui/material';
import {RelationType} from '@src/api';
import {
  RelationshipType,
  RelationErrorType,
  RequestRelationType,
  RelationEmailError,
} from '@src/api/relations';
import {Breadcrumbs, Link, RowInformation, Text} from '@src/components';
import {ComboBoxInput} from '@src/components/ComboBoxInput';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {GoogleAutocomplete} from '@src/components/Google/Autocomplete/GoogleAutocomplete';
import {useGeocoder} from '@src/components/Google/hooks';
import Grid from '@src/components/Grid';
import {ListWrapper} from '@src/components/ListWrapper';
import {PulseLoader} from '@src/components/PulseLoader';
import {RadioButtons} from '@src/components/RadioButtons';
import {WBox} from '@src/components/WhiteBox';
import {DateControl} from '@src/components/form/DateControl';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {POSTAL_CODE_LENGTH} from '@src/pages/Connections/constants';
import {CREATE_NEW_PATIENT_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/CreateNewPatient/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowLeftIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {
  BIRTH_SEX_TYPE_MAP,
  ETHNICITY_TYPE_MAP,
  GENDER_TYPE_MAP,
  RACE_TYPE_MAP,
  RELATIONSHIP_TYPE_MAP,
  REQUEST_RELATION_TYPE_MAP,
  SEXYAL_ORIENTATION_TYPE_MAP,
  STATES_TYPE_MAP,
} from '@src/shared/constants/enumsMaps';
import {
  MAX_ADDRESS_LENGTH,
  MAX_CITY_LENGTH,
  MAX_INPUT_LENGTH,
  MAX_POLICY_ID_LENGTH,
  MAX_SSN_LENGTH,
} from '@src/shared/constants/formFields';
import {useSidebarClassObserver, useColumnsNumber} from '@src/shared/hooks';
import {useErrorTextHeightObserve} from '@src/shared/hooks/useErrorTextHeightObserve';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useRecaptcha} from '@src/shared/hooks/useRecaptcha';
import {
  NO_VALUE,
  dateFormatted,
  dateToFormat,
  getFullName,
  getPrimaryInsurance,
} from '@src/shared/utils';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';
import {getFullAddress} from '@src/shared/utils/getFullAddress';
import {normalizeString} from '@src/shared/utils/normalizeString';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {testId} from '@src/shared/utils/testId';
import {validateValue} from '@src/shared/utils/validateValue';
import {useAppSelector} from '@src/store';
import {useAccountProfile} from '@src/store/account/hooks';
import {useInsurances} from '@src/store/insurance/hooks';
import {useCreateRelationContact} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {FullAddress, PlaceResult, Steps} from '@src/types';
import {Formik} from 'formik';
import {isEqual} from 'lodash-es';
import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {PhoneInputControl, Button, InputControl, MenuItem} from 'ui-kit';

import {SendRequestSuccessModal} from '../SendRequestSuccessModal';
import {
  ethnicityOptions,
  genderOptions,
  raceOptions,
  sexualOrientationOptions,
} from '../fragments/RelationEditLayouts/constants';
import {checkIfPearsonYounger18} from '../helpers/checkIfPearsonYounger18';
import {checkIfPersonOlderThanUser} from '../helpers/checkIfPersonOlderThanUser';
import {checkIsPeroson18OrOlder} from '../helpers/checkIsPeroson18OrOlder';

import {
  birthSexSelectOptions,
  relationshipTypesGuarantorSelectOptions,
  relationshipTypesSelectOptions,
  statesSelectOptions,
  touchedData,
} from './constants';
import {ErrorModal} from './fragments/ErrorModal';
import {FailureModal} from './fragments/FailureModal';
import {ParentRoleModal} from './fragments/ParentRoleModal';
import {SuccessModal} from './fragments/SuccessModal';
import {getInitialValues, handleChangeRadioBth} from './helpers';
import {useInsuranceState, useValidationSchema} from './hooks';
import {StyledForm, sx} from './styles';
import {EWhichPrimaryInsuranceUse} from './types';

export const CreateNewPatient = () => {
  useRecaptcha();

  const {t, ready} = useTranslate('connections');
  const {t: tEnums, ready: enumsReady} = useTranslate('enums');
  const {mobile: isMobile} = useMQuery();

  const [relationType, setRelationType] = useState<RequestRelationType | null>(null);
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);
  const [availableOptions, setAvailableOptions] = useState(relationshipTypesGuarantorSelectOptions);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [value, setValue] = useState<null | FullAddress>(null);
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const resetHandlerRef = useRef({reset: () => {}});

  const {accountProfile} = useAccountProfile();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {healthcareInsurancePolicies} = useInsurances();
  const validationSchema = useValidationSchema(isOptionSelected);

  const {
    createRelationContact,
    successCreatedContact,
    failureCreatedContact,
    fetching: isFetching,
  } = useCreateRelationContact();

  const {isSidebarOpen} = useSidebarClassObserver();
  const getGeocoderResult = useGeocoder();
  const {
    selectedCarrier,
    setSelectedCarrier,
    insurancePlans,
    setSearchPrimaryText,
    showInsuranceErrorModal,
    setShowInsuranceErrorModal,
    isInsurancePlansEmpty,
  } = useInsuranceState();

  const insurance = getPrimaryInsurance(healthcareInsurancePolicies);

  const insuranceCarriers = useAppSelector((state) => state.insurance.insuranceCarriers);
  const {step, birthOfDateError, emailError, lastFailedEmail} = useAppSelector(
    (state) => state.relations,
  );

  const tabletGapForInsuranceBlock = 36;
  const isMobileGapForInsuranceBlock = isMobile ? 48 : 24;
  const gapForInsuranceBlockWith = isMobile ? 36 : tabletGapForInsuranceBlock;
  const gapForInsuranceBlockWithout = isMobile ? 24 : isMobileGapForInsuranceBlock;

  const accountProfileValidInsurance = accountProfile?.healthcareInsurancePolicies?.find(
    (insurance) =>
      (compareDatesWithToday(insurance.endDate, false, true) || insurance.endDate === null) &&
      insurance.primary,
  );

  const handlePrimaryTextChange = (value: string) => setSearchPrimaryText(value);

  const debouncedPrimaryChangeHandler = useCallback(debounce(handlePrimaryTextChange, 300), []);

  const {errorRef, isErrorTall} = useErrorTextHeightObserve();

  const setCarrier = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    setSelectedCarrier(value);
    handlePrimaryTextChange(value);
    setFieldValue('insuranceCarrier', value);

    if (setFieldValue && value !== selectedCarrier) {
      setFieldValue('insurancePlan', null);
    }
  };

  const birthDateError = (birthDate: any, relationship: any) => {
    const userBirthDate = accountProfile?.contact && accountProfile?.contact?.birthDate;
    const isPersonOlder18 = checkIsPeroson18OrOlder(birthDate);
    const isPersonOlderThanUser = checkIfPersonOlderThanUser(birthDate, userBirthDate);

    if (
      birthOfDateError === RelationErrorType.GREATER_EIGHTEEN_ERROR &&
      !isPersonOlder18 &&
      relationship !== RequestRelationType.CHD &&
      relationship !== RequestRelationType.FCH
    ) {
      return t('THIS_USER_MUST_BE_AT_LEAST_18_YO');
    }
    if (
      birthOfDateError === RelationErrorType.PARENT_AGE_ERROR &&
      isPersonOlderThanUser &&
      (relationship === RequestRelationType.CHD || relationship === RequestRelationType.GCH)
    ) {
      return t('THIS_USER_MUST_BE_YOUNGER_THAN_YOU');
    }
    if (
      birthOfDateError === RelationErrorType.PARENT_AGE_ERROR &&
      !isPersonOlderThanUser &&
      isPersonOlder18 &&
      (relationship === RequestRelationType.MTH ||
        relationship === RequestRelationType.FTH ||
        relationship === RequestRelationType.GRP)
    ) {
      return t('THIS_USER_MUST_BE_OLDER_THAN_YOU');
    }
    if (birthOfDateError === RelationErrorType.LESS_EIGHTEEN_ERROR && isPersonOlder18) {
      return t('THIS_USER_MUST_BE_UNDER_18_TO');
    }
  };

  const duplicateEmailError =
    emailError === RelationEmailError.CONTACT_EMAIL_UNIQUE ? t('DUPLICATE_EMAIL') : '';

  const initRelationshipType = () => {
    const queryParams = new URLSearchParams(location.search);
    const relationshipTypeFromQuery = queryParams.get('relationshipType');
    return relationshipTypeFromQuery as RequestRelationType | null;
  };

  const initialValue = useMemo(
    () =>
      getInitialValues({
        insurance,
        accountProfileValidInsurance,
        initRelationshipType: initRelationshipType(),
      }),
    [],
  );

  // TODO [tech-debt]: Code bug (figure out why changes are not applied when moving constants to the constants.ts file)
  const breakpoints = {xxl: 2123, xl: 1476, lg: 1002, sm: 592, md: 434};
  const defaultColumns = {xxl: 4, xl: 4, lg: 6, sm: 6};
  const customColumns = {xxl: 4, xl: 6, lg: 12, sm: 12};

  const {colsNumberSm, containerRef} = useColumnsNumber({
    breakpoints,
    defaultColumns,
    customColumns,
  });

  const handleChangeRelationType = (
    e: { target: { value: string } },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    handleSetGuarantorFields: (event: { target: { value: string } }) => void,
  ) => {
    setFieldValue('guarantorRelationshipType', e.target.value, true);
    handleSetGuarantorFields(e);
  };

  const handleClickBreadcrumbs = () =>
    navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION, {state: {toAddRelationPage: true}});

  const handleCloseErrorModal = () => {
    dispatch(relationsActions.setStep({step: Steps.INIT}));
    setShowInsuranceErrorModal(false);
    setIsErrorModalOpen(false);
  };

  const getNormalizeFormValues = (values: typeof initialValue) => {
    return {
      ...values,
      firstName: normalizeString(values.firstName),
      lastName: normalizeString(values.lastName),
      middleName: normalizeString(values.middleName),
      city: normalizeString(values.city),
      address1: normalizeString(values.address1),
      address2: normalizeString(values.address2),
      guarantorAddress1: normalizeString(values.guarantorAddress1),
      guarantorAddress2: normalizeString(values.guarantorAddress2),
      guarantorCity: normalizeString(values.guarantorCity),
      guarantorFirstName: normalizeString(values.guarantorFirstName),
      guarantorLastName: normalizeString(values.guarantorLastName),
      guarantorMiddleName: normalizeString(values.guarantorMiddleName),
      guarantorRelationshipType:
        values.guarantorRelationshipType !== RelationType.SELF
          ? values.guarantorRelationshipType
          : null,
      insurancePlanId:
        insurancePlans?.find((plan) => plan.name === values.insurancePlan)?.id || null,
    };
  };

  const showLoader = isFetching || !ready || !enumsReady;

  useEffect(() => {
    if (step === Steps.ERROR && !birthOfDateError && !emailError) {
      setIsErrorModalOpen(true);
    }
  }, [step, birthOfDateError, emailError]);

  useEffect(() => {
    const isYoungerThan18 = checkIfPearsonYounger18(dateOfBirth);
    if (isYoungerThan18 === null || isYoungerThan18) {
      setAvailableOptions(relationshipTypesGuarantorSelectOptions);
    } else {
      setAvailableOptions(
        relationshipTypesGuarantorSelectOptions.filter(
          (option) => option.name !== RelationshipType.Self,
        ),
      );
    }
  }, [dateOfBirth]);

  useEffect(() => {
    return () => {
      dispatch(relationsActions.setClearEmailError());
      dispatch(relationsActions.setStep({step: Steps.INIT}));
    };
  }, [dispatch]);

  if (showLoader) {
    return (
      <WBox sx={sx.loaderContainer}>
        <PulseLoader loading />
      </WBox>
    );
  }

  return (
    <>
      <Formik<typeof initialValue>
        validateOnChange
        validateOnBlur
        enableReinitialize
        onSubmit={(values) => {
          if (!isEqual(initialValue, values)) {
            const normalizedValues = getNormalizeFormValues(values);

            createRelationContact(normalizedValues);
          }
        }}
        initialValues={initialValue}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          setTouched,
          setFieldTouched,
          handleSubmit,
          validateField,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const handleAutocomplete = useCallback((result: PlaceResult | null) => {
            const fullAddress = result ? getFullAddress(result) : null;
            if (fullAddress) {
              setValue(fullAddress);
              setFieldValue('address1', fullAddress.streetAddress || '');
              setFieldValue('city', fullAddress.city || '');
              setFieldValue('state', fullAddress.state || '');
              setFieldValue('postalCode', fullAddress.postalCode || '');
              setFieldValue('location', {
                googlePlaceId: fullAddress.googlePlaceId || '',
                latitude: fullAddress.latitude || 0,
                longitude: fullAddress.longitude || 0,
              });
              setFieldValue('streetNumber', fullAddress.streetAddress?.split(' ')[0] || '');
              setIsOptionSelected(true);
            } else {
              resetHandlerRef.current.reset();
              setFieldValue('address1', '', true);
              setIsOptionSelected(false);
            }
          }, []);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const handleAutocompleteRef = useRef(handleAutocomplete);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const handleChangeAddress1 = useCallback(
            (newValue: string) => {
              setFieldValue?.('address1', newValue);
            },
            [setFieldValue],
          );

          const handleSetGuarantorFields = (event: { target: { value: string } }) => {
            const selectedValue = event.target.value;

            if (selectedValue === RelationshipType.Self) {
              setFieldValue('guarantorFirstName', values.firstName || '', true);
              setFieldValue('guarantorLastName', values.lastName || '', true);
              setFieldValue('guarantorMiddleName', values.middleName || '', true);
              setFieldValue('guarantorBirthDate', values.birthDate || '', true);
              setFieldValue('guarantorBirthSex', values.birthSex || '', true);
              setFieldValue('guarantorState', values.state || '', true);
              setFieldValue('guarantorCity', values.city || '', true);
              setFieldValue('guarantorAddress1', values.address1 || '', true);
              setFieldValue('guarantorAddress2', values.address2 || '', true);
              setFieldValue('guarantorPostalCode', values.postalCode || '', true);
              setFieldValue('guarantorPhone', values.phone || '', true);
            } else {
              setFieldValue('guarantorFirstName', '', true);
              setFieldValue('guarantorLastName', '', true);
              setFieldValue('guarantorMiddleName', '', true);
              setFieldValue('guarantorBirthDate', '', true);
              setFieldValue('guarantorBirthSex', '', true);
              setFieldValue('guarantorState', '', true);
              setFieldValue('guarantorCity', '', true);
              setFieldValue('guarantorAddress1', '', true);
              setFieldValue('guarantorAddress2', '', true);
              setFieldValue('guarantorPostalCode', '', true);
              setFieldValue('guarantorPhone', '', true);
            }
          };

          const handleChangeZipCode = (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            const validatedValue = validateValue(value);

            setFieldValue('guarantorPostalCode', validatedValue);

            const newEvent = {
              ...e,
              target: {
                ...e.target,
                value: validatedValue,
              },
            };

            handleChange(newEvent);
          };

          const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
            const newEmail = e.target.value;

            handleChange(e);

            if (newEmail === lastFailedEmail) {
              dispatch(
                relationsActions.setEmailError({
                  error: RelationEmailError.CONTACT_EMAIL_UNIQUE,
                  email: newEmail,
                }),
              );
            } else {
              if (emailError) {
                dispatch(relationsActions.setClearEmailError());
                dispatch(relationsActions.setStep({step: Steps.INIT}));
              }
            }
          };

          const handleCreateRelationContact = () => {
            const normalizedValues = getNormalizeFormValues(values);
            createRelationContact(normalizedValues, true);
          };

          const getBirthDateError = () => {
            if (!touched?.birthDate) return '';

            if (!values.birthDate) {
              return errors.birthDate;
            }

            return birthDateError(values.birthDate, values.relationShipType) || '';
          };

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (values.birthDate) {
              const isYoungerThan18 = checkIfPearsonYounger18(values.birthDate);

              setDateOfBirth(values.birthDate);

              if (!isYoungerThan18) {
                setFieldValue('guarantorRelationshipType', '', true);
                handleSetGuarantorFields({target: {value: ''}});
              }
            }
          }, [values.birthDate, dateOfBirth]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (values.guarantorRelationshipType) {
              handleSetGuarantorFields({target: {value: values.guarantorRelationshipType}});
            }
          }, [values.guarantorRelationshipType]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const hasInitialValue = initialValue?.location.googlePlaceId || initialValue?.address1;
            const hasHandler = !!getGeocoderResult;

            if (hasInitialValue && hasHandler) {
              const request = {
                placeId: initialValue.location.googlePlaceId,
                address: initialValue.location.googlePlaceId ? undefined : initialValue.address1,
              };

              if (!request.placeId && !request.address) return;

              void getGeocoderResult(request)
                .then((result: any) => {
                  if (!result) return;

                  const placeResult = result as google.maps.places.PlaceResult;
                  handleAutocompleteRef.current(placeResult);
                  const fullAddressData = getFullAddress(placeResult);
                  setFieldValue('address1', fullAddressData.streetAddress || '');
                  setFieldValue('city', fullAddressData.city || '');
                  setFieldValue('state', fullAddressData.state || '');
                  setFieldValue('postalCode', fullAddressData.postalCode || '');
                  setFieldValue('location', {
                    googlePlaceId: fullAddressData.googlePlaceId || '',
                    latitude: fullAddressData.latitude || 0,
                    longitude: fullAddressData.longitude || 0,
                  });
                  setFieldValue('streetNumber', fullAddressData.streetAddress?.split(' ')[0] || '');
                })
                .catch((error: string) => {
                  console.error('Error calling geocoder:', error);
                });
            }
          }, [getGeocoderResult, initialValue?.address1, initialValue?.location.googlePlaceId]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateField('streetNumber');
          }, [values.address1]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (selectedCarrier.trim() === '') {
              setFieldValue('insuranceCarrier', '', true);
            }
          }, [selectedCarrier]);

          return (
            <StyledForm>
              <WBox sx={sx.mainContainer}>
                <Stack sx={sx.contentContainer}>
                  {!isMobile && (
                    <Breadcrumbs>
                      <Link
                        to={ROUTERS_PATH.CONNECTIONS_RELATIONS}
                        data-test-id={testId(
                          CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientBreadcrumbsRelations,
                        )}
                      >
                        {t('RELATIONS')}
                      </Link>
                      <Link
                        to={ROUTERS_PATH.CONNECTIONS_ADD_RELATION}
                        state={{toAddRelationPage: true}}
                        data-test-id={testId(
                          CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientBreadcrumbsAddRelation,
                        )}
                      >
                        {t('ADD_RELATION')}
                      </Link>
                      <Text sx={sx.textColor}>{t('CREATE_A_NEW_PATIENT')}</Text>
                    </Breadcrumbs>
                  )}
                  {isMobile && (
                    <Stack
                      sx={sx.breadCrumbsMobile}
                      onClick={handleClickBreadcrumbs}
                      data-test-id={testId(
                        CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientBreadcrumbsAddRelation,
                      )}
                    >
                      <ArrowLeftIcon />
                      <Typography variant="14_18_600">{t('SEARCH')}</Typography>
                    </Stack>
                  )}
                  <Grid
                    ref={containerRef}
                    container
                    spacing={24}
                    columnSpacing={{xs: 18, sm: 36, lg: 48}}
                  >
                    <Grid xl={4} sm={colsNumberSm} xs={12}>
                      <FormSelectControl<keyof typeof REQUEST_RELATION_TYPE_MAP>
                        placeholder={t('SELECT')}
                        name="relationShipType"
                        label={t('RELATIONSHIP_TYPE')}
                        renderValue={(v) => (v ? tEnums(REQUEST_RELATION_TYPE_MAP[v]) : '')}
                        data-test-id={testId(
                          CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientRelationshipTypeSelect,
                        )}
                      >
                        {relationshipTypesSelectOptions.map(({name, value}, key) => (
                          <MenuItem
                            key={key}
                            value={value}
                            data-test-id={testId(
                              CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientRelationshipTypeSelectDropdownItem,
                            )}
                          >
                            {tEnums(name)}
                          </MenuItem>
                        ))}
                      </FormSelectControl>
                    </Grid>
                  </Grid>
                  <Stack gap={isMobile ? 16 : 12}>
                    <Typography sx={sx.title} variant="18_24_500">
                      {t('PATIENT_INFORMATION')}
                    </Typography>
                    <Grid container spacing={24} columnSpacing={{xs: 18, sm: 36, lg: 48}}>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <InputControl
                          maxlength={MAX_INPUT_LENGTH}
                          name="firstName"
                          label={t('FIRST_NAME')}
                          value={values.firstName || ''}
                          error={touched?.firstName ? errors.firstName : ''}
                          onChange={handleChange}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientFirstNameInput,
                          )}
                        />
                      </Grid>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <InputControl
                          maxlength={MAX_INPUT_LENGTH}
                          name="lastName"
                          label={t('LAST_NAME')}
                          value={values.lastName || ''}
                          error={touched?.lastName ? errors.lastName : ''}
                          onChange={handleChange}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientLastNameInput,
                          )}
                        />
                      </Grid>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <InputControl
                          maxlength={MAX_INPUT_LENGTH}
                          optional
                          name="middleName"
                          label={t('MIDDLE_NAME')}
                          value={values.middleName || ''}
                          error={touched?.middleName ? errors.middleName : ''}
                          onChange={handleChange}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientMiddleNameInput,
                          )}
                        />
                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}
                        sx={{mb: isErrorTall && !errors.birthDate ? 12 : 0}}
                      >
                        <DateControl
                          ref={errorRef}
                          errorTextStyles={sx.errorText}
                          labelTop
                          placeholder="mm/dd/yyyy"
                          disabledFuture
                          name="birthDate"
                          value={dateToFormat('P', values.birthDate) || ''}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={t('DATE_OF_BIRTH')}
                          error={getBirthDateError()}
                          dataTestId={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientBirthDate,
                          )}
                        />
                      </Grid>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <FormSelectControl<keyof typeof BIRTH_SEX_TYPE_MAP>
                          placeholder={t('SELECT')}
                          name="birthSex"
                          label={t('BIRTH_SEX')}
                          renderValue={(v) => (v ? tEnums(BIRTH_SEX_TYPE_MAP[v]) : '')}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientBirthSexSelect,
                          )}
                        >
                          {birthSexSelectOptions.map(({name, value}, key) => (
                            <MenuItem
                              key={key}
                              value={value}
                              data-test-id={testId(
                                CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientBirthSexSelectDropdownItem,
                              )}
                            >
                              {tEnums(name)}
                            </MenuItem>
                          ))}
                        </FormSelectControl>
                      </Grid>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <FormSelectControl<keyof typeof GENDER_TYPE_MAP>
                          optional
                          placeholder={t('SELECT')}
                          name="gender"
                          label={t('GENDER')}
                          renderValue={(v) => (v ? tEnums(GENDER_TYPE_MAP[v]) : '')}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGenderSelect,
                          )}
                        >
                          {genderOptions.map(({name, value}, key) => (
                            <MenuItem
                              key={key}
                              value={value}
                              data-test-id={testId(
                                CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGenderSelectDropdownItem,
                              )}
                            >
                              {tEnums(name)}
                            </MenuItem>
                          ))}
                        </FormSelectControl>
                      </Grid>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <FormSelectControl<keyof typeof RACE_TYPE_MAP>
                          optional
                          placeholder={t('SELECT')}
                          name="race"
                          label={t('RACE')}
                          renderValue={(v) => (v ? tEnums(RACE_TYPE_MAP[v]) : '')}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientRaceSelect,
                          )}
                        >
                          {raceOptions.map(({name, value}, key) => (
                            <MenuItem
                              key={key}
                              value={value}
                              data-test-id={testId(
                                CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientRaceSelectDropdownItem,
                              )}
                            >
                              {tEnums(name)}
                            </MenuItem>
                          ))}
                        </FormSelectControl>
                      </Grid>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <FormSelectControl<keyof typeof ETHNICITY_TYPE_MAP>
                          optional
                          placeholder={t('SELECT')}
                          name="ethnicity"
                          label={t('ETHNICITY')}
                          renderValue={(v) => (v ? tEnums(ETHNICITY_TYPE_MAP[v]) : '')}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientEthnicitySelect,
                          )}
                        >
                          {ethnicityOptions.map(({name, value}, key) => (
                            <MenuItem
                              key={key}
                              value={value}
                              data-test-id={testId(
                                CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientEthnicitySelectDropdownItem,
                              )}
                            >
                              {tEnums(name)}
                            </MenuItem>
                          ))}
                        </FormSelectControl>
                      </Grid>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <FormSelectControl<keyof typeof SEXYAL_ORIENTATION_TYPE_MAP>
                          optional
                          placeholder={t('SELECT')}
                          name="sexualOrientation"
                          label={t('SEXUAL_ORIENTATION')}
                          renderValue={(v) => (v ? tEnums(SEXYAL_ORIENTATION_TYPE_MAP[v]) : '')}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientSexualOrientationSelect,
                          )}
                        >
                          {sexualOrientationOptions.map(({name, value}, key) => (
                            <MenuItem
                              key={key}
                              value={value}
                              data-test-id={testId(
                                CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientSexualOrientationSelectDropdownItem,
                              )}
                            >
                              {tEnums(name)}
                            </MenuItem>
                          ))}
                        </FormSelectControl>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack sx={sx.contactsContainer}>
                    <Typography sx={sx.contacts} variant="18_24_500">
                      {t('CONTACTS')}
                    </Typography>
                    <Grid container spacing={24} columnSpacing={{xs: 18, sm: 36, lg: 48}}>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <PhoneInputControl
                          name="phone"
                          optional={!checkIsPeroson18OrOlder(values?.birthDate)}
                          label={t('MOBILE_PHONE')}
                          placeholder={t('ENTER_A_PHONE')}
                          value={values.phone || ''}
                          error={errors.phone ?? undefined}
                          onChange={handleChange}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientPhoneInput,
                          )}
                        />
                      </Grid>
                      <Grid xl={4} sm={colsNumberSm} xs={12}>
                        <InputControl
                          optional
                          name="email"
                          label={t('EMAIL')}
                          placeholder={t('ENTER_AN_EMAIL_ADDRESS')}
                          value={values.email || ''}
                          error={(duplicateEmailError || errors.email) ?? ''}
                          onChange={(e) => handleEmailChange(e)}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientEmailInput,
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack gap={isMobile ? 24 : 0}>
                    <Box sx={sx.boxContainer}>
                      <Typography variant="18_24_500">{t('ADDRESS')}</Typography>
                      <Typography sx={sx.optionalText} variant="12_16_500">
                        {t('OPTIONAL')}
                      </Typography>
                    </Box>
                    <Grid container spacing={24} columnSpacing={{xs: 18, sm: 36, lg: 48}}>
                      <Grid
                        xl={8}
                        xs={12}
                        display="grid"
                        sx={sx.addressesContainer}>
                        <GoogleAutocomplete
                          placeholder={t('ENTER_PATIENT_ADDRESS')}
                          value={value?.fullAddress}
                          label={t('ADDRESS')}
                          onChange={handleAutocomplete}
                          handleChangeAddress1={handleChangeAddress1}
                          setIsOptionSelected={setIsOptionSelected}
                          isOptionSelected={isOptionSelected}
                          error={errors.address1 || errors.streetNumber}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientAddressInput,
                          )}
                        />
                        <InputControl
                          maxlength={MAX_ADDRESS_LENGTH}
                          disabled={!values.address1}
                          name="address2"
                          label={t('ADDRESS_2')}
                          value={values.address2 || ''}
                          error={touched?.address2 && !values.address2 ? errors.address2 : ''}
                          onChange={handleChange}
                          data-test-id={testId(
                            CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientAddress2Input,
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack
                    sx={{
                      gap:
                        values.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My
                          ? gapForInsuranceBlockWith
                          : gapForInsuranceBlockWithout,
                    }}
                  >
                    <Stack gap={isMobile ? 18 : 24}>
                      <Stack sx={sx.primatyInsuranceContainer}>
                        <Typography variant="18_24_500">{t('PRIMARY_INSURANCE')}</Typography>
                        <Typography variant="12_16_500" sx={sx.optionalText}>
                          {t('OPTIONAL')}
                        </Typography>
                      </Stack>
                      {!!accountProfileValidInsurance && (
                        <RadioButtons
                          gapNumber={isMobile ? 12 : ''}
                          stylesSx={sx.radioBth}
                          height={isMobile ? 'initial' : ''}
                          inColumn={isMobile}
                          name="whichPrimaryInsuranceUse"
                          error={
                            touched.whichPrimaryInsuranceUse && errors.whichPrimaryInsuranceUse
                          }
                          value={values.whichPrimaryInsuranceUse}
                          handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChangeRadioBth(e, setFieldValue);
                            handleChange(e);
                          }}
                          onTouched={handleBlur}
                          firstButtonData={{
                            value: EWhichPrimaryInsuranceUse.My,
                            label: t('USE_MY_INSURANCE_POLICY'),
                            dataTestId: testId(
                              CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientMyInsuranceButton,
                            ),
                          }}
                          secondaryButtonData={{
                            value: EWhichPrimaryInsuranceUse.Patient,
                            label: t('USE_PATIENTS_INSURANCE_POLICY'),
                            dataTestId: testId(
                              CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientPatientInsuranceButton,
                            ),
                          }}
                        />
                      )}
                    </Stack>
                    {values.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My && (
                      <Stack
                        sx={[
                          ...spreadSx(sx.content),
                          {width: {lg: isSidebarOpen ? 706 : 844, xl: 844}},
                        ]}
                      >
                        <Stack
                          sx={[
                            ...spreadSx(sx.insuranceWrapper),
                            {
                              maxWidth: {
                                sm: 'calc(50% - 18px)',
                                lg: isSidebarOpen ? 335 : 404,
                                xl: 404,
                              },
                            },
                          ]}
                        >
                          <ListWrapper padding="12px" noLast={{xs: 0}}>
                            <RowInformation
                              name={t('CARRIER')}
                              value={accountProfileValidInsurance?.insurancePlan?.carrier?.name}
                              noMargin
                              tooltip
                            />
                            <RowInformation
                              name={t('PLAN')}
                              value={accountProfileValidInsurance?.insurancePlan?.name}
                              noMargin
                              tooltip
                            />
                            <RowInformation
                              name={t('POLICY_ID')}
                              value={accountProfileValidInsurance?.number}
                              noMargin
                              tooltip
                            />
                            <RowInformation
                              name={t('END_DATE')}
                              value={dateFormatted(accountProfileValidInsurance?.endDate)}
                              noMargin
                              tooltip
                            />
                          </ListWrapper>
                        </Stack>
                        <Stack sx={sx.guarantorWrapper}>
                          <Typography variant="18_24_500">{t('GUARANTOR')}</Typography>
                          <Stack sx={sx.guarantorContentWrapper}>
                            <Stack
                              sx={[
                                ...spreadSx(sx.guarantorListContent),
                                {
                                  maxWidth: {
                                    sm: 'calc(50% - 18px)',
                                    lg: isSidebarOpen ? 335 : 404,
                                    xl: 404,
                                  },
                                },
                              ]}
                            >
                              <ListWrapper padding="12px" noLast={{xs: 0}}>
                                <RowInformation
                                  tooltip
                                  value={t(accountProfileValidInsurance?.guarantor?.relationType)}
                                  name={t('RELATIONSHIP')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.firstName}
                                  name={t('FIRST_NAME')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.lastName}
                                  name={t('LAST_NAME')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.middleName}
                                  name={t('MIDDLE_NAME')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={dateFormatted(
                                    accountProfileValidInsurance?.guarantor?.birthDate ?? NO_VALUE,
                                  )}
                                  name={t('DATE_OF_BIRTH')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={t(accountProfileValidInsurance?.guarantor?.birthSex)}
                                  name={t('BIRTH_SEX')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.ssnTail}
                                  name={t('SSN_LAST_4')}
                                  noMargin
                                />
                              </ListWrapper>
                            </Stack>
                            <Stack
                              sx={[
                                ...spreadSx(sx.guarantorListContent),
                                {
                                  maxWidth: {
                                    sm: 'calc(50% - 18px)',
                                    lg: isSidebarOpen ? 335 : 404,
                                    xl: 404,
                                  },
                                },
                              ]}
                            >
                              <ListWrapper padding="12px" noLast={{xs: 0}}>
                                <RowInformation
                                  tooltip
                                  value={
                                    accountProfileValidInsurance?.guarantor?.state
                                      ? tEnums(
                                        STATES_TYPE_MAP[
                                          accountProfileValidInsurance?.guarantor?.state
                                        ],
                                      )
                                      : ''
                                  }
                                  name={t('STATE')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.city}
                                  name={t('CITY')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.main}
                                  name={t('ADDRESS_1')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.additional}
                                  name={t('ADDRESS_2')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.postalCode}
                                  name={t('ZIP_CODE')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={formatPhoneNumber(
                                    accountProfileValidInsurance?.guarantor?.phone,
                                  )}
                                  name={t('PHONE')}
                                  noMargin
                                />
                              </ListWrapper>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                    {values.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && (
                      <Stack
                        sx={[
                          ...spreadSx(sx.mainContainer),
                          {gap: {xs: 42, sm: 48, lg: 42}, p: {xs: 0}},
                        ]}
                      >
                        <Stack gap={24}>
                          <Grid container spacing={24} columnSpacing={{xs: 18, sm: 36, lg: 48}}>
                            <Grid xl={4} sm={colsNumberSm} xs={12}>
                              <ComboBoxInput
                                value={values.insuranceCarrier}
                                label={t('INSURANCE_CARRIER')}
                                options={insuranceCarriers || []}
                                debouncedChangeHandler={debouncedPrimaryChangeHandler}
                                setSelectedCarrier={(value: string) =>
                                  setCarrier(value, setFieldValue)
                                }
                                error={
                                  touched?.insuranceCarrier && !values.insuranceCarrier
                                    ? errors.insuranceCarrier
                                    : ''
                                }
                                clearInputValue={isInsurancePlansEmpty}
                                testIdFeatureGroup={
                                  CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientInsuranceCarrier
                                }
                              />
                            </Grid>
                            <Grid xl={4} sm={colsNumberSm} xs={12}>
                              <FormSelectControl
                                sx={sx.plan}
                                placeholder={t('SELECT')}
                                disabled={!insurancePlans?.length}
                                name="insurancePlan"
                                value={t(values.insurancePlan) || ''}
                                error={
                                  touched?.insurancePlan && !values.insurancePlan
                                    ? errors.insurancePlan
                                    : ''
                                }
                                onChange={handleChange}
                                label={t('INSURANCE_PLAN')}
                                data-test-id={testId(
                                  CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientInsurancePlanSelect,
                                )}
                              >
                                {insurancePlans?.map((option) => (
                                  <MenuItem
                                    key={option.name}
                                    value={option.name}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientInsurancePlanSelectDropdownItem,
                                    )}
                                  >
                                    {t(option.name as TKeys<'common'>)}
                                  </MenuItem>
                                ))}
                              </FormSelectControl>
                            </Grid>
                            <Grid xl={4} sm={colsNumberSm} xs={12}>
                              <InputControl
                                maxlength={MAX_POLICY_ID_LENGTH}
                                name="policyId"
                                label={t('POLICY_ID')}
                                value={t(values.policyId) || ''}
                                error={touched?.policyId && !values.policyId ? errors.policyId : ''}
                                onChange={handleChange}
                                data-test-id={testId(
                                  CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientPolicyIdInput,
                                )}
                              />
                            </Grid>
                            <Grid xl={4} sm={colsNumberSm} xs={12}>
                              <DateControl
                                labelTop
                                placeholder="mm/dd/yyyy"
                                reverseYears
                                disabledPast
                                onlyFutureYears
                                disableCurrentDay
                                name="endDate"
                                helperText={t('OPTIONAL')}
                                value={dateToFormat('P', values.endDate) || ''}
                                onChange={handleChange}
                                onTouched={setFieldTouched}
                                label={t('END_DATE')}
                                error={touched.endDate ? errors.endDate : ''}
                                optional
                                dataTestId={testId(
                                  CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientEndDate,
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                        <Stack sx={sx.guarantorContainer}>
                          <Stack sx={sx.flexContainer}>
                            <Box sx={sx.guarantorTitleContainer}>
                              <Typography component="h4" variant="18_24_500">
                                {t('GUARANTOR')}
                              </Typography>
                            </Box>
                            <Grid container spacing={24} columnSpacing={{xs: 18, sm: 36, lg: 48}}>
                              <Grid xl={4} sm={colsNumberSm} xs={12}>
                                <FormSelectControl<keyof typeof RELATIONSHIP_TYPE_MAP>
                                  optional
                                  placeholder={t('SELECT')}
                                  name="guarantorRelationshipType"
                                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    handleChangeRelationType(
                                      e,
                                      setFieldValue,
                                      handleSetGuarantorFields,
                                    );
                                  }}
                                  label={t('RELATIONSHIP')}
                                  renderValue={(v) => (v ? tEnums(RELATIONSHIP_TYPE_MAP[v]) : '')}
                                  data-test-id={testId(
                                    CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorRelationshipTypeSelect,
                                  )}
                                >
                                  {availableOptions.map(({name, value}, key) => (
                                    <MenuItem
                                      key={key}
                                      value={value}
                                      data-test-id={testId(
                                        CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorRelationshipTypeSelectDropdownItem,
                                      )}
                                    >
                                      {tEnums(name)}
                                    </MenuItem>
                                  ))}
                                </FormSelectControl>
                              </Grid>
                            </Grid>
                          </Stack>
                          <Stack gap={24}>
                            <Stack sx={sx.content} flexGrow={1}>
                              <Grid
                                container
                                spacing={24}
                                columnSpacing={{xs: 18, sm: 36, lg: 48}}
                              >
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <InputControl
                                    maxlength={MAX_INPUT_LENGTH}
                                    name="guarantorFirstName"
                                    label={t('FIRST_NAME')}
                                    value={values.guarantorFirstName || ''}
                                    error={
                                      touched?.guarantorFirstName ? errors.guarantorFirstName : ''
                                    }
                                    onChange={handleChange}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorFirstNameInput,
                                    )}
                                  />
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <InputControl
                                    maxlength={MAX_INPUT_LENGTH}
                                    name="guarantorLastName"
                                    label={t('LAST_NAME')}
                                    value={values.guarantorLastName || ''}
                                    error={
                                      touched?.guarantorLastName ? errors.guarantorLastName : ''
                                    }
                                    onChange={handleChange}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorLastNameInput,
                                    )}
                                  />
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <InputControl
                                    maxlength={MAX_INPUT_LENGTH}
                                    optional
                                    name="guarantorMiddleName"
                                    label={t('MIDDLE_NAME')}
                                    value={values.guarantorMiddleName || ''}
                                    error={
                                      touched?.guarantorMiddleName ? errors.guarantorMiddleName : ''
                                    }
                                    onChange={handleChange}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorMiddleNameInput,
                                    )}
                                  />
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <DateControl
                                    labelTop
                                    placeholder="mm/dd/yyyy"
                                    name="guarantorBirthDate"
                                    disabledFuture
                                    value={dateToFormat('P', values.guarantorBirthDate) || ''}
                                    onChange={handleChange}
                                    onTouched={setFieldTouched}
                                    label={t('DATE_OF_BIRTH')}
                                    error={
                                      touched?.guarantorBirthDate && errors?.guarantorBirthDate
                                        ? errors.guarantorBirthDate
                                        : ''
                                    }
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorBirthDate,
                                    )}
                                  />
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <FormSelectControl<keyof typeof BIRTH_SEX_TYPE_MAP>
                                    placeholder={t('SELECT')}
                                    name="guarantorBirthSex"
                                    label={t('BIRTH_SEX')}
                                    renderValue={(v) => (v ? tEnums(BIRTH_SEX_TYPE_MAP[v]) : '')}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorBirthSexSelect,
                                    )}
                                  >
                                    {birthSexSelectOptions.map(({name, value}, key) => (
                                      <MenuItem
                                        key={key}
                                        value={value}
                                        data-test-id={testId(
                                          CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorBirthSexSelectDropdownItem,
                                        )}
                                      >
                                        {tEnums(name)}
                                      </MenuItem>
                                    ))}
                                  </FormSelectControl>
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <InputControl
                                    type="number"
                                    maxlength={MAX_SSN_LENGTH}
                                    optional
                                    name="guarantorSsnTail"
                                    label={t('SSN_LAST_4')}
                                    error={touched?.guarantorSsnTail ? errors.guarantorSsnTail : ''}
                                    value={values.guarantorSsnTail || ''}
                                    onChange={handleChange}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorSsnTailInput,
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Stack>
                            <Stack sx={sx.content} flexGrow={1}>
                              <Grid
                                container
                                spacing={24}
                                columnSpacing={{xs: 18, sm: 36, lg: 48}}
                              >
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <FormSelectControl<keyof typeof STATES_TYPE_MAP>
                                    placeholder={t('SELECT')}
                                    name="guarantorState"
                                    label={t('STATE')}
                                    renderValue={(v) => (v ? tEnums(STATES_TYPE_MAP[v]) : '')}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorStateSelect,
                                    )}
                                  >
                                    {statesSelectOptions.map(({name, value}, key) => (
                                      <MenuItem
                                        key={key}
                                        value={value}
                                        data-test-id={testId(
                                          CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorStateSelectDropdownItem,
                                        )}
                                      >
                                        {tEnums(name)}
                                      </MenuItem>
                                    ))}
                                  </FormSelectControl>
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <InputControl
                                    maxlength={MAX_CITY_LENGTH}
                                    name="guarantorCity"
                                    label={t('CITY')}
                                    value={values.guarantorCity || ''}
                                    error={touched?.guarantorCity ? errors.guarantorCity : ''}
                                    onChange={handleChange}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorCityInput,
                                    )}
                                  />
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <InputControl
                                    name="guarantorPostalCode"
                                    label={t('ZIP_CODE')}
                                    value={values.guarantorPostalCode || ''}
                                    error={
                                      touched?.guarantorPostalCode ? errors.guarantorPostalCode : ''
                                    }
                                    onChange={handleChangeZipCode}
                                    maxlength={POSTAL_CODE_LENGTH}
                                    simpleTextInput
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorZipCodeInput,
                                    )}
                                  />
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <InputControl
                                    maxlength={MAX_ADDRESS_LENGTH}
                                    name="guarantorAddress1"
                                    label={t('ADDRESS_1')}
                                    error={
                                      touched?.guarantorAddress1 ? errors.guarantorAddress1 : ''
                                    }
                                    value={values.guarantorAddress1 || ''}
                                    onChange={handleChange}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorAddress1Input,
                                    )}
                                  />
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <InputControl
                                    maxlength={MAX_ADDRESS_LENGTH}
                                    optional
                                    name="guarantorAddress2"
                                    label={t('ADDRESS_2')}
                                    error={
                                      touched?.guarantorAddress2 ? errors.guarantorAddress2 : ''
                                    }
                                    value={values.guarantorAddress2 || ''}
                                    onChange={handleChange}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorAddress2Input,
                                    )}
                                  />
                                </Grid>
                                <Grid xl={4} sm={colsNumberSm} xs={12}>
                                  <PhoneInputControl
                                    optional
                                    name="guarantorPhone"
                                    label={t('PHONE')}
                                    error={errors.guarantorPhone ?? undefined}
                                    placeholder={t('ENTER_A_PHONE')}
                                    value={values.guarantorPhone || ''}
                                    onChange={handleChange}
                                    sx={sx.phoneInput}
                                    data-test-id={testId(
                                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientGuarantorPhoneInput,
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                <Stack sx={sx.buttonsWrapper}>
                  <Button
                    sx={sx.button}
                    variant="outlined"
                    color="primary"
                    disabled={step === 'loading'}
                    onClick={() =>
                      navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION, {
                        state: {toAddRelationPage: true},
                      })
                    }
                    data-test-id={testId(
                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientCancelButton,
                    )}
                  >
                    {t('CANCEL')}
                  </Button>
                  <Button
                    sx={sx.button}
                    variant="contained"
                    color="primary"
                    disabled={step === 'loading' || !initialValue || isEqual(initialValue, values)}
                    onClick={() => {
                      setTouched(touchedData);
                      setRelationType(values.relationShipType || null);
                      handleSubmit();
                    }}
                    data-test-id={testId(
                      CREATE_NEW_PATIENT_DATA_TEST_ID.createNewPatientSaveButton,
                    )}
                  >
                    {t('SAVE')}
                  </Button>
                </Stack>
              </WBox>
              <ParentRoleModal />
              {failureCreatedContact !== null && (
                <FailureModal
                  failureCreatedContact={failureCreatedContact}
                  relationType={relationType}
                  setTouched={setTouched}
                  handleCreateRelationContact={handleCreateRelationContact}
                />
              )}
              <SendRequestSuccessModal isFullWidthBth={false} />
            </StyledForm>
          );
        }}
      </Formik>
      {successCreatedContact !== null && (
        <SuccessModal
          fullName={getFullName({
            firstName: successCreatedContact?.['firstName'],
            lastName: successCreatedContact?.['lastName'],
            middleName: successCreatedContact?.['middleName'],
          })}
          email={successCreatedContact?.['email']}
          phone={successCreatedContact?.['phone']}
          successType={successCreatedContact?.['type']}
        />
      )}
      <ErrorModal
        open={isErrorModalOpen || showInsuranceErrorModal}
        close={handleCloseErrorModal}
        isInsurancePlansEmpty={isInsurancePlansEmpty}
      />
    </>
  );
};
