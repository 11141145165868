import {Dialog, DialogActions, DialogContent, DialogTitle} from '@components';
import {Box, Stack} from '@mui/material';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {FILTERS_DIALOG_DATA_TEST_ID} from '@src/components/fragments/FiltersDialog/testIds';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {FC, PropsWithChildren} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {IFiltersDialogProps} from './types';

export const FiltersDialog: FC<PropsWithChildren<IFiltersDialogProps>> = ({
  title,
  isOpen,
  onClose,
  onClear,
  onApply,
  children,
  testIdFeatureGroup = '',
}) => {
  const {t} = useTranslate('common');
  const {mobile} = useMQuery();

  const renderButtons = () => {
    return (
      <Stack sx={sx.buttonsContainer}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={onApply}
          data-test-id={testId(
            testIdFeatureGroup + FILTERS_DIALOG_DATA_TEST_ID.filtersDialogApplyButton,
          )}
        >
          {t('APPLY')}
        </Button>
        {onClear && (
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={onClear}
            data-test-id={testId(
              testIdFeatureGroup + FILTERS_DIALOG_DATA_TEST_ID.filtersDialogClearButton,
            )}
          >
            {t('CLEAR_ALL')}
          </Button>
        )}
      </Stack>
    );
  };

  if (mobile) {
    return (
      <MobileInteractionView
        isOpen={isOpen}
        title={title}
        onBack={onClose}
        onClose={onClose}
        testIdFeatureGroup={testIdFeatureGroup}
      >
        <Box sx={sx.mobileContent}>{children}</Box>
        {renderButtons()}
      </MobileInteractionView>
    );
  }

  return (
    <Dialog open={isOpen} size={'lg'} border={'0px solid'}>
      <DialogTitle
        onClose={onClose}
        closeButtonDataTestId={testId(
          testIdFeatureGroup + FILTERS_DIALOG_DATA_TEST_ID.filtersDialogTitleCloseButton,
        )}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={sx.content}>{children}</DialogContent>
      <DialogActions sx={sx.actionsContainer}>
        <Button
          onClick={onClose}
          variant={onClear ? 'text' : 'outlined'}
          color={'secondary'}
          sx={sx.cancelButton}
          data-test-id={testId(
            testIdFeatureGroup + FILTERS_DIALOG_DATA_TEST_ID.filtersDialogFooterCancelButton,
          )}
        >
          {t('CANCEL')}
        </Button>
        {renderButtons()}
      </DialogActions>
    </Dialog>
  );
};
