export const envs = {
  get BOOKING_WEB_URL (): string {
    return (
      window.env?.BOOKING_WEB_URL ??
      process.env.BOOKING_WEB_URL ??
      ''
    );
  },
  get PASSPORT_WEB_URL (): string {
    return (
      window.env?.PASSPORT_WEB_URL ??
      process.env.PASSPORT_WEB_URL ??
      ''
    );
  },
  get PASSPORT_IDENTITY_URL (): string {
    return (
      window.env?.PASSPORT_IDENTITY_URL ??
      process.env.PASSPORT_IDENTITY_URL ??
      ''
    );
  },
  get PATIENT_USER_GUIDE_URL (): string {
    return (
      window.env?.PATIENT_USER_GUIDE_URL ??
      process.env.PATIENT_USER_GUIDE_URL ??
      ''
    );
  },
  get PATIENT_CONTACT_SUPPORT_URL (): string {
    return (
      window.env?.PATIENT_CONTACT_SUPPORT_URL ??
      process.env.PATIENT_CONTACT_SUPPORT_URL ??
      ''
    );
  },
  get BASE_API (): string {
    return (
      window.env?.BASE_API ??
      process.env.BASE_API ??
      ''
    );
  },
  get GOOGLE_TAG_MANAGER_ID (): string {
    return (
      window.env?.GOOGLE_TAG_MANAGER_ID ??
      process.env.GOOGLE_TAG_MANAGER_ID ??
      ''
    );
  },
  get GOOGLE_API_KEY (): string {
    return (
      window.env?.GOOGLE_API_KEY ??
      process.env.GOOGLE_API_KEY ??
      ''
    );
  },
  get GOOGLE_RECAPTCHA_KEY (): string {
    return (
      window.env?.GOOGLE_RECAPTCHA_KEY ??
      process.env.GOOGLE_RECAPTCHA_KEY ??
      ''
    );
  },
  get DISPLAY_DATA_TEST_ID (): boolean {
    return (
      window.env?.DISPLAY_DATA_TEST_ID === 'true' ||
      process.env.DISPLAY_DATA_TEST_ID === 'true' ||
      false
    );
  },
};
