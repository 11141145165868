export const EDIT_INSURANCE_DATA_TEST_ID = {
  editInsuranceEndDate: 'edit-insurance__end-date',
  editInsuranceRelationshipSelect: 'edit-insurance__relationship_select',
  editInsuranceRelationshipSelectDropdownItem: 'edit-insurance__relationship_select_dropdown_item',
  editInsuranceFirstNameInput: 'edit-insurance__first-name-input',
  editInsuranceLastNameInput: 'edit-insurance__last-name-input',
  editInsuranceMiddleNameInput: 'edit-insurance__middle-name-input',
  editInsuranceBirthDate: 'edit-insurance__birth-date',
  editInsuranceBirthSexSelect: 'edit-insurance__birth-sex_select',
  editInsuranceBirthSexSelectDropdownItem: 'edit-insurance__birth-sex_select_dropdown_item',
  editInsuranceSsnTailInput: 'edit-insurance__ssn-tail-input',
  editInsuranceStateSelect: 'edit-insurance__state_select',
  editInsuranceStateSelectDropdownItem: 'edit-insurance__state_select_dropdown_item',
  editInsuranceCityInput: 'edit-insurance__city-input',
  editInsuranceZipInput: 'edit-insurance__zip-input',
  editInsuranceAddress1Input: 'edit-insurance__address-1-input',
  editInsuranceAddress2Input: 'edit-insurance__address-2-input',
  editInsurancePhoneInput: 'edit-insurance__phone-input',
  editInsuranceFooterCancelButton: 'edit-insurance__footer_cancel-button',
  editInsuranceFooterSaveButton: 'edit-insurance__footer_cancel-button',
} as const;
