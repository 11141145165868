import {OptionValue} from 'ui-kit';

export interface IFilter {
  end: Date | null
  start: Date | null
  selectedTests: OptionValue[]
}

export enum TimePeriod {
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  SIX_MONTH = 'SIX_MONTH',
  ONE_YEAR = 'ONE_YEAR',
  CUSTOM = 'CUSTOM',
}
