export const CREATE_NEW_PATIENT_DATA_TEST_ID = {
  createNewPatientBreadcrumbsRelations: 'connections__create-new-patient_breadcrumbs_relations',
  createNewPatientBreadcrumbsAddRelation: 'connections__create-new-patient_breadcrumbs_add-relation',
  createNewPatientRelationshipTypeSelect: 'connections__create-new-patient_relationship-type-select',
  createNewPatientRelationshipTypeSelectDropdownItem: 'connections__create-new-patient_relationship-type-select_dropdown_item',
  createNewPatientFirstNameInput: 'connections__create-new-patient_first-name-input',
  createNewPatientLastNameInput: 'connections__create-new-patient_last-name-input',
  createNewPatientMiddleNameInput: 'connections__create-new-patient_middle-name-input',
  createNewPatientBirthDate: 'connections__create-new-patient_birth-date',
  createNewPatientBirthSexSelect: 'connections__create-new-patient_birth-sex-select',
  createNewPatientBirthSexSelectDropdownItem: 'connections__create-new-patient_birth-sex-select_dropdown_item',
  createNewPatientGenderSelect: 'connections__create-new-patient_gender-select',
  createNewPatientGenderSelectDropdownItem: 'connections__create-new-patient_gender-select_dropdown_item',
  createNewPatientRaceSelect: 'connections__create-new-patient_race-select',
  createNewPatientRaceSelectDropdownItem: 'connections__create-new-patient_race-select_dropdown_item',
  createNewPatientEthnicitySelect: 'connections__create-new-patient_ethnicity-select',
  createNewPatientEthnicitySelectDropdownItem: 'connections__create-new-patient_ethnicity-select_dropdown_item',
  createNewPatientSexualOrientationSelect: 'connections__create-new-patient_sexual-orientation-select',
  createNewPatientSexualOrientationSelectDropdownItem: 'connections__create-new-patient_sexual-orientation-select_dropdown_item',
  createNewPatientPhoneInput: 'connections__create-new-patient_phone-input',
  createNewPatientEmailInput: 'connections__create-new-patient_email-input',
  createNewPatientAddressInput: 'connections__create-new-patient_address-input',
  createNewPatientAddress2Input: 'connections__create-new-patient_address2-input',
  createNewPatientMyInsuranceButton: 'connections__create-new-patient_my-insurance-button',
  createNewPatientPatientInsuranceButton: 'connections__create-new-patient_patient-insurance-button',
  createNewPatientInsuranceCarrier: 'connections__create-new-patient_insurance-carrier',
  createNewPatientInsurancePlanSelect: 'connections__create-new-patient_insurance-plan-select',
  createNewPatientInsurancePlanSelectDropdownItem: 'connections__create-new-patient_insurance-plan-select_dropdown_item',
  createNewPatientPolicyIdInput: 'connections__create-new-patient_policy-id-input',
  createNewPatientEndDate: 'connections__create-new-patient_end-date',
  createNewPatientGuarantorRelationshipTypeSelect: 'connections__create-new-patient_guarantor-relationship-type-select',
  createNewPatientGuarantorRelationshipTypeSelectDropdownItem: 'connections__create-new-patient_guarantor-relationship-type-select_dropdown_item',
  createNewPatientGuarantorFirstNameInput: 'connections__create-new-patient_guarantor-first-name-input',
  createNewPatientGuarantorLastNameInput: 'connections__create-new-patient_guarantor-last-name-input',
  createNewPatientGuarantorMiddleNameInput: 'connections__create-new-patient_guarantor-middle-name-input',
  createNewPatientGuarantorBirthDate: 'connections__create-new-patient_guarantor-birth-date',
  createNewPatientGuarantorBirthSexSelect: 'connections__create-new-patient_guarantor-birth-sex-select',
  createNewPatientGuarantorBirthSexSelectDropdownItem: 'connections__create-new-patient_guarantor-birth-sex-select_dropdown_item',
  createNewPatientGuarantorSsnTailInput: 'connections__create-new-patient_guarantor-ssn-tail-input',
  createNewPatientGuarantorStateSelect: 'connections__create-new-patient_guarantor-state-select',
  createNewPatientGuarantorStateSelectDropdownItem: 'connections__create-new-patient_guarantor-state-select_dropdown_item',
  createNewPatientGuarantorCityInput: 'connections__create-new-patient_guarantor-city-input',
  createNewPatientGuarantorZipCodeInput: 'connections__create-new-patient_guarantor-zip-code-input',
  createNewPatientGuarantorAddress1Input: 'connections__create-new-patient_guarantor-address1-input',
  createNewPatientGuarantorAddress2Input: 'connections__create-new-patient_guarantor-address2-input',
  createNewPatientGuarantorPhoneInput: 'connections__create-new-patient_guarantor-phone-input',
  createNewPatientCancelButton: 'connections__create-new-patient_cancel-button',
  createNewPatientSaveButton: 'connections__create-new-patient_save-button',

} as const;
