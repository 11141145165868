import {Stack, Typography} from '@mui/material';
import {Result} from '@src/api';
import {useTranslate} from '@src/i18n/useTranslate';
import {HEALTH_CASE_DATA_TEST_ID} from '@src/pages/HealthCase/testIds';
import {DATE_FORMAT, dateToFormat} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {testId} from '@src/shared/utils/testId';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {Checkbox} from 'ui-kit';

import {MobileCardContainer, sx} from './styles';

export const CardResultsMobile = (result: Result) => {
  const {t, ready} = useTranslate('healthCases');
  const dispatch = useAppDispatch();
  const {selectedResults, initialResults, savedResults} = useAppSelector(
    (state) => state.healthCases,
  );
  const isDisabled = initialResults.has(result.id) || savedResults.has(result.id);
  const handleCheckboxChange = () => {
    if (!isDisabled) {
      dispatch(healthCasesActions.toggleResultsSelection(result.id));
    }
  };

  const isChecked =
    selectedResults.has(result.id) || initialResults.has(result.id) || savedResults.has(result.id);

  const firstName = result?.physician?.firstName;
  const lastName = result?.physician?.lastName;
  const middleName = result?.physician?.middleName;
  const healthCases =
    result.healthCases
      ?.map((item) => {
        return item.name;
      })
      .join(', ') || [];

  if (!ready) return null;

  return (
    <MobileCardContainer
      key={result.id}
      onClick={handleCheckboxChange}
      isSelected={isChecked}
      isDisabled={isDisabled}
      data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogListItem)}
    >
      <Stack>
        <Checkbox
          disabled={isDisabled}
          value={isChecked}
          sx={sx.checkbox}
          data-test-id={testId(HEALTH_CASE_DATA_TEST_ID.healthCaseResultsDialogListItemCheckbox)}
        />
      </Stack>
      <Stack gap={12}>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography color={'grey.400'} variant="14_18_500">
            {t('COLLECTED_ON', {date: dateToFormat(DATE_FORMAT, result.collected || '')})}
          </Typography>
        </Stack>
        <Typography variant="14_18_500" sx={sx.description}>
          {healthCases}
        </Typography>
        <Typography variant="14_18_600">{getName({firstName, lastName, middleName})}</Typography>
      </Stack>
    </MobileCardContainer>
  );
};
