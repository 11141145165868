import {Stack, Avatar, Typography, SxProps, Theme, Link} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {GeneralInfoProps, getInitialContact} from '@src/pages/Overview/fragments/GeneralInfo';
import {OVERVIEW_DATA_TEST_ID} from '@src/pages/Overview/testIds';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-icon.svg';
import {envs} from '@src/shared/constants/envs';
import {NO_VALUE, dateFormatted, getFullName, getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {testId} from '@src/shared/utils/testId';
import {alpha} from '@src/theme/utils';
import {FC} from 'react';
import {IconButtonNew} from 'ui-kit';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  avatar: (t) => ({
    width: 72,
    height: 72,
    backgroundColor: alpha(t.palette.secondary.main, 21),
    color: t.palette.common.white,
  }),
  container: {
    padding: {xs: 18, sm: '24px 24px 18px 24px'},
    width: {
      xs: '100%',
    },
  },
  contentContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  link: {
    alignSelf: 'flex-start',
  },
};

export const GeneralInfoMobile: FC<GeneralInfoProps> = ({contact = getInitialContact(), avatarUrl}) => {
  const {birthDate} = contact;

  return (
    <Stack sx={sx.container}>
      <Stack textAlign="start" width={'100%'}>
        <Stack sx={sx.contentContainer}>
          <Stack gap={18} width={'100%'}>
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
              <Avatar sx={sx.avatar} alt={getFullName(contact)} src={avatarUrl}>
                <Typography fontSize='24px' lineHeight='28px' fontWeight='600'>
                  {getUserInitials(contact)}
                </Typography>
              </Avatar>
              <Link
                data-test-id={testId(OVERVIEW_DATA_TEST_ID.overviewGeneralInfoEditButton)}
                sx={sx.link}
                target='_blank'
                href={`${envs.PASSPORT_WEB_URL}/profile`}>
                <IconButtonNew color={'tertiary'} size={'lg'}>
                  <EditIcon />
                </IconButtonNew>
              </Link>
            </Stack>
            <Stack>
              <EllipsisTextTooltip
                sx={{
                  width: {
                    xs: 320,
                    sm: 450,
                    md: 600,
                  },
                }}
                ellipsisText={getName(contact, {initialsOnlyMiddleName: true})}
                variant="24_32_700" />
              {birthDate && (
                <Typography variant="16_20_500">
                  {dateFormatted(birthDate ?? NO_VALUE)}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
