/* eslint-disable no-comments/disallowComments */
import {
  RejectedReason,
  ResultType,
  StorageBiomarker,
} from '@src/api/__generated__/webApi';
import {ISODate} from 'types';

import {HealthCase, HealthCaseData} from '../healthCases';

export interface LaboratoryResultDetail {
  id: string
  orderDetails: OrderDetails
  patient: Patient
  products: Product[]
  panels: Panel[]
  healthCases: HealthCaseData[]
  type: ResultType
}

export interface ManualResultDetail {
  /** @format date */
  date?: string
  /** @format uuid */
  id: string
  name?: string
  description?: string
  recognition: boolean
  storageBiomarkers?: StorageBiomarker[]
  hasFile: boolean
  type: ResultType
  healthCases?: HealthCaseData[]
}

export type ResultDetail = LaboratoryResultDetail;

export interface ResultTests {
  name: string
}

export interface Panel {
  code: string
  id: string
  name: string
  products?: Product[]
}

export interface Product {
  code: string
  id: string
  name: string
  tests: Test[]
}

export interface Patient {
  birthDate: string
  birthSex: BirthSex
  firstName: string
  lastName: string
  middleName: string
}

export interface OrderDetails {
  collected: ISODate
  number: number
  provider: OrderingProvider
  reported: ISODate
}

export interface Reference {
  isOutOfRef: boolean
  maxValue: number
  minValue: number
}

interface QualityReference {
  isOutOfRef: boolean
  value?: string
}

export enum TestStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export interface Test {
  code: string
  comment: string | null
  units: string | null
  criticalReference: Reference | null
  id: string
  loinc: string
  name: string
  qualityReference: QualityReference | null
  reference: Reference | null
  value: string | null
  status: TestStatus
  rejectedReasons?: RejectedReason[]
}

export interface OrderingProvider {
  firstName: string
  id: string
  lastName: string
  middleName: string
}

export interface Result {
  collected?: ISODate
  companyId?: string
  flag?: FlagType
  id: string
  healthCases?: HealthCase[]
  physician?: OrderingProvider
  reported?: ISODate
  type?: ResultType
  date?: ISODate
}

export enum DynamicResultType {
  All = 'ALL',
  Laboratory = 'LABORATORY',
  Manual = 'MANUAL',
}

export interface DynamicsResult {
  comment: string
  criticalReference: Reference
  dateTime: string
  qualityReference: QualityReference | null
  reference: Reference
  value?: string | null
  type?: DynamicResultType.Manual | DynamicResultType.Laboratory
  labTestCode?: string
  labTestName?: string
}

export interface DynamicsTest {
  code: string
  unit?: string
  name?: string
  results: DynamicsResult[]
}

export interface ResultsSearch {
  contentType: string
  data: string
  error: string
  fieldErrors: [
    {
      error: string
      field: string
    },
  ]
  expiredSeconds: number
  attemptsLeft: number
  message: string
}

enum BirthSex {
  Male = 'MALE',
  Female = 'FEMALE',
  Unknown = 'UNKNOWN',
}

export type FlagType = 'NORMAL' | 'ABNORMAL' | 'CRITICAL';
