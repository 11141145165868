import {Box, Stack, SxProps, Theme} from '@mui/material';
import {DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {ACCESSES_DATA_TEST_ID} from '@src/pages/Connections/fragments/Accesses/testIds';
import {modalSize, modalType} from '@src/pages/Connections/utils/functionHelpers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {testId} from '@src/shared/utils/testId';
import {FC} from 'react';
import {Button} from 'ui-kit';

import {StyledModal} from './styled';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: 24,
  },
  modalButton: {
    padding: '11px 50px',
  },
};

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const AccessesDeleteModal: FC<Props> = ({isOpen, onClose}) => {
  const {t} = useTranslate('accesses');
  const {mobile, tablet} = useMQuery();

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      size={modalSize(tablet, mobile)}
      data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesDeleteDialog)}
    >
      <DialogTitle
        onClose={onClose}
        noContent={false}
        closeButtonDataTestId={ACCESSES_DATA_TEST_ID.accessesDeleteDialogHeaderCloseButton}
      >
        {t('DELETE')}
      </DialogTitle>
      <Stack sx={{alignItems: 'center'}}>{modalType(tablet, mobile)}</Stack>
      <Box sx={sx.modalButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          sx={{...sx.modalButton, width: {xs: '100%', sm: 'unset'}}}
          onClick={onClose}
          data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesDeleteDialogFooterCloseButton)}
        >
          {t('OK')}
        </Button>
      </Box>
    </StyledModal>
  );
};
