import {forwardRef} from 'react';
import {IconButtonNew} from 'ui-kit';

import {StyledGlobalNotificationIcon} from './styled';
import {NotificationBtnProps} from './types';

export const GlobalNotificationBtn = forwardRef<HTMLButtonElement, NotificationBtnProps>(
  ({hasUnread = false, open, ...props}, ref) => {
    return (
      <IconButtonNew
        color={'quaternary'}
        size={'lg'}
        onClick={open}
        ref={ref}
        {...props}>
        <StyledGlobalNotificationIcon hasUnread={hasUnread} />
      </IconButtonNew>
    );
  },
);

GlobalNotificationBtn.displayName = 'NotificationBtn';
