import {MenuTypes, StorageFileCategory, storageApi} from '@api';
import {DatePickerRange} from '@components';
import {Box, SelectChangeEvent, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {DateRangeButton} from '@src/components/DateRangeButton';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {STORAGE_DATA_TEST_ID} from '@src/pages/Storage/testIds';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {STORAGE_CATEGORY_TYPE_MAP} from '@src/shared/constants/enumsMaps';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {INITIAL_DATES, INITIAL_FILTERS} from '@src/store/storage/constants';
import {storageActions} from '@src/store/storage/slice';
import {StorageFilters} from '@src/store/storage/types';
import {MenuItemState} from '@src/types';
import {useAppDispatch, useAppSelector} from '@store';
import {ChangeEvent, FC, MouseEvent, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {
  ActionsButton,
  ActionsRow,
  IconButton,
  MenuItem, OptionValue,
  SearchControl,
  SelectControl,
} from 'ui-kit';

import {categoryOptions, getMenuTypesOptions} from '../../helpers';
import {useStorageTranslate} from '../../hooks';
import {FiltersDialog} from '../FiltersDialog';

import {FiltersContainer, StyledAdaptiveFieldWrapper, sx} from './styles';
import {FiltersProps} from './types';

export const Filters: FC<FiltersProps> = ({isSidebarOpen, isScrolled}) => {
  const translate = useStorageTranslate();
  const {t: tEnums} = useTranslate('enums');
  const {mobile: isMobile, tabletPortrait: isTabletSm} = useMQuery();

  const [healthCasesOptions, setHealthCasesOptions] = useState<OptionValue[]>([]);
  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (value: string) => {
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('');
  };

  const filters = useAppSelector((state) => state.storage.files.filters);
  const dispatch = useAppDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {state} = useLocation() as { state: MenuItemState };

  const handleToggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleFetchHealthCases = async () => {
    try {
      const response = await storageApi.getStorageHealthCases();
      const hcOptions = response.map<OptionValue>(({name, id}) => ({value: id, label: name}));
      setHealthCasesOptions(hcOptions);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    void handleFetchHealthCases();
  }, []);

  const filteredCasesOptions = useMemo(() => {
    if (searchValueHealthCase) {
      return healthCasesOptions.filter((option) =>
        option.label.toLowerCase().includes(searchValueHealthCase.toLowerCase()),
      );
    }
    return healthCasesOptions;
  }, [healthCasesOptions, searchValueHealthCase]);

  const handleDispatchSetFilters = ({
    newState,
    updatedValue,
  }: {
    newState?: StorageFilters
    updatedValue?: { key: keyof StorageFilters, value: any }
  }) => {
    dispatch(storageActions.setFilters({newState, updatedValue}));
  };

  const handleChangeHealthCases = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const selectedHealthCases = healthCasesOptions.filter((option) => value.includes(option.value));
    handleDispatchSetFilters({newState: {...filters, cases: selectedHealthCases}});
  };

  const handleCasesCheckToggle = () => {
    if ((filters.cases?.length || 0) > healthCasesOptions.length / 2) {
      handleDispatchSetFilters({newState: {...filters, cases: []}});
    } else {
      handleDispatchSetFilters({newState: {...filters, cases: healthCasesOptions}});
    }
  };

  const handleChangeCategory = (event: SelectChangeEvent<StorageFileCategory>) => {
    const value = event.target.value as StorageFileCategory;
    handleDispatchSetFilters({newState: {...filters, category: value}});
  };

  const handleChangeDate = (name: keyof StorageFilters) => (date: Date | null) => {
    handleDispatchSetFilters({
      updatedValue: {
        key: name,
        value: date,
      },
    });
  };
  const handleClearDate = () => {
    handleDispatchSetFilters({newState: {...filters, ...INITIAL_DATES}});
  };
  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    handleDispatchSetFilters({
      updatedValue: {
        key: 'phrase',
        value,
      },
    });
  };
  const handleChangeMenu = (_: MouseEvent<HTMLElement>, value: MenuTypes) => {
    if (!value) return;
    handleDispatchSetFilters({updatedValue: {key: 'menu', value}});
  };

  const handleUpdateFilters = (newFilters: StorageFilters) => {
    handleDispatchSetFilters({newState: newFilters});
  };

  const handleClearAllFilters = () => {
    handleDispatchSetFilters({newState: INITIAL_FILTERS});
    dispatch(healthCasesActions.clearSelectedDocuments());
  };

  useEffect(() => {
    if (state?.needClear) {
      handleClearAllFilters();
    }
  }, [state?.needClear]);

  return (
    <>
      <FiltersContainer isScrolled={isScrolled}>
        <Box sx={sx.inputsContainer}>
          <StyledAdaptiveFieldWrapper downBreakpoint={'xs'} isSidebarOpen={isSidebarOpen}>
            <SearchControl
              placeholder={translate('SEARCH')}
              value={filters.phrase}
              onChange={handleChangeSearch}
              label={!isMobile && !isTabletSm && translate('SEARCH')}
              data-test-id={testId(STORAGE_DATA_TEST_ID.storageHeaderFiltersInput)}
            />
          </StyledAdaptiveFieldWrapper>
          <StyledAdaptiveFieldWrapper
            downBreakpoint={'xs'}
            upBreakpoint={'md'}
            isSidebarOpen={isSidebarOpen}
            sx={{minWidth: 'unset', flex: 'unset'}}
          >
            <IconButton
              color={'secondary-light'}
              onClick={handleToggleDialog}
              data-test-id={testId(STORAGE_DATA_TEST_ID.storageHeaderFilterOpenFiltersButton)}
            >
              <SettingsIcon />
            </IconButton>
          </StyledAdaptiveFieldWrapper>
          <StyledAdaptiveFieldWrapper downBreakpoint={'lg'} isSidebarOpen={isSidebarOpen}>
            <FormSelectControl<keyof typeof STORAGE_CATEGORY_TYPE_MAP>
              name="category"
              label={translate('CATEGORY')}
              placeholder={translate('SELECT')}
              value={filters.category}
              onChange={handleChangeCategory}
              renderValue={(v) => (v ? tEnums(STORAGE_CATEGORY_TYPE_MAP[v]) : '')}
              data-test-id={testId(STORAGE_DATA_TEST_ID.storageHeaderFiltersCategorySelect)}
            >
              {categoryOptions.map(({name, value}, key) => (
                <MenuItem
                  key={key}
                  value={value}
                  data-test-id={testId(
                    STORAGE_DATA_TEST_ID.storageHeaderFiltersCategorySelectDropdownItem,
                  )}
                >
                  {tEnums(name)}
                </MenuItem>
              ))}
            </FormSelectControl>
          </StyledAdaptiveFieldWrapper>

          <StyledAdaptiveFieldWrapper downBreakpoint={'xl'} isSidebarOpen={isSidebarOpen}>
            <SelectControl<string>
              data-test-id={testId(STORAGE_DATA_TEST_ID.storageFiltersHealthCaseSelect)}
              value={filters.cases}
              onChange={handleChangeHealthCases}
              multiple
              label={translate('HEALTH_CASES')}
              placeholder={translate('SELECT')}
              onSearch={handleChangeSearchValue}
              onClose={handleClearSearchValue}
              dropdownFooterRow={
                <ActionsRow>
                  <ActionsButton
                    data-test-id={testId(
                      STORAGE_DATA_TEST_ID.storageFiltersHealthCaseSelectDropdownCheckUncheckAll,
                    )}
                    onClick={handleCasesCheckToggle}
                    noClose={isMobile || isTabletSm}
                  >
                    {(filters.cases?.length || 0) > healthCasesOptions.length / 2 ? translate('UNCHECK_ALL') : translate('CHECK_ALL')}
                  </ActionsButton>
                </ActionsRow>
              }
            >
              {filteredCasesOptions.length
                ? (
                  filteredCasesOptions.map(({value, label}) => (
                    <MenuItem
                      key={value}
                      value={value}
                      data-test-id={testId(
                        STORAGE_DATA_TEST_ID.storageFiltersHealthCaseSelectDropdownItem,
                      )}
                    >
                      {label}
                    </MenuItem>
                  ))
                )
                : (
                  <ActionsRow>{translate('NO_SUITABLE_RESULTS')}</ActionsRow>
                )}
            </SelectControl>
          </StyledAdaptiveFieldWrapper>
          <StyledAdaptiveFieldWrapper
            downBreakpoint={'xl'}
            upBreakpoint={'xxl'}
            isSidebarOpen={isSidebarOpen}
            sx={{minWidth: 'unset', flex: 'unset'}}
          >
            <DateRangeButton
              startDate={filters.from}
              endDate={filters.to}
              setStartDate={handleChangeDate('from')}
              setEndDate={handleChangeDate('to')}
              clearDate={handleClearDate}
              startRangeWithCurrentMonth
              buttonDataTestId={STORAGE_DATA_TEST_ID.storageHeaderFiltersDateRangeButton}
            />
          </StyledAdaptiveFieldWrapper>
          <StyledAdaptiveFieldWrapper downBreakpoint={'xxl'} isSidebarOpen={isSidebarOpen}>
            <DatePickerRange
              sx={{mb: '0 !important'}}
              isIconVisible={false}
              variant="outlined"
              label={translate('DATE')}
              startDate={filters.from}
              endDate={filters.to}
              setStartDate={handleChangeDate('from')}
              setEndDate={handleChangeDate('to')}
              clearDate={handleClearDate}
              labelTop
              startRangeWithCurrentMonth
              dataTestId={STORAGE_DATA_TEST_ID.storageHeaderFiltersDateRange}
            />
          </StyledAdaptiveFieldWrapper>
        </Box>
        <ToggleButtonGroup
          color={'secondary'}
          size={'small'}
          value={filters.menu}
          exclusive
          onChange={handleChangeMenu}
          sx={{height: 42}}
          fullWidth={isMobile || isTabletSm}
        >
          {getMenuTypesOptions(translate).map((option) => (
            <ToggleButton
              sx={sx.toggleButton}
              key={option.value}
              value={option.value}
              data-test-id={testId(STORAGE_DATA_TEST_ID.storageHeaderFiltersTypeButton)}
            >
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <StyledAdaptiveFieldWrapper
          downBreakpoint={'md'}
          upBreakpoint={'xl'}
          isSidebarOpen={isSidebarOpen}
          sx={{minWidth: 'unset', flex: 'unset'}}
        >
          <IconButton
            color={'secondary-light'}
            onClick={handleToggleDialog}
            data-test-id={testId(STORAGE_DATA_TEST_ID.storageHeaderFilterOpenFiltersButton)}
          >
            <SettingsIcon />
          </IconButton>
        </StyledAdaptiveFieldWrapper>
        <StyledAdaptiveFieldWrapper
          downBreakpoint={'md'}
          isSidebarOpen={isSidebarOpen}
          sx={{minWidth: 'unset', flex: 'unset'}}
        >
          <IconButton
            color={'secondary-light'}
            onClick={handleClearAllFilters}
            data-test-id={testId(STORAGE_DATA_TEST_ID.storageHeaderFiltersClearButton)}
          >
            <RefreshIcon />
          </IconButton>
        </StyledAdaptiveFieldWrapper>
      </FiltersContainer>
      <FiltersDialog
        filters={filters}
        healthCasesOptions={healthCasesOptions}
        onUpdateFilters={handleUpdateFilters}
        onClose={handleToggleDialog}
        isOpen={isDialogOpen}
        isSidebarOpen={isSidebarOpen}
      />
    </>
  );
};
