export const HEADER_DATA_TEST_ID = {
  headerBurgerButton: 'header__burger-button',
  headerLogoButton: 'header__logo-button',
  headerNotificationButton: 'header__notification-button',
  headerNotificationsBackButton: 'header__notifications_back-button',
  headerNotificationsCloseButton: 'header__notifications_close-button',
  headerNotificationsListItem: 'header__notifications_list_item',
  headerAboutSystemDialog: 'header__about-system-dialog',
  headerAboutSystemHeaderCloseButton: 'header__about-system-dialog_header_close-button',
  headerProfileButton: 'header__profile-button',
  headerProfileMenu: 'header__profile-menu',
  headerProfileMenuLanguage: 'header__profile-menu_language',
  headerProfileMenuPassport: 'header__profile-menu_passport',
  headerProfileMenuAbout: 'header__profile-menu_about',
  headerProfileMenuLogout: 'header__profile-menu_logout',
  headerSupportMenuUserGuide: 'header__support-menu_user-guide',
  headerSupportMenuContactSupport: 'header__support-menu_contact-support',
  headerSupportButton: 'header__support-button',
  headerSupportMenu: 'header__support-menu',
} as const;
