import {Typography, Stack} from '@mui/material';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@src/components';
import {useTranslate} from '@src/i18n/useTranslate';
import {SEND_REQUEST_DIALOG_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/SendRequestSuccessModal/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as SuccessIcon} from '@src/shared/assets/icons/success.svg';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {useAppSelector} from '@src/store';
import {relationsActions} from '@src/store/relations/slice';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {ISendRequestSuccessModalProps} from './types';

export const SendRequestSuccessModal = ({
  isFullWidthBth = true,
}: ISendRequestSuccessModalProps) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();

  const step = useAppSelector((state) => state.relations.step);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onClose = () => {
    dispatch(relationsActions.setStep({step: 'init'}));
    navigate(ROUTERS_PATH.CONNECTIONS_RELATIONS);
  };

  const modalSize = () => {
    if (isMobile) return 'xs';
    return '482px';
  };

  return (
    <Dialog
      open={step === 'open'}
      onClose={onClose}
      keepMounted={false}
      size={modalSize()}
      data-test-id={testId(SEND_REQUEST_DIALOG_DATA_TEST_ID.sendRequestDialog)}
    >
      <DialogTitle
        width="56px"
        onClose={onClose}
        closeButtonDataTestId={SEND_REQUEST_DIALOG_DATA_TEST_ID.sendRequestDialogHeaderCloseButton}
      >
        <Stack sx={sx.titleWrapper}>
          <SuccessIcon />
          <Typography variant="18_24_700">{t('SUCCESS_WITH_EXCLAMATION_MARK')}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{padding: '36px 24px !important'}}>
        <Typography variant="14_18_500">{t('SUCCESSFULLY_CREATED_REQUEST')}</Typography>
      </DialogContent>
      <DialogActions sx={sx.dialogActions}>
        <Button
          variant="contained"
          fullWidth={isFullWidthBth}
          color="white"
          onClick={onClose}
          data-test-id={testId(SEND_REQUEST_DIALOG_DATA_TEST_ID.sendRequestDialogFooterOkButton)}
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
