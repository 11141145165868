import {Typography} from '@mui/material';
import {WBox} from '@src/components';
import {Dialog, DialogActions, DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {ERROR_DIALOG_DATA_TEST_ID} from '@src/pages/Storage/components/ErrorDialog/testIds';
import {ReactComponent as Error} from '@src/shared/assets/icons/error.svg';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {storageActions} from '@src/store/storage/slice';
import {useAppSelector} from '@store';
import {FC, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'ui-kit';

import {sx} from './styles';

interface ErrorDialogProps {
  testIdFeatureGroup: string
}

export const ErrorDialog: FC<ErrorDialogProps> = ({testIdFeatureGroup}) => {
  const {t, ready} = useTranslate('storage');
  const {mobile: isMobile} = useMQuery();
  const errorText = useAppSelector((state) => state.storage.errorText);
  const dispatch = useDispatch();

  const [dialogErrorText, setDialogErrorText] = useState('');

  useEffect(() => {
    if (errorText) {
      setDialogErrorText(errorText);
    }
  }, [errorText]);

  const closeModal = () => {
    dispatch(storageActions.setErrorText(''));
  };

  const modalSize = isMobile ? 'xs' : 'sm';

  if (!ready) return null;
  return (
    <Dialog
      sx={sx.dialogError}
      border="none"
      open={!!errorText}
      onClose={closeModal}
      size={modalSize}
      data-test-id={testId(testIdFeatureGroup + ERROR_DIALOG_DATA_TEST_ID.errorDialog)}
    >
      <DialogTitle
        width="64px"
        onClose={closeModal}
        noContent={false}
        sx={sx.dialogTitleError}
        closeButtonDataTestId={testId(ERROR_DIALOG_DATA_TEST_ID.errorDialogHeaderCloseButton)}
      >
        <Error />
        {t('ERROR_EXCLAMATION')}
      </DialogTitle>
      <WBox sx={sx.confirmDeleteContent}>
        <Typography variant="14_18_500">{dialogErrorText}</Typography>
      </WBox>
      <DialogActions sx={sx.dialogActionsError} onClose={closeModal}>
        <Button
          fullWidth={isMobile}
          sx={sx.bth}
          variant="contained"
          color="inherit"
          onClick={closeModal}
          data-test-id={testId(testIdFeatureGroup + ERROR_DIALOG_DATA_TEST_ID.errorDialogOkButton)}
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
