import {Box, Stack, Typography} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {ACCESSES_DATA_TEST_ID} from '@src/pages/Connections/fragments/Accesses/testIds';
import {ReactComponent as TrashIcon} from '@src/shared/assets/icons/trash.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {NO_VALUE} from '@src/shared/utils';
import {testId} from '@src/shared/utils/testId';
import {FC} from 'react';

import {DateRange} from '../common/DateRange';

import {sx} from './styles';
import {IAccessesCardProps} from './types';

export const AccessesCard: FC<IAccessesCardProps> = ({
  title,
  isExpired,
  access,
  beginDate,
  endDate,
  onOpenModal,
}) => {
  const {t} = useTranslate('accesses');
  const {mobile: isMobile} = useMQuery();

  const ExpiredComponent = (
    <Typography height={24} variant="14_18_500" sx={isExpired ? sx.expired : sx.active}>
      {isExpired ? t('EXPIRED') : t('ACTIVE')}
    </Typography>
  );

  return (
    <Stack sx={sx.contentContainer}>
      <Box sx={sx.content}>
        <Stack>
          {!isMobile && (
            <Stack sx={sx.flexContainer}>
              {ExpiredComponent}
              <Box
                onClick={() => onOpenModal(true)}
                sx={sx.delete}
                data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesListItemDeleteButton)}
              >
                <TrashIcon />
              </Box>
            </Stack>
          )}

          {isMobile && (
            <Stack>
              <Box sx={sx.titleBlock}>
                {ExpiredComponent}
                <Box
                  onClick={() => onOpenModal(true)}
                  sx={sx.delete}
                  data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesListItemDeleteButton)}
                >
                  <TrashIcon />
                </Box>
              </Box>
            </Stack>
          )}

          <Stack gap={18}>
            <Stack>
              <EllipsisTextTooltip
                ellipsisText={title}
                sx={sx.cardTitle}
                variant={isMobile ? '14_18_700' : '16_24_700'}
              />
            </Stack>
            <Stack>
              <Stack sx={sx.rowInfo}>
                <Typography variant={isMobile ? '12_16_500' : '14_18_500'} sx={sx.accessInfo}>
                  <b>{t('TYPE')}</b>
                  {t(access) || NO_VALUE}
                </Typography>
              </Stack>
              <DateRange beginDate={beginDate} endDate={endDate} spaceBetween={12} />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
