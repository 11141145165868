import {Theme} from '@mui/material';
import {SxProps} from '@mui/system';

export const stylesSx = {
  boxPaddings: {
    padding: {
      xs: '12px 24px 12px 120px',
      sm: '12px 24px 12px 136px',
      md: '12px 24px 12px 146px',
      lg: '24px 24px 24px 81px',
    },
  },
  checkboxPosition: {
    position: 'absolute',
    top: -2,
    left: -37,
  },
  boxContainer: {
    ml: {
      xs: 0,
      md: 33,
      lg: 0,
    },
  },
  dlPosition: {
    position: 'relative',
  },
  commentText: {
    whiteSpace: 'wrap',
    wordBreak: 'break-all',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '4',
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rejectedTooltip: {
    maxWidth: 330,
  },
  rejectedReasonsList: {
    m: 0,
  },
  infoIconButton: {
    display: 'inline-flex',
    color: 'inherit',
    height: 'unset',
  },
} satisfies Record<string, SxProps<Theme>>;
