// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {css as _, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {DatePickerRange} from '@src/components';
import {FormSelectControl} from '@src/components/FormSelectControl';
import Grid from '@src/components/Grid';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {RESULTS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/resultTestIds';
import {IFilterResults} from '@src/pages/Results/Results.constant';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {FC} from 'react';
import {MenuItem} from 'ui-kit';

import {getPhysiciansProps} from '../types';

interface ResultsFilterDialogProps {
  selectItem: string
  filter: IFilterResults
  filterOpened: boolean
  changeSelectPhysicians: (event: SelectChangeEvent<string>) => void
  getPhysicians: getPhysiciansProps[]
  changeDate: (value: string) => void
  onClearCollectionDate: VoidFunction
  onClear: VoidFunction
  onApply: VoidFunction
  onClose: VoidFunction
}

export const ResultsFilterDialog: FC<ResultsFilterDialogProps> = ({
  filterOpened,
  changeSelectPhysicians,
  getPhysicians,
  changeDate,
  onClearCollectionDate,
  onClear,
  onApply,
  filter,
  selectItem,
  onClose,
}) => {
  const {t} = useTranslate('results');
  const {desktop, lg} = useMQuery();

  const onMiddleSizeWidth = !lg && desktop;

  return (
    <FiltersDialogContainer
      isOpen={filterOpened}
      onClose={onClose}
      title={t('FILTERS')}
      onApply={onApply}
      onClear={onClear}
      testIdFeatureGroup={RESULTS_DATA_TEST_ID.resultsFiltersDialog}
    >
      <Stack gap={24}>
        <Grid xs={12} sm={6}>
          <FormSelectControl
            name={'physician'}
            placeholder={t('SELECT')}
            value={selectItem}
            onChange={changeSelectPhysicians}
            label={t('PHYSICIAN')}
            data-test-id={testId(RESULTS_DATA_TEST_ID.resultsFiltersDialogPhysicianSelect)}
          >
            {getPhysicians.map((option) => (
              <MenuItem
                key={option.name}
                value={option.name}
                data-test-id={testId(
                  RESULTS_DATA_TEST_ID.resultsFiltersDialogPhysicianSelectDropdownItem,
                )}
              >
                {option.name}
              </MenuItem>
            ))}
          </FormSelectControl>
        </Grid>
        <Grid xs={12} sm={6}>
          {!onMiddleSizeWidth && (
            <Typography variant="14_18_700">{t('COLLECTION_DATE_CALENDAR')}</Typography>
          )}
          <DatePickerRange
            label={onMiddleSizeWidth ? t('COLLECTION_DATE_CALENDAR') : ''}
            variant="outlined"
            startDate={filter.collectionDateFrom}
            setStartDate={changeDate('collectionDateFrom')}
            setEndDate={changeDate('collectionDateTo')}
            endDate={filter.collectionDateTo}
            sx={{backgroundColor: 'black'}}
            css={_`width: 100%; margin-bottom: 0 !important`}
            clearDate={onClearCollectionDate}
            isLabelOutside={false}
            isIconVisible={false}
            dataTestId={RESULTS_DATA_TEST_ID.resultsFiltersDialogCollectionDate}
          />
        </Grid>
      </Stack>
    </FiltersDialogContainer>
  );
};
