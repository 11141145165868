import {Typography, Stack, IconButton, Divider} from '@mui/material';
import {HealthcareInsurancePolicy} from '@src/api';
import {RowInformation} from '@src/components';
import {EmptyFragment} from '@src/components/EmptyFragment';
import {ListWrapper} from '@src/components/ListWrapper';
import {WBox} from '@src/components/WhiteBox';
import {InsuranceDeleteDialog} from '@src/features/InsuranceDeleteDialog';
import {StepDialog} from '@src/features/InsuranceDeleteDialog/types';
import {useTranslate} from '@src/i18n/useTranslate';
import {RELATIONS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-icon.svg';
import {ReactComponent as TrashIcon} from '@src/shared/assets/icons/trash.svg';
import {STATES_TYPE_MAP} from '@src/shared/constants/enumsMaps';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateFormatted} from '@src/shared/utils';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';
import {getRowInformationTypography} from '@src/shared/utils/getRowInformationTypography';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {testId} from '@src/shared/utils/testId';
import {useAppSelector} from '@src/store';
import {useContactRelations, useRelation, useRemoveInsurance} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {sortInsurances} from '../../helpers/functionHelpers';
import {Loader} from '../Loader';
import {canUserEdit as canUserEditData} from '../helpers';

import {calcWBoxPaddings} from './helpers';
import {AddIconPrimary, AddIconWhite, sx} from './styles';

export const RelationHealthPolicies = () => {
  const {t, ready} = useTranslate('connections');
  const {t: tEnums, ready: enumsReady} = useTranslate('enums');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();
  const {removeInsurance} = useRemoveInsurance();
  const {relationId} = useContactRelations(id);
  const {relation, fetching} = useRelation(relationId);
  const [deleteInsuranceId, setDeleteInsuranceId] = useState<string>('');
  const step = useAppSelector((state) => state.relations.insuranceStep);

  const isAccesses = !!relation?.accesses?.length;
  const sortedInsurance = sortInsurances(relation?.healthcareInsurancePolicies);
  const canUserEdit = canUserEditData(relation);
  const activeInsurance = sortedInsurance?.filter(
    (insurance) =>
      compareDatesWithToday(insurance.endDate, false, true) || insurance.endDate === null,
  );

  const minActiveInsuranceCount = (activeInsurance?.length ?? 0) < 2;

  const isShowMobileBtnCondition = isMobile && canUserEdit && minActiveInsuranceCount;

  const {rowInfoNameTypography, rowInfoValueTypography} = getRowInformationTypography(isMobile);

  const listWrapperPaddings = isMobile ? '6px' : '12px';
  const listWrapperSpaceBetween = isMobile ? '8px' : '12px';

  const showDivider = (
    insurance: HealthcareInsurancePolicy,
    sortedInsurance: HealthcareInsurancePolicy[] | null,
  ) => {
    return !isDesktop && insurance.primary && sortedInsurance && sortedInsurance?.length > 1;
  };

  const shouldShowAddInsuranceBtn = (sortedInsurance: HealthcareInsurancePolicy[]) => {
    return canUserEdit && minActiveInsuranceCount && sortedInsurance.length < 2;
  };

  const shouldShowAddInsuranceBtnWithDate = (insurance: HealthcareInsurancePolicy) => {
    return (
      canUserEdit &&
      !compareDatesWithToday(insurance.endDate, false, true) &&
      insurance.primary &&
      insurance.endDate
    );
  };

  const showAddInsuranceBtnWithDateDesk = (insurance: HealthcareInsurancePolicy) => {
    return shouldShowAddInsuranceBtnWithDate(insurance) && isDesktop;
  };

  const goToEditPage = (primary?: boolean) => {
    if (primary !== undefined && id) {
      sessionStorage.setItem('primary', JSON.stringify(primary));
      navigate(generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES_EDIT, {id}));
    }
  };

  const goToAddInsurancePage = (primary?: boolean) => {
    if (primary !== undefined && id) {
      navigate(generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES_ADD, {id}), {
        state: {primary},
      });
    }
  };

  const setId = (id?: string) => {
    if (id) {
      setDeleteInsuranceId(id);
      dispatch(relationsActions.setInsuranceStep({step: 'open'}));
    }
  };

  const onClose = () => {
    dispatch(relationsActions.setInsuranceStep({step: 'init'}));
  };

  const onRemove = () => {
    if (deleteInsuranceId && relationId) {
      removeInsurance({
        data: {
          deletedHealthcareInsurancePolicyIds: [deleteInsuranceId],
          healthcareInsurancePoliciesCreate: [],
          healthcareInsurancePoliciesEdit: [],
        },
        relationId,
      });
    }
  };

  if (fetching || !ready || !enumsReady) {
    return <Loader />;
  }

  if (!isAccesses) {
    return (
      <Stack sx={sx.mainContainer}>
        <Stack sx={sx.container}>
          <EmptyFragment
            title={t('INSURANCE')}
            description={t('YOU_DONT_HAVE_ACCESS_TO_THIS_DATA')}
            button={t('REQUEST_ACCESS')}
            isButtonIcon={false}
            noPaddings
            hasDescriptionIcon
            icon
            descriptionStyles={sx.emptyFragment}
            titleStyles={sx.titleEmptyFragment}
            boxContainerStyles={sx.boxContainerEmptyFragment}
            buttonDataTestId={testId(
              RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesRequestAccessButton,
            )}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Stack sx={[...spreadSx(sx.mainContainer)]}>
        {sortedInsurance?.length
          ? (
            <Stack sx={sx.contentWrapper}>
              {sortedInsurance
                ?.sort((a, b) => Number(b.primary) - Number(a.primary))
                .map((insurance) => (
                  <>
                    <WBox
                      key={insurance.id}
                      sx={[
                        ...spreadSx(sx.whiteBox),
                        {p: {xs: 0, lg: calcWBoxPaddings(sortedInsurance, insurance)}},
                      ]}
                    >
                      <Stack sx={sx.content}>
                        <Stack sx={sx.insuranceWrapper}>
                          <Stack sx={sx.flexContainer}>
                            <Typography sx={sx.insuranceMainTitle}>
                              {insurance.primary ? t('PRIMARY_INSURANCE') : t('SECONDARY_INSURANCE')}
                            </Typography>
                            {canUserEdit && (
                              <Stack sx={sx.iconBtnsWrapper}>
                                {insurance.primary &&
                              !activeInsurance?.find((insurance) => insurance.primary)
                                  ? null
                                  : (
                                    <IconButton
                                      sx={sx.icon}
                                      onClick={() => goToEditPage(insurance.primary)}
                                      data-test-id={testId(
                                        RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesListItemEditButton,
                                      )}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  )}
                                <IconButton
                                  sx={sx.icon}
                                  onClick={() => setId(insurance.id)}
                                  data-test-id={testId(
                                    RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesListItemDeleteButton,
                                  )}
                                >
                                  <TrashIcon />
                                </IconButton>
                              </Stack>
                            )}
                          </Stack>
                          <ListWrapper
                            spaceBetween={listWrapperSpaceBetween}
                            padding={listWrapperPaddings}
                            noLast={{xs: 0}}
                          >
                            <RowInformation
                              noWrap={!isDesktop}
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              name={t('INSURANCE_CARRIER')}
                              value={insurance.insurancePlan?.carrier?.name}
                              noMargin
                              tooltip={isDesktop}
                            />
                            <RowInformation
                              noWrap={!isDesktop}
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              name={t('INSURANCE_PLAN')}
                              value={insurance.insurancePlan?.name}
                              noMargin
                              tooltip={isDesktop}
                            />
                            <RowInformation
                              noWrap={!isDesktop}
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              tooltip={isDesktop}
                              name={t('POLICY_ID')}
                              value={insurance.number}
                              noMargin
                            />
                            <RowInformation
                              noWrap={!isDesktop}
                              variant={rowInfoNameTypography}
                              valueTypographyVariant={rowInfoValueTypography}
                              tooltip={isDesktop}
                              name={t('END_DATE')}
                              value={dateFormatted(insurance.endDate)}
                              noMargin
                            />
                          </ListWrapper>
                        </Stack>
                        <Stack sx={sx.guarantorWrapper}>
                          <Typography sx={sx.guarantorTitle}>{t('GUARANTOR')}</Typography>
                          <Stack sx={sx.guarantorContentWrapper}>
                            <Stack sx={sx.listWrapperContainer}>
                              <ListWrapper
                                padding={listWrapperPaddings}
                                noLast={{xs: 0}}
                                spaceBetween={listWrapperSpaceBetween}
                              >
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  tooltip={isDesktop}
                                  value={t(insurance.guarantor?.relationType)}
                                  name={t('RELATIONSHIP')}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  tooltip={isDesktop}
                                  value={insurance.guarantor?.firstName}
                                  name={t('FIRST_NAME')}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  tooltip={isDesktop}
                                  value={insurance.guarantor?.lastName}
                                  name={t('LAST_NAME')}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  tooltip={isDesktop}
                                  value={insurance.guarantor?.middleName}
                                  name={t('MIDDLE_NAME')}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  value={dateFormatted(insurance.guarantor?.birthDate)}
                                  name={t('DATE_OF_BIRTH')}
                                  tooltip={isDesktop}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  value={t(insurance.guarantor?.birthSex)}
                                  name={t('BIRTH_SEX')}
                                  tooltip={isDesktop}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  value={insurance.guarantor?.ssnTail}
                                  name={t('SSN_LAST_4')}
                                  tooltip={isDesktop}
                                  noMargin
                                />
                              </ListWrapper>
                            </Stack>
                            <Stack sx={sx.listWrapperContainer}>
                              <ListWrapper
                                padding={listWrapperPaddings}
                                spaceBetween={listWrapperSpaceBetween}
                                noLast={{xs: 0}}
                              >
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  value={
                                    insurance.guarantor?.state
                                      ? tEnums(STATES_TYPE_MAP[insurance.guarantor?.state])
                                      : ''
                                  }
                                  name={t('STATE')}
                                  tooltip={isDesktop}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  value={insurance.guarantor?.city}
                                  name={t('CITY')}
                                  tooltip={isDesktop}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  value={insurance.guarantor?.main}
                                  name={t('ADDRESS_1')}
                                  tooltip={isDesktop}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  value={insurance.guarantor?.additional}
                                  name={t('ADDRESS_2')}
                                  tooltip={isDesktop}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  value={insurance.guarantor?.postalCode}
                                  name={t('ZIP_CODE')}
                                  tooltip={isDesktop}
                                  noMargin
                                />
                                <RowInformation
                                  noWrap={!isDesktop}
                                  variant={rowInfoNameTypography}
                                  valueTypographyVariant={rowInfoValueTypography}
                                  value={formatPhoneNumber(insurance.guarantor?.phone)}
                                  name={t('PHONE')}
                                  tooltip={isDesktop}
                                  noMargin
                                />
                              </ListWrapper>
                            </Stack>
                          </Stack>
                        </Stack>
                        {shouldShowAddInsuranceBtn(sortedInsurance) && (
                          <Stack sx={sx.addInsuranceBtnContainer}>
                            <Button
                              fullWidth={isMobile}
                              startIcon={<AddIconWhite />}
                              variant="contained"
                              color="primary"
                              onClick={() => goToAddInsurancePage(insurance.primary)}
                              data-test-id={testId(
                                RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesAddInsuranceButton,
                              )}
                            >
                              {t('ADD_INSURANCE')}
                            </Button>
                          </Stack>
                        )}
                        {shouldShowAddInsuranceBtnWithDate(insurance) && !isDesktop && (
                          <Stack sx={sx.addInsuranceBtnContainer}>
                            <Button
                              fullWidth={isMobile}
                              startIcon={<AddIconWhite />}
                              variant="contained"
                              color="primary"
                              onClick={() => goToAddInsurancePage(insurance.primary)}
                              data-test-id={testId(
                                RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesAddInsuranceButton,
                              )}
                            >
                              {t('ADD_INSURANCE')}
                            </Button>
                          </Stack>
                        )}
                      </Stack>
                      {showDivider(insurance, sortedInsurance) && <Divider sx={sx.divider} />}
                    </WBox>
                    {showAddInsuranceBtnWithDateDesk(insurance) && (
                      <Stack sx={sx.addInsuranceBtnContainer} mr={24}>
                        <Button
                          fullWidth={isMobile}
                          startIcon={<AddIconWhite />}
                          variant="contained"
                          color="primary"
                          onClick={() => goToAddInsurancePage(insurance.primary)}
                          data-test-id={testId(
                            RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesAddInsuranceButton,
                          )}
                        >
                          {t('ADD_INSURANCE')}
                        </Button>
                      </Stack>
                    )}
                  </>
                ))}
            </Stack>
          )
          : (
            <WBox sx={sx.whiteBox}>
              <Stack sx={sx.noInsurancePlansContainer}>
                <Stack sx={sx.noInsuranceContainer}>
                  <Typography sx={sx.insuranceTitle}>{t('INSURANCE')}</Typography>
                  {!isMobile && canUserEdit && (
                    <Stack sx={sx.addInsuranceBtnContainer}>
                      <Button
                        startIcon={<AddIconPrimary />}
                        variant="outlined"
                        color="primary"
                        onClick={() => goToAddInsurancePage(true)}
                        data-test-id={testId(
                          RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesAddInsuranceButton,
                        )}
                      >
                        {t('ADD_INSURANCE')}
                      </Button>
                    </Stack>
                  )}
                </Stack>
                <Typography component="p" sx={sx.noInsuranceDescription}>
                  {t('NO_INSURANCE_PLANS')}
                </Typography>
                {isShowMobileBtnCondition && (
                  <WBox sx={sx.addInsuranceButtonOnisMobile}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<AddIconPrimary />}
                      onClick={() => goToAddInsurancePage(true)}
                      data-test-id={testId(
                        RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesAddInsuranceButton,
                      )}
                    >
                      {t('ADD_INSURANCE')}
                    </Button>
                  </WBox>
                )}
              </Stack>
            </WBox>
          )}
      </Stack>
      <InsuranceDeleteDialog
        onRemove={onRemove}
        isDeleting={step === StepDialog.LOADING}
        isOpen={step === StepDialog.OPEN || step === StepDialog.LOADING}
        onClose={onClose}
        translate="connections"
        testIdFeatureGroup={RELATIONS_DATA_TEST_ID.relationInfoHealthPoliciesInsurance}
      />
    </>
  );
};
