import {Stack, Typography, Box, Avatar} from '@mui/material';
import {RelationRequest} from '@src/api/relations';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {RELATIONS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/testIds';
import {useSortOfDate} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateFormatted, getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {testId} from '@src/shared/utils/testId';
import {useDeleteRelationRequest} from '@src/store/relations/hooks';
import {Link} from 'ui-kit';

import {sx} from './styles';

export const OutgoingRequests = ({data}: { data: RelationRequest[] | null }) => {
  const {t} = useTranslate('connections');
  const {deleteRequest} = useDeleteRelationRequest();
  const sortedData = useSortOfDate(data, 'createdDate');
  const {mobile: isMobile} = useMQuery();

  if (!data?.length) {
    return (
      <Typography
        sx={sx.emptyRequests}>
        {t('NO_OUTGOING_REQUESTS')}
      </Typography>
    );
  };

  return (
    <Stack sx={sx.container}>
      {sortedData?.map((request) => (
        <Stack
          sx={sx.stackContainer}
          key={request.id}
        >
          <Box sx={sx.boxContainer}>
            <Stack sx={sx.flexContainer}>
              <EllipsisTextTooltip
                variant={isMobile ? '16_20_700' : '18_24_700'}
                ellipsisText={t('TO_NAME', {name: getName(request?.contact, {short: true})})}
              />
              <Typography sx={sx.requestType}>
                {t(request?.type)}
              </Typography>
            </Stack>
            <Avatar sx={sx.requestAvatar} src={request?.avatarUrl} alt={''}>
              <Typography variant="14_20_700">{getUserInitials(request?.contact)}</Typography>
            </Avatar>
          </Box>
          <Stack justifyContent={'space-between'} flexDirection={'row'}>
            <Link
              size='md'
              color="primary"
              onClick={() => deleteRequest({requestId: request.id})}
              data-test-id={testId(RELATIONS_DATA_TEST_ID.relationsRequestsOutgoingDeleteButton)}
            >{t('DELETE')}</Link>
            <Typography sx={sx.date}>
              {dateFormatted(request.createdDate)}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
