export const RELATIONS_DATA_TEST_ID = {
  relations: 'connections__relations',
  relationsRequestsIncomingDeclineButton: 'connections__relations_incoming_decline-button',
  relationsRequestsIncomingAcceptButton: 'connections__relations_incoming_accept-button',
  relationsRequestsOutgoingDeleteButton: 'connections__relations_outgoing_delete-button',
  relationsAddRelationButton: 'connections__relations_add-relation-button',
  relationsListItem: 'connections__relations_list_item',
  relationBreadcrumbsBackLink: 'connections__relation_breadcrumbs_back-link',
  relationBreakRelationButton: 'connections__relation_break-relation-button',
  relationChangeAccessButton: 'connections__relation_change-access-button',
  relationInfoTab: 'connections__relation_info-tab',
  relationRelationsTab: 'connections__relation_relations-tab',
  relationResultsTab: 'connections__relation_results-tab',
  relationInfoPersonalTab: 'connections__relation_info_personal-tab',
  relationInfoContactsTab: 'connections__relation_info_contacts-tab',
  relationInfoHealthPoliciesTab: 'connections__relation_info_health-policies-tab',
  relationBreakRelationDialog: 'connections__relation_break-relation-dialog',
  relationBreakRelationDialogHeaderCloseButton: 'connections__relation_break-relation-dialog_header_close-button',
  relationBreakRelationDialogTypeCheckbox: 'connections__relation_break-relation-dialog_type-checkbox',
  relationBreakRelationDialogFooterCancelButton: 'connections__relation_break-relation-dialog_footer_cancel-button',
  relationBreakRelationDialogFooterBreakButton: 'connections__relation_break-relation-dialog_footer_break-button',
  relationDeleteRelationDialog: 'connections__relation_delete-relation-dialog',
  relationDeleteRelationDialogHeaderCloseButton: 'connections__relation_delete-relation-dialog_header_close-button',
  relationDeleteRelationDialogFooterNoButton: 'connections__relation_delete-relation-dialog_footer_no-button',
  relationDeleteRelationDialogFooterYesButton: 'connections__relation_delete-relation-dialog_footer_yes-button',
  relationErrorDialog: 'connections__relation_error-dialog',
  relationErrorDialogHeaderCloseButton: 'connections__relation_error-dialog_header_close-button',
  relationErrorDialogFooterOkButton: 'connections__relation_error-dialog_footer_ok-button',
  relationSuccessDialog: 'connections__relation_success-dialog',
  relationSuccessDialogHeaderCloseButton: 'connections__relation_success-dialog_header_close-button',
  relationSuccessDialogFooterOkButton: 'connections__relation_success-dialog_footer_ok-button',
  relationChangeAccessDialog: 'connections__relation_change-access-dialog',
  relationChangeAccessDialogHeaderCloseButton: 'connections__relation_change-access-dialog_header_close-button',
  relationChangeAccessDialogFooterBackButton: 'connections__relation_change-access-dialog_footer_back-button',
  relationInfoPersonalEditButton: 'connections__relation_info_personal_edit-button',
  relationInfoPersonalEditFirstNameInput: 'connections__relation_info_personal_edit_first-name-input',
  relationInfoPersonalEditLastNameInput: 'connections__relation_info_personal_edit_last-name-input',
  relationInfoPersonalEditMiddleNameInput: 'connections__relation_info_personal_edit_middle-name-input',
  relationInfoPersonalEditBirthDate: 'connections__relation_info_personal_edit_birth-date',
  relationInfoPersonalEditBirthSexSelect: 'connections__relation_info_personal_edit_birth-sex-select',
  relationInfoPersonalEditBirthSexSelectDropdownItem: 'connections__relation_info_personal_edit_birth-sex-select_dropdown_item',
  relationInfoPersonalEditRaceSelect: 'connections__relation_info_personal_edit_race-select',
  relationInfoPersonalEditRaceSelectDropdownItem: 'connections__relation_info_personal_edit_race-select_dropdown_item',
  relationInfoPersonalEditEthnicitySelect: 'connections__relation_info_personal_edit_ethnicity-select',
  relationInfoPersonalEditEthnicitySelectDropdownItem: 'connections__relation_info_personal_edit_ethnicity-select_dropdown_item',
  relationInfoPersonalEditGenderSelect: 'connections__relation_info_personal_edit_gender-select',
  relationInfoPersonalEditGenderSelectDropdownItem: 'connections__relation_info_personal_edit_gender-select_dropdown_item',
  relationInfoPersonalEditSexualOrientationSelect: 'connections__relation_info_personal_edit_sexual-orientation-select',
  relationInfoPersonalEditSexualOrientationSelectDropdownItem: 'connections__relation_info_personal_edit_sexual-orientation-select_dropdown_item',
  relationInfoPersonalEditSsnTailInput: 'connections__relation_info_personal_edit_ssn-tail-input',
  relationInfoPersonalEditCancelButton: 'connections__relation_info_personal_edit_cancel-button',
  relationInfoPersonalEditSaveButton: 'connections__relation_info_personal_edit_save-button',
  relationInfoContactsAddEditButton: 'connections__relation_info_contacts_add-edit-button',
  relationInfoContactsEditAddressInput: 'connections__relation_info_contacts_edit_address-input',
  relationInfoContactsEditAddress2Input: 'connections__relation_info_contacts_edit_address2-input',
  relationInfoContactsEditCancelButton: 'connections__relation_info_contacts_edit_cancel-button',
  relationInfoContactsEditSaveButton: 'connections__relation_info_contacts_edit_save-button',
  relationInfoHealthPoliciesRequestAccessButton: 'connections__relation_info_health-policies_request-access-button',
  relationInfoHealthPoliciesListItemEditButton: 'connections__relation_info_health-policies_list_item_edit-button',
  relationInfoHealthPoliciesListItemDeleteButton: 'connections__relation_info_health-policies_list_item_delete-button',
  relationInfoHealthPoliciesAddInsuranceButton: 'connections__relation_info_health-policies_add-insurance-button',
  relationInfoHealthPoliciesInsurance: 'connections__relation_info_health-policies_insurance',

  relationInfoHealthPoliciesInsuranceAddCarrier: 'connections__relation_info_health-policies_insurance_add_carrier',
  relationInfoHealthPoliciesInsuranceAddInsurancePlanSelect: 'connections__relation_info_health-policies_insurance_add_insurance-plan-select',
  relationInfoHealthPoliciesInsuranceAddInsurancePlanSelectDropdownItem: 'connections__relation_info_health-policies_insurance_add_insurance-plan-select_dropdown_item',
  relationInfoHealthPoliciesInsuranceAddPolicyIdInput: 'connections__relation_info_health-policies_insurance_add_policy-id-input',
  relationInfoHealthPoliciesInsuranceAddEndDate: 'connections__relation_info_health-policies_insurance_add_end-date',
  relationInfoHealthPoliciesInsuranceAddRelationshipSelect: 'connections__relation_info_health-policies_insurance_add_relationship-select',
  relationInfoHealthPoliciesInsuranceAddRelationshipSelectDropdownItem: 'connections__relation_info_health-policies_insurance_add_relationship-select_dropdown_item',
  relationInfoHealthPoliciesInsuranceAddFirstNameInput: 'connections__relation_info_health-policies_insurance_add_first-name-input',
  relationInfoHealthPoliciesInsuranceAddLastNameInput: 'connections__relation_info_health-policies_insurance_add_last-name-input',
  relationInfoHealthPoliciesInsuranceAddMiddleNameInput: 'connections__relation_info_health-policies_insurance_add_middle-name-input',
  relationInfoHealthPoliciesInsuranceAddBirthDate: 'connections__relation_info_health-policies_insurance_add_birth-date',
  relationInfoHealthPoliciesInsuranceAddBirthSexSelect: 'connections__relation_info_health-policies_insurance_add_birth-sex-select',
  relationInfoHealthPoliciesInsuranceAddBirthSexSelectDropdownItem: 'connections__relation_info_health-policies_insurance_add_birth-sex-select_dropdown_item',
  relationInfoHealthPoliciesInsuranceAddSsnTailInput: 'connections__relation_info_health-policies_insurance_add_ssn-tail-input',
  relationInfoHealthPoliciesInsuranceAddStateSelect: 'connections__relation_info_health-policies_insurance_add_state-select',
  relationInfoHealthPoliciesInsuranceAddStateSelectDropdownItem: 'connections__relation_info_health-policies_insurance_add_state-select_dropdown_item',
  relationInfoHealthPoliciesInsuranceAddCityInput: 'connections__relation_info_health-policies_insurance_add_city-input',
  relationInfoHealthPoliciesInsuranceAddZipCodeInput: 'connections__relation_info_health-policies_insurance_add_zip-code-input',
  relationInfoHealthPoliciesInsuranceAddAddress1Input: 'connections__relation_info_health-policies_insurance_add_address1-input',
  relationInfoHealthPoliciesInsuranceAddAddress2Input: 'connections__relation_info_health-policies_insurance_add_address2-input',
  relationInfoHealthPoliciesInsuranceAddPhoneInput: 'connections__relation_info_health-policies_insurance_add_phone-input',
  relationInfoHealthPoliciesInsuranceAddCancelButton: 'connections__relation_info_health-policies_insurance_add_cancel-button',
  relationInfoHealthPoliciesInsuranceAddSaveButton: 'connections__relation_info_health-policies_insurance_add_save-button',

  relationInfoHealthPoliciesInsuranceEditEndDate: 'connections__relation_info_health-policies_insurance_edit_end-date',
  relationInfoHealthPoliciesInsuranceEditRelationshipSelect: 'connections__relation_info_health-policies_insurance_edit_relationship-select',
  relationInfoHealthPoliciesInsuranceEditRelationshipSelectDropdownItem: 'connections__relation_info_health-policies_insurance_edit_relationship-select_dropdown_item',
  relationInfoHealthPoliciesInsuranceEditFirstNameInput: 'connections__relation_info_health-policies_insurance_edit_first-name-input',
  relationInfoHealthPoliciesInsuranceEditLastNameInput: 'connections__relation_info_health-policies_insurance_edit_last-name-input',
  relationInfoHealthPoliciesInsuranceEditMiddleNameInput: 'connections__relation_info_health-policies_insurance_edit_middle-name-input',
  relationInfoHealthPoliciesInsuranceEditBirthDate: 'connections__relation_info_health-policies_insurance_edit_birth-date',
  relationInfoHealthPoliciesInsuranceEditBirthSexSelect: 'connections__relation_info_health-policies_insurance_edit_birth-sex-select',
  relationInfoHealthPoliciesInsuranceEditBirthSexSelectDropdownItem: 'connections__relation_info_health-policies_insurance_edit_birth-sex-select_dropdown_item',
  relationInfoHealthPoliciesInsuranceEditSsnTailInput: 'connections__relation_info_health-policies_insurance_edit_ssn-tail-input',
  relationInfoHealthPoliciesInsuranceEditStateSelect: 'connections__relation_info_health-policies_insurance_edit_state-select',
  relationInfoHealthPoliciesInsuranceEditStateSelectDropdownItem: 'connections__relation_info_health-policies_insurance_edit_state-select_dropdown_item',
  relationInfoHealthPoliciesInsuranceEditCityInput: 'connections__relation_info_health-policies_insurance_edit_city-input',
  relationInfoHealthPoliciesInsuranceEditZipCodeInput: 'connections__relation_info_health-policies_insurance_edit_zip-code-input',
  relationInfoHealthPoliciesInsuranceEditAddress1Input: 'connections__relation_info_health-policies_insurance_edit_address1-input',
  relationInfoHealthPoliciesInsuranceEditAddress2Input: 'connections__relation_info_health-policies_insurance_edit_address2-input',
  relationInfoHealthPoliciesInsuranceEditPhoneInput: 'connections__relation_info_health-policies_insurance_edit_phone-input',
  relationInfoHealthPoliciesInsuranceEditCancelButton: 'connections__relation_info_health-policies_insurance_edit_cancel-button',
  relationInfoHealthPoliciesInsuranceEditSaveButton: 'connections__relation_info_health-policies_insurance_edit_save-button',

  addRelationSearchInput: 'connections__add-relation_search-input',
  addRelationSearchButton: 'connections__add-relation_search-button',
  addRelationBreadcrumbsBackToSearch: 'connections__add-relation_breadcrumbs_back-to-search',
  addRelationBreadcrumbsToRelations: 'connections__add-relation_breadcrumbs_to-relations',
  addRelationListItemPatientChartButton: 'connections__add-relation_list_item_patient-chart-button',
  addRelationListItemSendRequestButton: 'connections__add-relation_list_item_send-request-button',
  addRelationCreateNewPatientButton: 'connections__add-relation_create-new-patient-button',
  addRelationRelationTypeSelect: 'connections__add-relation_relation-type-select',
  addRelationRelationTypeSelectDropdownItem: 'connections__add-relation_relation-type-select_dropdown_item',
  addRelationPolicyIdInput: 'connections__add-relation_policy-id-input',
  addRelationShowPatientDetailsButton: 'connections__add-relation_show-patient-details-button',

} as const;
