import {Stack, Typography, Box} from '@mui/material';
import {RadioButton} from '@src/components/RadioButton';
import {RadioButtonsGroup} from '@src/components/RadioButtonsGroup';
import {useTranslate} from '@src/i18n/useTranslate';
import {REQUESTS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Requests/testIds';
import {ReactComponent as Expand} from '@src/shared/assets/icons/expand-panel-secondary.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {testId} from '@src/shared/utils/testId';
import {AccountType, RequestsType} from '@src/types';
import {useAppSelector} from '@store';
import {useCallback, useEffect, useRef, useState} from 'react';
import {IconButton} from 'ui-kit';

import {IncomingRequests as IncomingRequestsAccesses} from '../Accesses/Requests/fragments/IncomingRequests';
import {OutgoingRequests as OutgoingRequestsAccesses} from '../Accesses/Requests/fragments/OutgoingRequests';
import {IncomingRequests as IncomingRequestsRelation} from '../Relations/requests/fragments/IncomingRequests';
import {OutgoingRequests as OutgoingRequestsRelation} from '../Relations/requests/fragments/OutgoingRequests';

import {sx} from './styles';
import {IRequestsProps} from './types';

export const Requests = ({
  isIncomingRequest,
  setIsIncomingRequest,
  isRequestsBlockOpen,
  setIsRequestsBlockOpen,
  requestsType,
  testIdFeatureGroup,
}: IRequestsProps) => {
  const {t, ready} = useTranslate('connections');
  const {relationRequests} = useAppSelector((state) => state.relations);
  const incomingRelation = relationRequests ? relationRequests.incoming : null;
  const outgoingRelation = relationRequests ? relationRequests.outgoing : null;

  const {accessesRequests} = useAppSelector((state) => state.accesses);
  const incomingRequests = accessesRequests?.incoming.length
    ? [
      ...(Array.isArray(accessesRequests?.incoming)
        ? accessesRequests?.incoming
        : [accessesRequests?.incoming]),
    ]
    : null;
  const incomingAccesses = incomingRequests
    ? incomingRequests.sort((a, b) => (a.createdDate > b.createdDate ? 1 : -1))
    : null;
  const outgoingAccesses = accessesRequests?.outgoing || null;

  const {desktop: isDesktop, tablet: isTablet} = useMQuery();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [forceUpdate, setForceUpdate] = useState(0);

  const containerHeightCalc = () => {
    if (isTablet) return 'calc(100vh - 174px)';
    if (isDesktop) return 'calc(100vh - 198px)';
    return 'initial';
  };

  const changeDisableVariantDynamics = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!(e.target instanceof HTMLElement)) return;
    const newRequestType = e.target.innerText === AccountType.Incoming;
    setIsIncomingRequest(newRequestType);
  };

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!isTablet) return;
      if (isTablet && containerRef.current && !containerRef.current.contains(e.target as Node)) {
        setIsRequestsBlockOpen(false);
      }
    },
    [containerRef, isTablet, setIsRequestsBlockOpen],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setForceUpdate((prev) => prev + 1);
  }, [isRequestsBlockOpen, isTablet]);

  if (!ready) {
    return null;
  }

  return (
    <Box position="relative" ref={containerRef} key={forceUpdate}>
      <Stack
        sx={[
          ...(Array.isArray(sx.container) ? sx.container : [sx.container]),
          {
            width: isRequestsBlockOpen ? '100%' : 60,
            minWidth: isRequestsBlockOpen
              ? {xs: 'initial', sm: 400, lg: 373}
              : {xs: 0, sm: 'initial'},
            maxWidth: {xs: 'initial', sm: 400, lg: 373},
            p: isRequestsBlockOpen ? 0 : 18,
            position: isRequestsBlockOpen && isTablet ? 'absolute' : 'relative',
            top: isRequestsBlockOpen && isTablet ? 0 : 'initial',
            left: isRequestsBlockOpen && isTablet ? 0 : 'initial',
            zIndex: isRequestsBlockOpen && isTablet ? 10 : 'initial',
            boxShadow:
              isRequestsBlockOpen && isTablet ? '0px 0px 26px 4px rgba(0, 0, 0, 0.15)' : 'none',
            height: containerHeightCalc(),
            m: 0,
          },
        ]}
      >
        {isRequestsBlockOpen
          ? (
            <Stack flexGrow={1} overflow="hidden">
              <Stack
                sx={sx.upperBlockWrapper}
                onClick={() => isTablet && setIsRequestsBlockOpen((previous) => !previous)}
                data-test-id={testId(
                  testIdFeatureGroup + REQUESTS_DATA_TEST_ID.requestsBlockOpenToggle,
                )}
              >
                {!isTablet && <Typography sx={sx.title}>{t('REQUESTS')}</Typography>}
                {isDesktop && (
                  <RadioButtonsGroup>
                    <RadioButton
                      active={isIncomingRequest}
                      onClick={changeDisableVariantDynamics}
                      data-test-id={testId(
                        testIdFeatureGroup + REQUESTS_DATA_TEST_ID.requestsHeaderIncomingRadio,
                      )}
                    >
                      {t('INCOMING')}
                    </RadioButton>
                    <RadioButton
                      active={!isIncomingRequest}
                      onClick={changeDisableVariantDynamics}
                      data-test-id={testId(
                        testIdFeatureGroup + REQUESTS_DATA_TEST_ID.requestsHeaderOutgoingRadio,
                      )}
                    >
                      {t('OUTGOING')}
                    </RadioButton>
                  </RadioButtonsGroup>
                )}
                {isTablet && (
                  <>
                    <Typography sx={sx.title}>{t('REQUESTS')}</Typography>
                    <IconButton
                      size="xs"
                      variant="text"
                      color="secondary"
                      data-test-id={testId(
                        testIdFeatureGroup + REQUESTS_DATA_TEST_ID.requestsExpandButton,
                      )}
                    >
                      <Expand />
                    </IconButton>
                  </>
                )}
              </Stack>
              {!isDesktop && (
                <Stack sx={sx.flexContainer}>
                  <RadioButtonsGroup>
                    <RadioButton
                      active={isIncomingRequest}
                      onClick={changeDisableVariantDynamics}
                      data-test-id={testId(
                        testIdFeatureGroup + REQUESTS_DATA_TEST_ID.requestsHeaderIncomingRadio,
                      )}
                    >
                      {t('INCOMING')}
                    </RadioButton>
                    <RadioButton
                      active={!isIncomingRequest}
                      onClick={changeDisableVariantDynamics}
                      data-test-id={testId(
                        testIdFeatureGroup + REQUESTS_DATA_TEST_ID.requestsHeaderOutgoingRadio,
                      )}
                    >
                      {t('OUTGOING')}
                    </RadioButton>
                  </RadioButtonsGroup>
                </Stack>
              )}
              {isIncomingRequest
                ? (
                  <>
                    {requestsType === RequestsType.Accesses && (
                      <IncomingRequestsAccesses data={incomingAccesses} />
                    )}
                    {requestsType === RequestsType.Relation && (
                      <IncomingRequestsRelation data={incomingRelation} />
                    )}
                  </>
                )
                : (
                  <>
                    {requestsType === RequestsType.Accesses && (
                      <OutgoingRequestsAccesses data={outgoingAccesses} />
                    )}
                    {requestsType === RequestsType.Relation && (
                      <OutgoingRequestsRelation data={outgoingRelation} />
                    )}
                  </>
                )}
            </Stack>
          )
          : (
            <Box
              sx={sx.boxIconContainer}
              onClick={() => setIsRequestsBlockOpen((previous) => !previous)}
              data-test-id={testId(
                testIdFeatureGroup + REQUESTS_DATA_TEST_ID.requestsBlockOpenToggle,
              )}
            >
              <IconButton
                sx={sx.iconBth}
                size="xs"
                variant="text"
                color="secondary"
                data-test-id={testId(testIdFeatureGroup + REQUESTS_DATA_TEST_ID.requestsExpandButton)}
              >
                <Expand />
              </IconButton>
              <Typography typography="16_20_600" sx={sx.requestTitle}>
                {t('REQUESTS')}
              </Typography>
            </Box>
          )}
      </Stack>
    </Box>
  );
};
