import {Box, SxProps, Stack, Theme} from '@mui/material';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {OVERVIEW_DATA_TEST_ID} from '@src/pages/Overview/testIds';
import {OverviewProps} from '@src/types';

import {Contacts} from './fragments/Contacts';
import {GeneralInfo} from './fragments/GeneralInfo';
import {OverviewEmptyFragment} from './fragments/OverviewEmptyFragment';
import {OverviewInsurance} from './fragments/OverviewInsurance';
import {OverviewLastResults} from './fragments/OverviewLastResults';
import {OverviewRelations} from './fragments/OverviewRelations';
import {useOverviewNavigation} from './hooks';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
  },
  boxContainer: (t: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minWidth: 744,
    width: '100%',
    maxWidth: '100%',
    gap: 24,
    [t.breakpoints.only('lg')]: {
      minWidth: 684,
    },
  }),
  infoContainer: {
    padding: '0',
    maxWidth: '100%',
  },
};

export const OverviewDesktopXlOpenSidebar = ({
  accountProfile,
  data,
  isSidebarOpen,
  insurances,
  isPersonOlder18,
}: OverviewProps) => {
  const {t} = useTranslate('overview');
  const {navigateToAddResult, navigateToAddRelation, navigateToAddInsurance} = useOverviewNavigation();

  return (
    <Stack sx={sx.container}>
      <Box sx={sx.boxContainer}>
        <WBox sx={sx.infoContainer}>
          <GeneralInfo
            isSidebarOpen={isSidebarOpen}
            contact={accountProfile?.contact}
            avatarUrl={accountProfile?.avatarUrl}
          />
          <Contacts
            isSidebarOpen={isSidebarOpen}
            profile={accountProfile}
          />
        </WBox>
        <WBox noPaddings={true}>
          {accountProfile?.relations?.length
            ? (
              <OverviewRelations cardsCount={3} />
            )
            : (
              <OverviewEmptyFragment
                variant="14_18_500"
                title={t('RELATIONS')}
                button={isPersonOlder18 ? t('ADD_RELATION') : ''}
                isDisabled={false}
                onClick={navigateToAddRelation}
                description={t('NO_RELATIONSHIPS_WERE_FOUND')}
                buttonDataTestId={OVERVIEW_DATA_TEST_ID.overviewRelationsAddRelationButton}
              />
            )}
        </WBox>
        <WBox noPaddings={true}>
          {data?.length
            ? (
              <OverviewLastResults results={data} />
            )
            : (
              <OverviewEmptyFragment
                isDisabled={false}
                button={t('ADD_RESULT')}
                onClick={navigateToAddResult}
                variant="14_18_500"
                title={t('LAST_RESULTS')}
                description={t('NO_RESULTS')}
                buttonDataTestId={OVERVIEW_DATA_TEST_ID.overviewResultsAddResultsButton}
              />
            )}
        </WBox>
        <WBox noPaddings={true}>
          {insurances.length
            ? (
              <OverviewInsurance insurances={insurances} cardsCount={3} />
            )
            : (
              <OverviewEmptyFragment
                variant="14_18_500"
                isDisabled={false}
                title={t('INSURANCE')}
                button={t('ADD_INSURANCE')}
                onClick={navigateToAddInsurance}
                description={t('NO_INSURANCE_PLANS_WERE_FOUND')}
                buttonDataTestId={OVERVIEW_DATA_TEST_ID.overviewInsuranceAddInsuranceButton}
              />
            )}
        </WBox>
      </Box>
    </Stack>
  );
};
