import {Theme} from '@mui/material';
import {SxProps} from '@mui/system';

export const sx = {
  dialogActionContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderTop: '1px solid',
    borderColor: 'secondary.300',
    p: 24,
  },
} satisfies Record<string, SxProps<Theme>>;
