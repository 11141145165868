import {Stack} from '@mui/material';
import {RadioButton} from '@src/components/RadioButton';
import {RadioButtonsGroup} from '@src/components/RadioButtonsGroup';
import {Tab, useTabs} from '@src/components/TabPanel';
import {useTranslate} from '@src/i18n/useTranslate';
import {ACCESSES_DATA_TEST_ID} from '@src/pages/Connections/fragments/Accesses/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowIcon} from '@src/shared/assets/icons/arrow-right.svg';
import {testId} from '@src/shared/utils/testId';
import {AccountType} from '@src/types';
import {useNavigate} from 'react-router-dom';

import {sx, StyledTabs} from './styles';
import {IMobileTabsProps, TabTypes} from './types';

export const MobileTabs = ({
  accessesTypeForMobile,
  setAccessesTypeForMobile,
}: IMobileTabsProps) => {
  const {t} = useTranslate('accesses');
  const {index, handleChange: tabChange} = useTabs(TabTypes.Requests);
  const navigate = useNavigate();
  return (
    <Stack sx={sx.wrapper}>
      <RadioButtonsGroup>
        <RadioButton
          active={accessesTypeForMobile === AccountType.Incoming}
          onClick={() => setAccessesTypeForMobile(AccountType.Incoming)}
          data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesRequestsHeaderIncomingRadio)}
        >
          {t('INCOMING')}
        </RadioButton>
        <RadioButton
          active={accessesTypeForMobile === AccountType.Outgoing}
          onClick={() => setAccessesTypeForMobile(AccountType.Outgoing)}
          data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesRequestsHeaderOutgoingRadio)}
        >
          {t('OUTGOING')}
        </RadioButton>
      </RadioButtonsGroup>
      <Stack sx={sx.content}>
        <StyledTabs
          value={index}
          onChange={tabChange}
          scrollButtons="auto"
          variant="fullWidth"
        >
          <Tab
            value={TabTypes.Requests}
            label={t('REQUESTS')}
            icon={<ArrowIcon width={24} height={24} />}
            iconPosition="end"
            isActive={false}
            onClick={() =>
              navigate(ROUTERS_PATH.CONNECTIONS_ACCESSES_REQUESTS, {
                state: {accessesTypeForMobile},
              })
            }
            data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesRequestsTab)}
          />
          <Tab
            value={TabTypes.Accepted}
            label={t('ACCEPTED')}
            icon={<ArrowIcon width={24} height={24} />}
            iconPosition="end"
            isActive={false}
            onClick={() =>
              navigate(ROUTERS_PATH.CONNECTIONS_ACCESSES_ACCEPTED, {
                state: {accessesTypeForMobile},
              })
            }
            data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesAcceptedTab)}
          />
        </StyledTabs>
      </Stack>
    </Stack>
  );
};
