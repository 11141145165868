import {FormControlLabel} from '@mui/material';
import {Switch} from '@src/components/Switch';
import {useTranslate} from '@src/i18n/useTranslate';

import {sx} from './styles';
import {IExpiredCheckBoxProps} from './types';

export const ExpiredCheckBox = (props: IExpiredCheckBoxProps) => {
  const {t} = useTranslate('accesses');
  return (
    <FormControlLabel
      {...props}
      sx={sx.checkExpired}
      labelPlacement="start"
      control={<Switch defaultChecked color="secondary" />}
      label={t('SHOW_EXPIRED')}
    />
  );
};
