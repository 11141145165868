import {InputLabel, MenuItem, Select, Typography, Box, FormHelperText} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {MobileSelect} from '@src/components/Select/MobileSelect/MobileSelect';
import {SELECT_DATA_TEST_ID} from '@src/components/Select/testIds';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Arrow} from '@src/shared/assets/icons/chevron-down.svg';
import {ReactComponent as Selected} from '@src/shared/assets/icons/selected.svg';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {useCallback, useState} from 'react';

import {sx} from './styles';
import {ISelectLabelsProps, IOption} from './types';

export const SelectLabels = ({
  value,
  onChange,
  onChangeMobile,
  options,
  label,
  contentPaddingLeft,
  placeholder,
  disabled = false,
  name,
  error,
  helperText,
  onTouched,
  onFullClose,
  testIdFeatureGroup = 'select',
}: ISelectLabelsProps) => {
  const isDisabled = typeof disabled === 'function' ? disabled() : disabled;
  const {t} = useTranslate('common');
  const {mobile: isMobile} = useMQuery();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleTouch = useCallback(() => {
    if (onTouched && name) {
      onTouched(name, true);
    }
  }, [name, onTouched]);

  if (isOpen && isMobile && onChangeMobile) {
    return (
      <MobileSelect
        onBack={handleClose}
        onClose={() => onFullClose?.() || handleClose()}
        name={name}
        label={label}
        value={value}
        options={options}
        onChangeMobile={onChangeMobile}
        onTouch={handleTouch}
        testIdFeatureGroup={testIdFeatureGroup}
      />
    );
  }

  return (
    <FormControl
      onClick={handleTouch}
      disabled={isDisabled}
      sx={(t) => ({
        m: 1,
        width: '100%',
        backgroundColor: isDisabled ? `${t.palette.grey[200]} !important` : 'unset',
        borderColor: isDisabled ? t.palette.grey[300] : 'initial',
        '&:hover': {backgroundColor: t.palette.grey[100]},
        '&:active': {backgroundColor: t.palette.grey[200]},
      })}
    >
      <Typography
        sx={(t) => ({
          position: 'absolute',
          top: '-18px',
          fontSize: 12,
          lineHeight: '18px',
          color: `${t.colors.all.grey[400]} !important`,
        })}
      >
        <em>{helperText}</em>
      </Typography>
      <InputLabel id="demo-simple-select-helper-label" sx={sx.label}>
        {label}
      </InputLabel>
      <Select
        data-test-id={testId(testIdFeatureGroup + SELECT_DATA_TEST_ID.select)}
        labelId="demo-simple-select-helper-label"
        IconComponent={(props) => <Arrow {...props} />}
        displayEmpty
        MenuProps={{sx: {ul: {padding: 0, maxHeight: 300}}}}
        name={name}
        label={label}
        value={value}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={onChange}
        sx={(t) => ({
          '&.MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
            padding: 0,
          },
          ' .MuiSelect-select': {
            padding: `11px 11px 11px ${label ? contentPaddingLeft ?? '85px' : '11px'}`,
            fontWeight: 500,
          },
          svg: (t) => ({
            marginRight: 3,
            mt: '-3px',
            path: {
              fill: t.palette.grey[400],
            },
          }),
          ' .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0.8px !important',
            top: 0,
            legend: {display: 'none'},
          },
          fieldset: {
            borderColor: error
              ? `${t.palette.error.main} !important`
              : `${t.palette.grey[300]} !important`,
          },
        })}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography variant="14_20_500" sx={(t) => ({color: t.palette.grey[400]})}>
                {placeholder}
              </Typography>
            );
          }

          return selected;
        }}
      >
        {(!isMobile || !onChangeMobile) &&
          options.map((option: IOption | any, index: number) => (
            <MenuItem
              value={option.name}
              key={option?.key ?? index}
              selected={false}
              placeholder="Select"
              sx={sx.selectItem}
              disableRipple
              disableTouchRipple
              data-test-id={testId(testIdFeatureGroup + SELECT_DATA_TEST_ID.selectDropdownItem)}
            >
              {value === t(option.name as TKeys<'common'>)
                ? (
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography variant="14_20_500">{t(option.name as TKeys<'common'>)}</Typography>
                    <Selected />
                  </Box>
                )
                : (
                  <Typography variant="14_20_500">{t(option.name as TKeys<'common'>)}</Typography>
                )}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText
        id="component-error-text"
        sx={(t) => ({
          color: `${t.colors.all.errorLegacy}!important`,
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '18px',
          position: 'absolute',
          bottom: '-21px',
          marginLeft: 0,
        })}
      >
        {error}
      </FormHelperText>
    </FormControl>
  );
};
