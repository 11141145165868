import {Stack, Typography, Box, Avatar} from '@mui/material';
import {AccessRequest} from '@src/api/accesses';
import {RowInformation} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {DateRange} from '@src/pages/Connections/fragments/Accesses/common/DateRange';
import {ACCESSES_DATA_TEST_ID} from '@src/pages/Connections/fragments/Accesses/testIds';
import {useMQuery, useSortOfDate} from '@src/shared/hooks';
import {getUserInitials, dateFormatted} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {testId} from '@src/shared/utils/testId';
import {useApproveAccessesRequest, useDeclineAccessesRequest} from '@src/store/accesses/hooks';
import {FC} from 'react';
import {Link} from 'ui-kit';

import {sx} from './styles';

interface IncomingRequestsProps {
  data: AccessRequest[] | null
}
export const IncomingRequests: FC<IncomingRequestsProps> = ({data}) => {
  const {t} = useTranslate('accesses');
  const {approve} = useApproveAccessesRequest();
  const {decline} = useDeclineAccessesRequest();
  const sortedData = useSortOfDate(data, 'createdDate');
  const {mobile: isMobile} = useMQuery();

  if (!data?.length) {
    return <Typography sx={sx.emptyRequests}>{t('NO_INCOMING_REQUESTS')}</Typography>;
  }
  return (
    <Stack sx={sx.wrapper}>
      {sortedData?.map((request) => (
        <Stack sx={sx.stackContainer} key={request.id}>
          <Box sx={sx.boxContainer}>
            <Stack sx={sx.widthLimitContainer}>
              <EllipsisTextTooltip
                ellipsisText={getName(request?.contact, {short: true})}
                sx={sx.personName}
              />
              <Typography variant="14_18_500" sx={sx.personType}>
                {t(request?.access)}
              </Typography>
            </Stack>
            <Stack justifyContent={'end'}>
              <Avatar sx={sx.requestAvatar} src={request.avatarUrl} alt={''}>
                <Typography variant={isMobile ? '12_16_700' : '14_20_700'}>
                  {getUserInitials(request?.contact)}
                </Typography>
              </Avatar>
            </Stack>
          </Box>
          <Stack mb={12}>
            <RowInformation
              spaceBetween={isMobile ? 8 : 0}
              variant={isMobile ? '12_16_700' : '14_18_700'}
              valueTypographyVariant={isMobile ? '12_16_500' : '14_18_500'}
              tooltip
              value={t(`${request?.access}_RIGHTS` as TKeys<'accesses'>)}
              noMargin
              sx={sx.rowInformation}
              name={t('RIGHTS')}
            />
            <DateRange beginDate={request?.createdDate} endDate={request?.endDate} />
          </Stack>
          <Stack sx={sx.bottomContainer}>
            <Stack sx={sx.bthContainer}>
              <Link
                size="md"
                color="primary"
                onClick={() => decline({requestId: request?.id})}
                data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesRequestsIncomingDeclineButton)}
              >
                {t('DECLINE')}
              </Link>
              <Link
                size="md"
                color="secondary"
                onClick={() => approve({requestId: request?.id})}
                data-test-id={testId(ACCESSES_DATA_TEST_ID.accessesRequestsIncomingAcceptButton)}
              >
                {t('ACCEPT')}
              </Link>
            </Stack>
            <Typography sx={sx.date}>{dateFormatted(request?.createdDate)}</Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
