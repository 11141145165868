import {IconButton, IconButtonProps, SxProps, Theme} from '@mui/material';
import {ReactComponent as IconSVGSettings} from '@src/shared/assets/images/icons/icon_settings.svg';
import {testId} from '@src/shared/utils/testId';
import {FC} from 'react';

const buttonSx: SxProps<Theme> = {
  border: '1px solid',
  borderColor: theme => theme.colors.secondary,
  borderRadius: 0,
  ':disabled': {
    borderColor: 'currentcolor',
  },
};

interface SettingsButtonProps extends IconButtonProps {
  dataTestId?: string
}

export const SettingsButton: FC<SettingsButtonProps> = ({dataTestId, ...props}) => {
  return (
    <IconButton
      {...props}
      sx={{...buttonSx, ...props.sx}}
      color={'secondary'}
      size={'large'}
      data-test-id={testId(dataTestId || '')}
    >
      <IconSVGSettings />
    </IconButton>
  );
};
