import {Stack} from '@mui/material';
import {EmptyFragment} from '@src/components/EmptyFragment';
import {WhiteBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {RESULTS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/resultTestIds';
import {testId} from '@src/shared/utils/testId';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {useParams} from 'react-router-dom';

import {Loader} from './Loader';
import {ResultsTab} from './ResultsTab/ResultsTab';

export const RelationResults = () => {
  const {t} = useTranslate('connections');
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const {relation, fetching} = useRelation(relationId);
  const isAccesses = !!relation?.accesses?.length;

  return (
    <>
      <Stack flexGrow={1} gap={24} p={{sm: 24, md: 0}}>
        {isAccesses
          ? (
            <ResultsTab />
          )
          : (
            <>
              {fetching && <Loader />}
              {!fetching && (
                <WhiteBox sx={{flexGrow: 1, padding: 24}}>
                  <EmptyFragment
                    title={t('RESULTS')}
                    description={t('YOU_DONT_HAVE_ACCESS_TO_THIS_DATA')}
                    button={t('REQUEST_ACCESS')}
                    isButtonIcon={false}
                    noPaddings
                    buttonDataTestId={testId(RESULTS_DATA_TEST_ID.resultsRequestAccessButton)}
                  />
                </WhiteBox>
              )}
            </>
          )}
      </Stack>
    </>
  );
};
