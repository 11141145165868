export const STORAGE_DATA_TEST_ID = {
  storageFiltersHealthCaseSelect: 'storage__filters_health-case_select',
  storageFiltersHealthCaseSelectDropdownCheckUncheckAll: 'storage__filters_health-case_select_dropdown_check-uncheck-all',
  storageFiltersHealthCaseSelectDropdownItem: 'storage__filters_health-case_select_dropdown_item',
  storageFiltersDialogHealthCaseSelect: 'storage__filters_dialog_health-case_select',
  storageFiltersDialogHealthCaseSelectDropdownCheckUncheckAll: 'storage__filters_dialog_health-case_select_dropdown_check-uncheck-all',
  storageFiltersDialogHealthCaseSelectDropdownItem: 'storage__filters_dialog_health-case_select_dropdown_item',
  storageHeaderAddButton: 'storage__header_add-button',
  storage: 'storage_',
  storageHeaderShareButton: 'storage__header_share-button',
  storageMobileShareButton: 'storage__mobile_share-button',
  storageHeader: 'storage__header',
  storageHeaderFiltersInput: 'storage__header_filters_search-input',
  storageHeaderFilterOpenFiltersButton: 'storage__header_filters_open-filter-button',
  storageHeaderFiltersCategorySelect: 'storage__header_filters_category-select',
  storageHeaderFiltersCategorySelectDropdownItem: 'storage__header_filters_category-select_dropdown_item',
  storageHeaderFiltersHealthCases: 'storage__header_filters_health-cases',
  storageHeaderFiltersDateRangeButton: 'storage__header_filters_date-range-button',
  storageHeaderFiltersDateRange: 'storage__header_filters_date-range',
  storageHeaderFiltersTypeButton: 'storage__header_filters_type-button',
  storageHeaderFiltersClearButton: 'storage__header_filters_clear-button',
  storageHeaderFiltersDialogCategorySelect: 'storage__header_filters-dialog_category-select',
  storageHeaderFiltersDialogCategorySelectDropdownItem: 'storage__header_filters-dialog_category-select_dropdown_item',
  storageHeaderFiltersDialogHealthCases: 'storage__header_filters-dialog_health-cases',
  storageHeaderFiltersDialogDateRange: 'storage__header_filters-dialog_date-range',
  storageListItemCheckbox: 'storage__list_item_checkbox',
  storageListItemFavoriteButton: 'storage__list_item_favorite-button',
  storageListItemViewButton: 'storage__list_item_view-button',
  storageListItemEditButton: 'storage__list_item_edit-button',
  storageListItemShareButton: 'storage__list_item_share-button',
  storageListItem: 'storage__list_item',
  storageListItemDeleteButton: 'storage__list_item_delete-button',
} as const;
