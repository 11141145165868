import {Stack, Box} from '@mui/material';
import {RequestRelationType} from '@src/api/relations';
import {
  Breadcrumbs,
  Link,
  Text,
  InfiniteScrollContainer,
  WBox,
  AlertWithAnimatedBorder,
} from '@src/components';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {PulseLoader} from '@src/components/PulseLoader';
import {useTranslate} from '@src/i18n/useTranslate';
import {RELATIONS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as InformationIcon} from '@src/shared/assets/icons/information.svg';
import {ReactComponent as PlusIcon} from '@src/shared/assets/icons/plus.svg';
import {REQUEST_RELATION_TYPE_MAP} from '@src/shared/constants/enumsMaps';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useRecaptcha} from '@src/shared/hooks/useRecaptcha';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {testId} from '@src/shared/utils/testId';
import {useAppSelector} from '@src/store';
import {useAccountProfile} from '@src/store/account/hooks';
import {useSearchRelationContacts} from '@src/store/relations/hooks';
import {PER_PAGE, relationsActions} from '@src/store/relations/slice';
import {Formik, FormikTouched} from 'formik';
import lodash from 'lodash';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, InputControl, MenuItem} from 'ui-kit';

import {AddRelationMobileLayout} from '../AddRelationTable/AddRelationMobileLayout';
import {AddRelationTable} from '../AddRelationTable/AddRelationTable';
import {AddRelationTableTabletLayout} from '../AddRelationTable/AddRelationTableTabletLayout.tsx';
import {LOAD_MORE_THRESHOLD} from '../AddRelationTable/constant';
import {relationshipTypesSelectOptions} from '../CreateNewPatient/constants';
import {SendRequestSuccessModal} from '../SendRequestSuccessModal';

import {SelectParentRolDialog} from './SelectParentRolDialog';
import {openModal} from './helpers';
import {sx, StyledForm} from './styles';
import {getValidationSchema} from './validationSchema';

export const AddRelation = () => {
  useRecaptcha();

  const {t, ready} = useTranslate('connections');
  const {t: tEnums, ready: enumsReady} = useTranslate('enums');
  const {mobile: isMobile, tablet: isTablet, desktop: isDesktop} = useMQuery();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {state} = useLocation();

  const {accountProfile} = useAccountProfile();
  const {search, relationContacts, fetching, loadMore} = useSearchRelationContacts();

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [relationTypeValue, setRelationTypeValue] = useState<RequestRelationType | null>(null);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(true);

  const {isSearched, policyId, relationType, searchBy} = useAppSelector(
    (state) => state.relations.prevAddRalationData,
  );

  const closeModal = () => {
    setIsModalOpened(false);
    setIsFirstModalOpen(false);
  };

  const initialValue = {
    relationType: relationType || null,
    searchBy: searchBy || null,
    policyId: policyId || null,
    isSearched: isSearched || null,
  };

  const searchData = ({
    relationType,
    searchBy,
    policyId,
  }: {
    relationType: RequestRelationType | null
    searchBy: string | null
    policyId: string | null
  }) => {
    const hasValidSearchBy = searchBy && searchBy.length > 1;
    const hasValidPolicyId = policyId && policyId.length > 1;

    const data = {
      relationType: relationType || '',
      filter: searchBy || '',
      perPage: PER_PAGE,
      policyNumber: policyId || '',
      recaptcha: '',
      startPage: 0,
    };
    if (!hasValidSearchBy && !hasValidPolicyId) {
      setIsAlertVisible(true);
    } else {
      setIsAlertVisible(false);
      dispatch(relationsActions.setContacts({contacts: data}));
      dispatch(relationsActions.setRelationContacts({relationContacts: null}));
      setRelationTypeValue(relationType);
      search({data});
      setHasSearched(true);
      dispatch(
        relationsActions.setPrevAddRelationData({
          relationType,
          policyId,
          searchBy,
          isSearched: true,
        }),
      );
    }
  };

  const iconSize = isMobile ? 18 : 24;

  const shouldShowPulseLoader = fetching && !relationContacts;

  const currentRelationType: RequestRelationType | null = relationTypeValue || relationType;

  const shouldShowCreatePatientButton = (touched: FormikTouched<typeof initialValue>) => {
    return !isMobile && (touched.isSearched || isSearched) && relationContacts;
  };

  const handleClickCreatePatient = () => {
    navigate(
      `${ROUTERS_PATH.CONNECTIONS_CREATE_A_NEW_PATIENT}?relationshipType=${
        currentRelationType ?? ''
      }`,
    );
  };

  useEffect(() => {
    if (!state?.toAddRelationPage) {
      dispatch(
        relationsActions.setPrevAddRelationData({
          relationType: null,
          policyId: null,
          searchBy: null,
          isSearched: false,
        }),
      );
      dispatch(relationsActions.setRelationContacts({relationContacts: null}));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (isAlertVisible) {
      const timer = setTimeout(() => {
        setIsAlertVisible(false);
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [isAlertVisible]);

  useEffect(() => {
    window.scrollTo(0, -50);
  }, [hasSearched]);

  const handleMobileLoadMore = () => {
    if (isMobile) {
      loadMore();
    }
  };

  if (!ready || !enumsReady) return null;

  return (
    <InfiniteScrollContainer
      sx={sx.infiniteScrollContainer}
      threshold={LOAD_MORE_THRESHOLD}
      onLoad={handleMobileLoadMore}
    >
      <Formik<typeof initialValue>
        enableReinitialize
        onSubmit={(values) => {
          if (!lodash.isEqual(initialValue, values) || isMobile) searchData(values);
        }}
        initialValues={initialValue}
        validationSchema={getValidationSchema(t)}
      >
        {({handleChange, values, setFieldValue, handleSubmit, touched}) => {
          if (isMobile && hasSearched) {
            return (
              <AddRelationMobileLayout
                relationContacts={relationContacts}
                searchBy={values.searchBy}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                relationType={values.relationType}
                setHasSearched={setHasSearched}
                fetching={fetching}
              />
            );
          }

          return (
            <StyledForm>
              <WBox sx={sx.whiteContainer} noPaddings>
                <Stack sx={{flexGrow: 1}}>
                  <Stack sx={sx.container}>
                    <Stack sx={sx.flexContainerBreadCrumbs}>
                      <Breadcrumbs>
                        <Link
                          sx={sx.breadCrumbsFont}
                          to={ROUTERS_PATH.CONNECTIONS_RELATIONS}
                          data-test-id={testId(
                            RELATIONS_DATA_TEST_ID.addRelationBreadcrumbsToRelations,
                          )}
                        >
                          {t('RELATIONS')}
                        </Link>
                        <Text
                          sx={[...spreadSx(sx.breadCrumbsFont), ...spreadSx(sx.breadCrumbsText)]}
                        >
                          {t('ADD_RELATION')}
                        </Text>
                      </Breadcrumbs>
                      {shouldShowCreatePatientButton(touched) && (
                        <Button
                          sx={{mb: -18}}
                          variant="outlined"
                          color="secondary"
                          onClick={handleClickCreatePatient}
                          startIcon={<PlusIcon />}
                          data-test-id={testId(
                            RELATIONS_DATA_TEST_ID.addRelationCreateNewPatientButton,
                          )}
                        >
                          {t('CREATE_A_NEW_PATIENT')}
                        </Button>
                      )}
                    </Stack>
                    <Box sx={sx.selectContainer}>
                      <FormSelectControl<keyof typeof REQUEST_RELATION_TYPE_MAP>
                        placeholder={t('SELECT')}
                        name="relationType"
                        label={t('RELATIONSHIP_TYPE')}
                        renderValue={(v) => (v ? tEnums(REQUEST_RELATION_TYPE_MAP[v]) : '')}
                        data-test-id={testId(RELATIONS_DATA_TEST_ID.addRelationRelationTypeSelect)}
                      >
                        {relationshipTypesSelectOptions.map(({name, value}, key) => (
                          <MenuItem
                            key={key}
                            value={value}
                            data-test-id={testId(
                              RELATIONS_DATA_TEST_ID.addRelationRelationTypeSelectDropdownItem,
                            )}
                          >
                            {tEnums(name)}
                          </MenuItem>
                        ))}
                      </FormSelectControl>
                    </Box>
                    <AlertWithAnimatedBorder
                      variant={isMobile ? '12_16_500' : '14_18_500'}
                      isVisible={isAlertVisible}
                      text={t('ADD_RELATION_INFO_BLOCK')}
                      icon={
                        <Box sx={sx.alertWithAnimatedBorder}>
                          <InformationIcon width={iconSize} height={iconSize} />
                        </Box>
                      }
                    />
                    <Stack sx={sx.searchBlockContainer}>
                      <InputControl
                        sx={sx.searchByNameInput}
                        name="searchBy"
                        label={t('SEARCH_BY')}
                        placeholder={t(
                          isMobile ? 'SEARCH_BY_PLACEHOLDER_MOBILE' : 'SEARCH_BY_PLACEHOLDER',
                        )}
                        value={values.searchBy || ''}
                        onChange={handleChange}
                        data-test-id={testId(RELATIONS_DATA_TEST_ID.addRelationSearchInput)}
                      />
                      <InputControl
                        sx={sx.searchByPolicyIdInput}
                        label={t('POLICY_ID')}
                        name="policyId"
                        value={values.policyId || ''}
                        onChange={handleChange}
                        data-test-id={testId(RELATIONS_DATA_TEST_ID.addRelationPolicyIdInput)}
                      />
                      {!isMobile && (
                        <Button
                          sx={sx.searchBth}
                          variant="contained"
                          color="primary"
                          type="submit"
                          data-test-id={testId(RELATIONS_DATA_TEST_ID.addRelationSearchButton)}
                        >
                          {t('SEARCH')}
                        </Button>
                      )}
                    </Stack>
                    {isMobile && (
                      <Stack sx={sx.flexContainer}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          data-test-id={testId(RELATIONS_DATA_TEST_ID.addRelationSearchButton)}
                        >
                          {t('SEARCH')}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                  {shouldShowPulseLoader && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%">
                      <PulseLoader loading={fetching} />
                    </Box>
                  )}
                  {relationContacts !== null && (
                    <>
                      {isDesktop && (
                        <Stack sx={{height: 'calc(100vh - 515px)', marginTop: '1px'}}>
                          <AddRelationTable
                            relationContacts={relationContacts}
                            relationType={currentRelationType}
                            loadMore={loadMore}
                          />
                        </Stack>
                      )}
                      {isTablet && (
                        <Stack sx={{height: 'calc(100vh - 515px)', overflow: 'auto'}}>
                          <AddRelationTableTabletLayout
                            relationContacts={relationContacts}
                            relationType={currentRelationType}
                            loadMore={loadMore}
                          />
                        </Stack>
                      )}
                    </>
                  )}
                  {!isMobile && !relationContacts?.length && <Stack sx={sx.spaceContainer}></Stack>}
                </Stack>
              </WBox>
              {openModal({
                values,
                birthDate: accountProfile?.contact?.birthDate,
                birthSex: accountProfile?.contact?.birthSex,
                setIsModalOpened,
                isFirstModalOpen,
              }) && (
                <SelectParentRolDialog
                  closeModal={closeModal}
                  isModalOpened={isModalOpened}
                  relationType={values.relationType || null}
                  setFieldValue={setFieldValue}
                  birthDate={accountProfile?.contact?.birthDate}
                  birthSex={accountProfile?.contact?.birthSex}
                />
              )}
              <SendRequestSuccessModal isFullWidthBth={false} />
            </StyledForm>
          );
        }}
      </Formik>
    </InfiniteScrollContainer>
  );
};
