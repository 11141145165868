import {Box, Stack} from '@mui/material';
import {Panel, Product, Test} from '@src/api';
import {RejectedReason} from '@src/api/__generated__/webApi';
import {
  Breadcrumbs,
  EmptySearch,
  ITableProps,
  Text,
  RowInformation,
  Table,
  PageHeader,
  Link,
} from '@src/components';
import Grid from '@src/components/Grid';
import {SendResult} from '@src/components/SendResult';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {useHeader} from '@src/pages/Details';
import {ExpandableTableRowContainer} from '@src/pages/Details/ExpandableTableRowContainer/ExpandableTableRowContainer';
import {UpdateCode} from '@src/pages/Details/ExpandableTableRowContainer/types';
import {RejectedReasonsDialog} from '@src/pages/Details/components/RejectedReasonsDialog';
import {filterValidTestsForPanels, filterValidTestsForProducts} from '@src/pages/Details/helpers';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as DownloadIcon} from '@src/shared/assets/icons/download.svg';
import {envs} from '@src/shared/constants/envs';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat, DATE_FORMAT, getFullName, dateFormatted, getAge} from '@src/shared/utils';
import {testId} from '@src/shared/utils/testId';
import {useAppSelector} from '@src/store';
import {useContactRelations, useEmailForm, useRelationDetails} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {Steps} from '@src/types';
import {Table as TanstackTable} from '@tanstack/react-table';
import {BoxBody, WhiteBox, BoxPaddings, BoxHeader} from 'components/WhiteBox/WhiteBox';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {RESULTS_DATA_TEST_ID} from '../../resultTestIds';
import {Loader} from '../Loader';

import {ResultDetailsTable} from './ResultDetailsTable/ResultDetailsTable';
import {checkBadStatus} from './ResultDetailsTable/helpers';
import {sx} from './styles';
import {getResultTitle} from './utils/getResultTitle';
import {isTest} from './utils/isTest';

export const ResultDetails: React.FC = () => {
  const {t, ready} = useTranslate('details');
  const {mobile, tablet, tabletPortrait, desktop} = useMQuery();

  const {id, resultId, companyId} = useParams();
  const {relationId, fetching} = useContactRelations(id);
  const {result, isNotFound} = useRelationDetails({relationId, resultId});

  const [testCodes, setTestCodes] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [allCodeSelect, setAllCodeSelect] = useState<string[]>([]);
  const [selectHeaderCheckbox, setSelectHeaderCheckbox] = useState(false);
  const [dialogRejectedReasons, setDialogRejectedReasons] = useState<RejectedReason[] | null>(null);

  const {submit} = useEmailForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {sendResultStep} = useAppSelector((state) => state.relations);

  const closeSendResultModal = () => {
    void dispatch(relationsActions.setSendResultStep({step: Steps.INIT}));
  };

  const handleSetDialogRejectedReasons = (rejectedReasons: RejectedReason[]) => {
    if (!mobile && !tablet) {
      return;
    }
    setDialogRejectedReasons(rejectedReasons);
  };
  const handleCloseRejectedReasonsDialog = () => setDialogRejectedReasons(null);

  useEffect(() => {
    const allTestCodesInPanels = result?.panels?.map((panel) =>
      panel.products?.flatMap((product) => product.tests.flatMap((test) => test.code)),
    );
    const allTestCodesProducts = result?.products?.map((product) =>
      product.tests.flatMap((test) => test.code),
    );

    const allTestCodes = [...(allTestCodesInPanels ?? []), ...(allTestCodesProducts ?? [])].flat();

    if (allTestCodes) {
      setAllCodeSelect(allTestCodes as string[]);
    }
  }, [result?.panels, result?.products]);

  useEffect(() => {
    if (allCodeSelect.length > 0 && testCodes.length === allCodeSelect.length) {
      setSelectHeaderCheckbox(true);
      setSelectAll(true);
    } else {
      setSelectHeaderCheckbox(false);
      setSelectAll(false);
    }
  }, [testCodes, allCodeSelect]);

  const goToDynamics = () => {
    const tests = encodeURI(JSON.stringify([...new Set(testCodes)]));
    if (id && resultId && companyId) {
      navigate(
        `${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id}/dynamics/${resultId}/${companyId}/${tests}/results`,
      );
    } else {
      console.error('Parameters are undefined');
    }
  };

  const changeCodesForDynamics: UpdateCode = (item, checked, flag) => {
    if (typeof item === 'string') {
      if (checked) {
        setTestCodes((prev) => [...prev, item]);
      } else {
        const findCodeIndex = testCodes.indexOf(item);
        setTestCodes((prev) => {
          prev.splice(findCodeIndex, 1);
          return [...prev];
        });
      }
    } else {
      const tests: string[] = flag
        ? item.flat().map((t: Product | Panel) => t.code)
        : (item as Product[] | Panel[]).map((t: Product | Panel) => t.code);

      if (checked) {
        setTestCodes((prev) => [...new Set([...prev, ...tests])]);
      } else {
        setTestCodes((prev) => {
          return prev.filter((t) => {
            const findIndex = tests.indexOf(t);
            if (findIndex >= 0) {
              tests.splice(findIndex, 1);
            }
            return findIndex < 0;
          });
        });
      }
    }
  };

  const handleSendResult = (email: string) => {
    submit({relationId, resultId, email});
  };

  const changeAllTests = (_: any, checked: boolean) => {
    if (checked) {
      setSelectAll(true);
      setTestCodes([...allCodeSelect]);
    } else {
      setSelectAll(false);
      setTestCodes([]);
    }
  };

  const handleToggleRowSelection = (row: Product | Test, checked: boolean) => {
    if (isTest(row)) {
      changeCodesForDynamics(row.loinc, checked);
    } else {
      row.tests.forEach((test: Test) => {
        if (!checkBadStatus(test.status)) {
          changeCodesForDynamics(test.loinc, checked);
        }
      });
    }
  };

  const handleToggleAllRowsSelection = (table: TanstackTable<Product>, checked: boolean) => {
    table.toggleAllRowsSelected(checked);
    const allRows = table.getRowModel().rows;
    if (checked) {
      const allTestCodes = allRows
        .flatMap((row) => row.original.tests)
        .filter((item) => item !== undefined && !checkBadStatus(item.status))
        .map((test: Test) => test.loinc);
      setTestCodes(allTestCodes);
    } else {
      setTestCodes([]);
    }
  };

  const {HEADER_LIST} = useHeader(changeAllTests, selectHeaderCheckbox);

  const desktopGridColumns = '300px 1fr 1fr 1fr 1fr';
  const tabletGridColumns = '28.79% 26.77% 5.55% 12.59% 15.65%';
  const mobileGridColumns = '50px 1fr';
  const gridTemplateColumns = useMemo(() => {
    if (tablet) {
      return tabletGridColumns;
    }
    return mobile ? mobileGridColumns : desktopGridColumns;
  }, [tablet, mobile]);

  const filteredData = useMemo(() => {
    const filteredProducts = filterValidTestsForProducts(result?.products || []);
    const filteredPanels = filterValidTestsForPanels(result?.panels || []);

    return [...filteredProducts, ...filteredPanels] as ITableProps<Product & Panel>['data'];
  }, [result]);

  if (isNotFound && !fetching) {
    navigate(ROUTERS_PATH.NOT_FOUND);
    return null;
  }

  if (!result || fetching || !ready) return <Loader />;

  const {orderDetails, patient} = result;
  const {birthDate, birthSex, firstName, lastName} = patient;
  const {collected, number, provider, reported} = orderDetails;

  const onPortrait = mobile || tabletPortrait;
  const onlyMobileAndTablet = mobile || tablet;

  return (
    <Box sx={sx.mainContainer}>
      <Stack sx={sx.container}>
        <Stack sx={sx.topContainer}>
          <Breadcrumbs sx={{alignSelf: 'flex-start'}}>
            <Link
              data-test-id={testId(RESULTS_DATA_TEST_ID.detailsTableBreadcrumbsResults)}
              to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/results`}
              sx={{typography: mobile ? '14_18_500' : '16_24_500'}}
            >
              {t('RESULTS')}
            </Link>
            <Text sx={sx.breadcrumbsText}>{getResultTitle(orderDetails)}</Text>
          </Breadcrumbs>
          {onlyMobileAndTablet
            ? (
              <PageHeader
                alignItems="center"
                variant={'20_24_700'}
                sxTypography={{display: 'flex', alignItems: 'center'}}
                itemAction={
                  <SendResult
                    onSubmit={handleSendResult}
                    step={sendResultStep}
                    close={closeSendResultModal}
                    testIdFeatureGroup={RESULTS_DATA_TEST_ID.details}
                  />
                }
              >
                {getResultTitle(orderDetails)}
              </PageHeader>
            )
            : (
              <PageHeader
                itemAction={
                  <SendResult
                    onSubmit={handleSendResult}
                    step={sendResultStep}
                    close={closeSendResultModal}
                    testIdFeatureGroup={RESULTS_DATA_TEST_ID.details}
                  />
                }
              />
            )}
        </Stack>
        <Grid
          spacing={{xs: 20, sm: 24, lg: 36}}
          sx={{
            gap: {xs: 18, sm: 24, lg: 60},
            display: 'grid',
            gridTemplateColumns: `${onPortrait ? '1fr' : '1fr 1fr'}`,
          }}
        >
          <Grid xs={12} md={6} sx={sx.gridWrapper}>
            <WhiteBox
              headerSx={sx.headerSx}
              notApplySx
              collapsible={onPortrait}
              id={'boxOne'}
              sx={sx.wBoxContent}
              header={t('PATIENT_DETAILS')}
              headerMargin="24px"
              noPaddings={!tablet}
              hasIconBox
              noBorder={!onPortrait}
              buttonDataTestId={testId(RESULTS_DATA_TEST_ID.detailsInfoCollapseButton)}
            >
              <Stack>
                <Grid
                  xs={12}
                  lg={12}
                  xl={8}
                  sx={sx.resetPaddingBox}>
                  <RowInformation value={firstName} tooltip name={t('FIRST_NAME')} />
                  <RowInformation value={lastName} tooltip name={t('LAST_NAME')} />
                </Grid>
                <Grid
                  xs={12}
                  lg={12}
                  xl={6}
                  sx={sx.resetPaddingBox}>
                  <RowInformation
                    value={
                      birthDate
                        ? `${dateFormatted(birthDate) || ''} (${getAge(birthDate)} ${t('YEARS')})`
                        : null
                    }
                    name={t('DATE_OF_BIRTH')}
                  />
                  <RowInformation value={t(birthSex)} name={t('BIRTH_SEX')} noMargin />
                </Grid>
              </Stack>
            </WhiteBox>
          </Grid>
          <Grid xs={12} md={6} sx={sx.gridWrapper}>
            <WhiteBox
              headerSx={sx.headerSx}
              notApplySx
              collapsible={onPortrait}
              id={'boxTwo'}
              sx={sx.wBoxContent}
              header={t('ORDER_DETAILS')}
              headerMargin="24px"
              noPaddings={!tablet}
              hasIconBox
              noBorder={!onPortrait}
              buttonDataTestId={testId(RESULTS_DATA_TEST_ID.detailsInfoCollapseButton)}
            >
              <BoxBody>
                <Stack>
                  <Grid
                    xs={12}
                    lg={12}
                    xl={8}
                    sx={sx.resetPaddingBox}>
                    <RowInformation value={number.toString()} tooltip name={t('ORDER_ID')} />
                    <RowInformation
                      value={dateToFormat(DATE_FORMAT, collected)}
                      name={t('COLLECTION_DATE')}
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    lg={12}
                    xl={6}
                    sx={sx.resetPaddingBox}>
                    <RowInformation
                      value={dateToFormat(DATE_FORMAT, reported)}
                      name={t('REPORTED_DATE')}
                    />
                    <RowInformation
                      value={getFullName(provider)}
                      tooltip
                      noMargin
                      name={t('ORDERING_PROVIDER')}
                    />
                  </Grid>
                </Stack>
              </BoxBody>
            </WhiteBox>
          </Grid>
        </Grid>
      </Stack>
      <WBox noPaddings sx={sx.wBoxContainer}>
        <BoxBody>
          <BoxPaddings sx={sx.boxPaddings}>
            <Box sx={sx.boxContainer}>
              <BoxHeader>{t('RESULTS')}</BoxHeader>
              {!mobile && (
                <Box sx={sx.bthContainer}>
                  <Button
                    data-test-id={testId(RESULTS_DATA_TEST_ID.detailsDownloadButton)}
                    variant={'text'}
                    startIcon={<DownloadIcon />}
                    href={
                      relationId && resultId
                        ? `${envs.BASE_API}${generatePath(
                          ROUTERS_PATH.CONNECTIONS_RELATIONS_RESULTS_FN_DOWNLOAD,
                          {relationId, resultId},
                        )}`
                        : ''
                    }
                    target="_blank"
                  >
                    {t('DOWNLOAD_RESULTS')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={goToDynamics}
                    disabled={testCodes.length === 0}
                    data-test-id={testId(RESULTS_DATA_TEST_ID.detailsDynamicButton)}
                  >
                    {t('DYNAMICS_OF_RESULTS')}
                  </Button>
                </Box>
              )}
            </Box>
          </BoxPaddings>
          {desktop && (
            <ResultDetailsTable
              data={filteredData}
              handleToggleAllRowsSelection={handleToggleAllRowsSelection}
              handleToggleRowSelection={handleToggleRowSelection}
              onSetDialogRejectedReasons={handleSetDialogRejectedReasons}
            />
          )}
          {onlyMobileAndTablet && (
            <Table
              paddingLeft="18px"
              header={desktop}
              keyIndicator="code"
              data={filteredData}
              gridTemplateColumns={gridTemplateColumns}
              headerList={HEADER_LIST}
              rowComponent={{
                component: ExpandableTableRowContainer,
                props: {
                  changeCodesForDynamics,
                  testCodes,
                  selectAll,
                  selectRest: selectHeaderCheckbox,
                  gridTemplateColumns,
                  testIdFeatureGroup: RESULTS_DATA_TEST_ID.detailsTable,
                  onSetDialogRejectedReasons: handleSetDialogRejectedReasons,
                },
              }}
              loading={fetching}
              emptyData={<EmptySearch isLoading={fetching} isNew isDetails />}
              minHeight={820}
            />
          )}
        </BoxBody>
        {mobile && (
          <Box sx={sx.buttonsContainer}>
            <Button
              fullWidth
              variant={'contained'}
              onClick={goToDynamics}
              disabled={testCodes.length === 0}
              data-test-id={testId(RESULTS_DATA_TEST_ID.detailsDynamicButton)}
            >
              {t('DYNAMICS_OF_RESULTS')}
            </Button>
            <Button
              data-test-id={testId(RESULTS_DATA_TEST_ID.detailsDownloadButton)}
              sx={{width: '100%'}}
              variant={'outlined'}
              href={
                relationId && resultId
                  ? `${envs.BASE_API}${generatePath(
                    ROUTERS_PATH.CONNECTIONS_RELATIONS_RESULTS_FN_DOWNLOAD,
                    {relationId, resultId},
                  )}`
                  : ''
              }
              target="_blank"
            >
              {t('DOWNLOAD_RESULTS')}
            </Button>
          </Box>
        )}
      </WBox>
      <RejectedReasonsDialog
        rejectedReasons={dialogRejectedReasons}
        onClose={handleCloseRejectedReasonsDialog}
        testIdFeatureGroup={RESULTS_DATA_TEST_ID.detailsRejectedReasonsDialog}
      />
    </Box>
  );
};
