export const ACCESSES_DATA_TEST_ID = {
  accesses: 'connections__accesses',
  accessesRequestsHeaderIncomingRadio: 'connections__accesses_requests_header_incoming-radio',
  accessesRequestsHeaderOutgoingRadio: 'connections__accesses_requests_header_outgoing-radio',
  accessesRequestsTab: 'connections__accesses_requests-tab',
  accessesAcceptedTab: 'connections__accesses_accepted-tab',
  accessesRequestsIncomingDeclineButton: 'connections__accesses_incoming_decline-button',
  accessesRequestsIncomingAcceptButton: 'connections__accesses_incoming_accept-button',
  accessesRequestsOutgoingDeleteButton: 'connections__accesses_outgoing_delete-button',
  accessesHeaderExpiredToggle: 'connections__accesses_header_expired-toggle',
  accessesRequestAccessButton: 'connections__accesses_request-access-button',
  accessesListItemDeleteButton: 'connections__accesses_list_item_delete-button',
  accessesDeleteDialog: 'connections__accesses_delete-dialog',
  accessesDeleteDialogHeaderCloseButton: 'connections__accesses_delete-dialog_header_close-button',
  accessesDeleteDialogFooterCloseButton: 'connections__accesses_delete-dialog_footer_close-button',
} as const;
