/* eslint-disable no-comments/disallowComments */
export const RESULTS_DATA_TEST_ID = {
  // Results
  resultsHeaderOpenFiltersButton: 'connections__results_header_open-filers-button',
  resultsFiltersRefreshButton: 'connections__results_filters_refresh-button',
  resultsFiltersPhysician: 'connections__results_filters_physician',
  resultsFiltersCollectionDate: 'connections__results_filters_collection-date',
  resultsTableRow: 'connections__results_table_row',
  resultsFiltersDialog: 'connections__results_filter-dialog',
  resultsFiltersDialogPhysicianSelect: 'connections__results_filter-dialog_physician-select',
  resultsFiltersDialogPhysicianSelectDropdownItem: 'connections__results_filter-dialog_physician-select_dropdown_item',
  resultsFiltersDialogCollectionDate: 'connections__results_filter-dialog_collection-date',
  resultsRequestAccessButton: 'connections__results_request-access-button',
  // Result details
  detailsCheckboxAll: 'connections__result-details_table_checkbox-all',
  detailsCheckboxTest: 'connections__result-details_table_checkbox-test',
  detailsTable: 'connections__result-details_table',
  detailsTableBreadcrumbsResults: 'connections__result-details_breadcrumbs_results',
  details: 'connections__result-details',
  detailsInfoCollapseButton: 'connections__result-details_info_collapse-button',
  detailsDownloadButton: 'connections__result-details_download-button',
  detailsDynamicButton: 'connections__result-details_dynamic-button',
  detailsTableExpandButton: 'connections__result-details_table_expand-button',
  detailsRejectedReasonsDialog: 'connections__result-details__rejected-reasons-dialog',
  // Results dynamic
  dynamicsBreadcrumbsResults: 'connections__dynamics_breadcrumbs_results',
  dynamicsBreadcrumbsResultDetails: 'connections__dynamics_breadcrumbs_result-details',
  dynamicsHeaderGraphButton: 'connections__dynamics_header_graph-button',
  dynamicsHeaderTableButton: 'connections__dynamics_header_table-button',
  dynamicsHeaderOpenFiltersButton: 'connections__dynamics_header_open-filters-button',
  dynamicsFiltersRefreshButton: 'connections__dynamics_filters_refresh-button',
  dynamicsFiltersPeriod: 'connections__dynamics_filters_period',
  dynamicsFiltersDateRangeButton: 'connections__dynamics_filters_date-range-button',
  dynamicsFiltersTestsSelect: 'connections__dynamics_filters_tests',
  dynamicsFiltersDialog: 'connections__dynamics_filters-dialog',
  dynamicsFiltersDialogPeriod: 'connections__dynamics_filters-dialog_period',
  dynamicsFiltersDialogDateRange: 'connections__dynamics_filters-dialog_date-range',
  dynamicsFiltersDialogTests: 'connections__dynamics_filters-dialog_tests',
} as const;
