import {IconButton, Stack, Typography} from '@mui/material';
import {MOBILE_DIALOG_DATA_TEST_ID} from '@src/components/MobileInteractionView/testIds';
import {ReactComponent as BackIcon} from '@src/shared/assets/icons/24x24/arrow-back.svg';
import {ReactComponent as CloseIcon} from '@src/shared/assets/icons/24x24/cross.svg';
import {testId} from '@src/shared/utils/testId';
import {FC, PropsWithChildren} from 'react';

import {sx} from './styles';
import {IMobileInteractionViewProps} from './types';

export const MobileInteractionView: FC<PropsWithChildren<IMobileInteractionViewProps>> = ({
  isOpen,
  title,
  onBack,
  onClose,
  children,
  testIdFeatureGroup = '',
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Stack sx={sx.container}>
      <Stack sx={sx.header}>
        <IconButton
          onClick={onBack}
          data-test-id={testId(
            testIdFeatureGroup + MOBILE_DIALOG_DATA_TEST_ID.mobileDialogBackButton,
          )}
        >
          <BackIcon />
        </IconButton>
        <Typography
          variant={'18_24_700'}
          data-test-id={testId(
            testIdFeatureGroup + MOBILE_DIALOG_DATA_TEST_ID.mobileDialogCloseButton,
          )}
        >
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {children}
    </Stack>
  );
};
