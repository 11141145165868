import {ROUTERS_PATH} from '@src/routers';
import {SEARCH_PARAMS} from '@src/shared/constants/searchParams';
import {useNavigate} from 'react-router-dom';

export const useOverviewNavigation = () => {
  const navigate = useNavigate();

  const navigateToAddRelation = () => {
    navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION);
  };

  const navigateToAddInsurance = () => {
    navigate(ROUTERS_PATH.ADD_INSURANCE);
  };

  const navigateToAddResult = () => {
    const searchParams = new URLSearchParams();
    searchParams.set(SEARCH_PARAMS.ADD_RESULT, 'true');
    const query = searchParams.toString();
    navigate(`${ROUTERS_PATH.RESULTS}?${query}`);
  };

  return {
    navigateToAddRelation,
    navigateToAddResult,
    navigateToAddInsurance,
  };
};
