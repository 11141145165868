import {Box, Typography, Stack, IconButton, Divider} from '@mui/material';
import {ReactComponent as ArrowIcon} from '@src/shared/assets/icons/24x24/arrow-down.svg';
import {testId} from '@src/shared/utils/testId';

import {calcContainerPaddings, rotateArrowIcon} from './styled';
import {sx} from './styles';
import {CollapsibleSectionProps} from './types';

export const CollapsibleSection = ({title, children, onClick, isActive, buttonDataTestId = 'collapse-button'}: CollapsibleSectionProps) => {
  return (
    <Stack sx={sx.container} p={calcContainerPaddings(isActive)}>
      <Box sx={sx.boxContainer}>
        <Typography sx={sx.title}>
          {title}
        </Typography>
        <IconButton
          sx={{
            p: 0,
            transform: rotateArrowIcon(isActive),
          }}
          onClick={onClick}
          data-test-id={testId(buttonDataTestId)}
        >
          <ArrowIcon/>
        </IconButton>
      </Box>
      {isActive && (
        <>
          <Divider sx={sx.line} />
          {children}
        </>
      )}
    </Stack>
  );
};
