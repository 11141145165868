import {IFilterResults} from './types';

export const initialFilter: IFilterResults = {
  collectionDateFrom: null,
  collectionDateTo: null,
  physician: null,
  healthCases: [],
  reportDateFrom: null,
  reportDateTo: null,
};
