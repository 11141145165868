import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerRange.scss';
import {SxProps} from '@mui/material';
import {DateControl} from '@src/components/form/DateControl';
import {endOfDay, startOfDay} from 'date-fns';
import {DateTime} from 'luxon';
import {FC, useMemo} from 'react';

export const COMMON_US_DATE_FORMAT = 'MM/dd/yyyy';

export enum EShowState {
  SHORT = 'SHORT',
  FULL = 'FULL',
}

interface IDatePickerRangeProps {
  sx?: SxProps
  startAdornmentName?: string
  variant?: string
  startDate: Date | null
  setStartDate: any
  endDate: Date | null
  setEndDate: any
  label?: string
  className?: string
  clearDate?: () => void
  isLabelOutside?: boolean
  showState?: EShowState
  isIconVisible?: boolean
  disabledPast?: boolean
  onlyFutureYears?: boolean
  disabledFuture?: boolean
  reverseYears?: boolean
  labelTop?: boolean
  labelLeft?: boolean
  startRangeWithCurrentMonth?: boolean
  dataTestId?: string
}

export const DatePickerRange: FC<IDatePickerRangeProps> = ({
  sx,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  label,
  className,
  clearDate,
  showState = EShowState.FULL,
  isIconVisible,
  disabledPast,
  onlyFutureYears,
  disabledFuture,
  reverseYears,
  isLabelOutside,
  labelTop,
  labelLeft,
  startRangeWithCurrentMonth,
  dataTestId = 'date-range',
}) => {
  let sxValue = {};
  switch (showState) {
    case EShowState.SHORT:
      sxValue = {
        position: 'absolute',
        button: {display: 'none'},
        opacity: 0,
        top: 0,
        left: 0,
      };
      break;
    case EShowState.FULL:
      sxValue = {
        position: 'static',
        button: {display: 'inline-flex'},
        opacity: 1,
        top: 0,
        left: 0,
      };
      break;
  }
  const onChange = (_: any, dates: [string, string]) => {
    const [start, end] = dates;

    if (!start && !end) {
      clearDate?.();

      return;
    }

    setStartDate(startOfDay(new Date(start)));
    setEndDate(end ? endOfDay(new Date(end)) : end);
  };
  const range = useMemo(() => {
    const startDateFormatted = startDate
      ? DateTime.fromJSDate(startDate).toFormat(COMMON_US_DATE_FORMAT)
      : null;
    const endDateFormatted = endDate
      ? DateTime.fromJSDate(endDate).toFormat(COMMON_US_DATE_FORMAT)
      : null;
    return [startDateFormatted, endDateFormatted] as [string | null, string | null];
  }, [endDate, startDate]);

  return (
    <DateControl
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        // TODO: Figure out why -20px and get rid of it
        {mb: '-20px'},
        sxValue,
      ]}
      className={className}
      type={'range'}
      label={label ?? ''}
      range={range}
      placeholder="mm/dd/yyyy"
      onChange={onChange as any}
      isIconVisible={isIconVisible}
      disabledPast={disabledPast}
      onlyFutureYears={onlyFutureYears}
      disabledFuture={disabledFuture}
      reverseYears={reverseYears}
      isLabelOutside={isLabelOutside}
      labelTop={labelTop}
      labelLeft={labelLeft}
      startRangeWithCurrentMonth={startRangeWithCurrentMonth}
      dataTestId={dataTestId}
    />
  );
};
