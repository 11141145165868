// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */

import {DatePickerRange} from '@components';
import {css as _, SelectChangeEvent, Stack} from '@mui/material';
import {FormSelectControl} from '@src/components/FormSelectControl';
import {FiltersDialog} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {RESULTS_DATA_TEST_ID} from '@src/pages/Results/testIds';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {FC, useEffect, useMemo, useState} from 'react';
import {ActionsButton, ActionsRow, MenuItem, SelectControl} from 'ui-kit';

import {initialFilter} from '../../Results.constant';

import {IFilterDialogProps} from './types';

export const FilterDialog: FC<IFilterDialogProps> = ({
  filter,
  handleClose,
  isFilterOpen,
  physicianOptions,
  healthCasesOptions,
  setNewFilters,
}) => {
  const {t} = useTranslate('results');
  const {mobile, tabletPortrait} = useMQuery();
  const onPortrait = mobile || tabletPortrait;
  const [dialogFilters, setDialogFilters] = useState(filter);
  const [searchValue, setSearchValue] = useState('');
  const [selectedPhysician, setSelectedPhysician] = useState('');

  const handleChangeSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const handleClearSearchValue = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (isFilterOpen) {
      setDialogFilters(filter);
    }
  }, [isFilterOpen]);

  const filteredCasesOptions = useMemo(() => {
    if (searchValue) {
      return healthCasesOptions.filter((option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
    return healthCasesOptions;
  }, [healthCasesOptions, searchValue]);

  const handlePhysicianChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedPhysician(value);

    const data = physicianOptions.find((item) => item.name === value);
    if (data && event) {
      setDialogFilters((prev) => ({
        ...prev,
        physician: data ? {value: data.value, name: data.name} : null,
      }));
    }
  };

  const handleHealthCaseChange = (event: SelectChangeEvent) => {
    const value = event.target.value;

    const selectedHealthCases = healthCasesOptions.filter((option) => value.includes(option.value));

    setDialogFilters((prevFilters) => ({
      ...prevFilters,
      healthCase: selectedHealthCases,
    }));
  };

  const handleCasesCheckToggle = () => {
    if ((dialogFilters.healthCase?.length || 0) > healthCasesOptions.length / 2) {
      setDialogFilters(prev => ({...prev, healthCase: []}));
    } else {
      setDialogFilters(prev => ({...prev, healthCase: healthCasesOptions}));
    }
  };

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setDialogFilters((prev) => ({...prev, [name]: date}));
  };

  const handleClearCollectionDate = () => {
    setDialogFilters((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handleClear = () => {
    setDialogFilters(initialFilter);
  };

  const handleApply = () => {
    handleClose();
    setNewFilters(dialogFilters);
  };

  return (
    <FiltersDialog
      isOpen={isFilterOpen}
      title={t('FILTERS')}
      onClose={handleClose}
      onApply={handleApply}
      onClear={handleClear}
      testIdFeatureGroup={RESULTS_DATA_TEST_ID.results}
    >
      <Stack gap={24}>
        {onPortrait && (
          <>
            <FormSelectControl
              name={'physician'}
              value={selectedPhysician}
              onChange={handlePhysicianChange}
              label={t('PHYSICIAN')}
              placeholder={t('SELECT')}
              data-test-id={testId(RESULTS_DATA_TEST_ID.resultsFiltersDialogPhysicianSelect)}
            >
              {physicianOptions.map((option) => (
                <MenuItem
                  key={option.name}
                  value={option.name}
                  data-test-id={testId(
                    RESULTS_DATA_TEST_ID.resultsFiltersDialogPhysicianSelectItem,
                  )}
                >
                  {option.name}
                </MenuItem>
              ))}
            </FormSelectControl>
            <SelectControl<string>
              data-test-id={testId(RESULTS_DATA_TEST_ID.resultsFiltersDialogHealthCaseSelect)}
              value={dialogFilters.healthCase || []}
              onChange={handleHealthCaseChange}
              multiple
              label={t('HEALTH_CASES')}
              placeholder={t('SELECT')}
              onSearch={handleChangeSearchValue}
              onClose={handleClearSearchValue}
              dropdownFooterRow={
                <ActionsRow>
                  <ActionsButton
                    data-test-id={testId(
                      RESULTS_DATA_TEST_ID.resultsFiltersDialogHealthCaseSelectDropdownCheckUncheckAll,
                    )}
                    onClick={handleCasesCheckToggle}
                    noClose={onPortrait}
                  >
                    {(dialogFilters.healthCase?.length || 0) > healthCasesOptions.length / 2 ? t('UNCHECK_ALL') : t('CHECK_ALL')}
                  </ActionsButton>
                </ActionsRow>
              }
            >
              {filteredCasesOptions.length
                ? (
                  filteredCasesOptions.map(({value, label}) => (
                    <MenuItem
                      key={value}
                      value={value}
                      data-test-id={testId(
                        RESULTS_DATA_TEST_ID.resultsFiltersDialogHealthCaseSelectDropdownItem,
                      )}
                    >
                      {label}
                    </MenuItem>
                  ))
                )
                : (
                  <ActionsRow>{t('NO_SUITABLE_RESULTS')}</ActionsRow>
                )}
            </SelectControl>
          </>
        )}
        <DatePickerRange
          label="Collection Date"
          variant="outlined"
          startDate={dialogFilters.collectionDateFrom}
          setStartDate={handleChangeDate('collectionDateFrom')}
          setEndDate={handleChangeDate('collectionDateTo')}
          endDate={dialogFilters.collectionDateTo}
          css={_`width: 100%; margin-bottom: 0 !important`}
          clearDate={handleClearCollectionDate}
          labelTop
          isIconVisible={false}
          dataTestId={testId(RESULTS_DATA_TEST_ID.resultsFiltersDialogDateRange)}
        />
      </Stack>
    </FiltersDialog>
  );
};
