import {Box, Stack} from '@mui/material';
import {DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {RELATIONS_DATA_TEST_ID} from '@src/pages/Connections/fragments/Relations/testIds';
import {modalSize, modalType} from '@src/pages/Connections/utils/functionHelpers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {testId} from '@src/shared/utils/testId';
import {Button} from 'ui-kit';

import {sx, StyledModal} from './styles';
import {AccessesDeleteModalProps} from './types';

export const ChangeAccessModal = ({isOpen, onClose}: AccessesDeleteModalProps) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile, tablet: isTablet} = useMQuery();

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      size={modalSize(isTablet, isMobile)}
      data-test-id={testId(RELATIONS_DATA_TEST_ID.relationChangeAccessDialog)}
    >
      <DialogTitle
        width="60px"
        onClose={onClose}
        noContent={false}
        closeButtonDataTestId={RELATIONS_DATA_TEST_ID.relationChangeAccessDialogHeaderCloseButton}
      >
        {t('CHANGE_ACCESS')}
      </DialogTitle>
      <Stack sx={{alignItems: 'center'}}>{modalType(isTablet, isMobile)}</Stack>
      <Box sx={sx.modalButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          sx={{...sx.modalButton, width: {xs: '100%', sm: 102}}}
          onClick={onClose}
          data-test-id={testId(RELATIONS_DATA_TEST_ID.relationChangeAccessDialogFooterBackButton)}
        >
          {t('BACK')}
        </Button>
      </Box>
    </StyledModal>
  );
};
