// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {Box, css, Stack, Typography, useTheme} from '@mui/material';
import {ResultType} from '@src/api/__generated__/webApi';
import {Breadcrumbs, FilterWrapper, Link, PageHeader, Text} from '@src/components';
import {DateRangeButton} from '@src/components/DateRangeButton';
import Grid from '@src/components/Grid';
import {TempSelect} from '@src/components/TempSelect';
import {BoxPaddings, WhiteBox} from '@src/components/WhiteBox';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {ResultDynamicsTable} from '@src/features/ResultDynamicsTable';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as GraphIcon} from '@src/shared/assets/icons/graph.svg';
import {ReactComponent as TableIcon} from '@src/shared/assets/icons/table.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateFormatted} from '@src/shared/utils';
import {testId} from '@src/shared/utils/testId';
import {DynamicTestTransformed} from '@src/store/results/helpers';
import {useDynamicsData, useResultDetail} from '@src/store/results/hooks';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {
  Toggle,
  LoaderLine,
  SelectControl,
  MenuItem,
  ActionsRow,
  ActionsButton,
  MultiSelectChangeHandler,
} from 'ui-kit';
import {OptionValue} from 'ui-kit/lib/components/SelectControl/Select/types/OptionValue';

import {dateInInterval} from '../Results/Results.helpers';

import {Graphics, Table} from './Board';
import {dataWithoutEmptyValue, getTimePeriod} from './Dynamic.helpers';
import {initialDates, initialFilters} from './Dynamics.constant';
import {sx} from './Dynamics.style';
import {RadioButton} from './Dynamics.styled';
import {FiltersDialog} from './components/FiltersDialog';
import {DYNAMICS_DATA_TEST_ID} from './testIds';
import {IFilter, TimePeriod} from './types';

const initialFilter: (testCodes: string | null | undefined, testsOptions: OptionValue[]) => IFilter = (testCodes, testsOptions) => {
  let selectedTests: OptionValue[] = [];
  if (testCodes) {
    const paramsTestCodes = JSON.parse(decodeURI(testCodes)) as string[];

    selectedTests = testsOptions.reduce<OptionValue[]>((acc, item) => {
      if (paramsTestCodes.includes(item.value)) {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  return {
    end: null,
    start: null,
    selectedTests,
  };
};

export const Dynamics: FC = () => {
  const {tests, resultId} = useParams();
  const [searchParams] = useSearchParams();

  const [timePeriod, setTimePeriod] = useState('');
  const {t, ready} = useTranslate('details');
  const [filter, setFilter] = useState<IFilter>(initialFilters);
  const refIsInitialSet = useRef(false);
  const [isGraphics, setIsGraphics] = useState(true);
  const {mobile, tablet, tabletPortrait} = useMQuery();
  const theme = useTheme();
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);
  const [isShowManualResults, setIsShowManualResults] = useState(true);
  const [isShowManualToggle, setIsShowManualToggle] = useState(true);
  const [isClearing, setIsClearing] = useState(false);
  const [isForceDatePickerOpen, setIsForceDatePickerOpen] = useState(false);

  const {dynamics, isLoading} = useDynamicsData();
  const [filteredDynamics, setFilteredDynamics] = useState<DynamicTestTransformed[]>([]);
  const [testsSearchValue, setTestsSearchValue] = useState('');

  const handleChangeSearchValue = (value: string) => setTestsSearchValue(value);
  const handleClearSearchValue = () => setTestsSearchValue('');

  const {resultLaboratoryDetails, resultManualDetails} = useResultDetail({
    resultId,
    type: searchParams.get('type') || '',
  });

  const resultTitle =
    (resultLaboratoryDetails?.orderDetails
      ? dateFormatted(resultLaboratoryDetails.orderDetails.reported)
      : dateFormatted(resultManualDetails?.date)) || '...';

  useEffect(() => {
    if (dynamics.testResults.length) {
      const isManualResultsExists = !!dynamics.testResults.find((test) =>
        test.resultLaboratoryAnalyses.find((item) => item.type === ResultType.MANUAL),
      );
      setIsShowManualToggle(isManualResultsExists);
    }
  }, [dynamics]);

  const {start, end, selectedTests} = filter;

  const toggleMobileFilersOpen = () => {
    setIsMobileFiltersOpen((prev) => !prev);
  };

  const handleToggleManualResultsShow = () => {
    const newValue = !isShowManualToggle;
    setIsShowManualResults(prev => !prev);

    if (!newValue) {
      const filteredTestCodes = dynamics.testResults.filter(item => {
        if (filter.selectedTests.some(test => test.value === item.code)) {
          const analyses = item.resultLaboratoryAnalyses;
          return analyses.every(analysis => analysis.type === ResultType.MANUAL || analysis.type === undefined);
        }
        return false;
      }).map(item => item.code);
      const testCodes = filter.selectedTests.filter(item => !filteredTestCodes.includes(item.value));
      setFilter((prev) => ({...prev, testCodes}));
    }
  };

  const handleSetTimePeriod = (newTimePeriod: string) => {
    setTimePeriod(newTimePeriod);
  };

  const changeTimePeriod = (value: string) => {
    handleSetTimePeriod(value);

    if (value === TimePeriod.CUSTOM) {
      setIsForceDatePickerOpen(true);
      return;
    }

    const dates = getTimePeriod(value);
    setFilter((prev) => ({...prev, ...dates}));
  };

  const handleOpenDatePicker = () => {
    setIsForceDatePickerOpen(false);
  };

  const changeDisableVariantDynamics = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!(e.target instanceof HTMLElement)) return;
    const {textContent} = e.target;
    setIsGraphics(textContent === 'Graph');
  };

  const testsOptions: OptionValue[] = useMemo(() => {
    if (!dynamics.testResults) {
      return [];
    }

    const uniqueNames = new Set<string>();

    return dynamics.testResults
      .reduce<OptionValue[]>((acc, {resultLaboratoryAnalyses, testName, code}) => {
      const filteredAnalyses =
          resultLaboratoryAnalyses?.filter(
            (column) => isShowManualResults || column.type === ResultType.LABORATORY || column.type === undefined,
          ) || [];

      if (filteredAnalyses.length) {
        const option = {
          label: testName || '',
          value: code,
        };

        if (!uniqueNames.has(option.label)) {
          uniqueNames.add(option.label);
          acc.push(option);
        }
      }

      return acc;
    }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [dynamics, isShowManualResults]);

  useEffect(() => {
    if (refIsInitialSet.current || !tests || !testsOptions.length) {
      return;
    }
    setFilter(initialFilter(tests, testsOptions));
    refIsInitialSet.current = true;
  }, [testsOptions]);

  const filteredTestsOptions = useMemo(() => {
    if (testsSearchValue) {
      return testsOptions.filter(option => option.label.toLowerCase().includes(testsSearchValue.toLowerCase()));
    }
    return testsOptions;
  }, [testsOptions, testsSearchValue]);

  useEffect(() => {
    const filteredDynamicsByTests = dynamics.testResults.filter((item) => doFiltration(item));
    const clearedFilteredDynamics = dataWithoutEmptyValue(filteredDynamicsByTests);

    const filteredByType = clearedFilteredDynamics.map((dataItem) => ({
      ...dataItem,
      resultLaboratoryAnalyses: dataItem?.resultLaboratoryAnalyses?.filter((column) =>
        isShowManualResults ? true : column.type === ResultType.LABORATORY || column.type === undefined,
      ),
    }));

    if (!end || !start) {
      setFilteredDynamics(filteredByType);
      return;
    }
    const dataFil =
      filteredByType.map((dataItem) => ({
        ...dataItem,
        resultLaboratoryAnalyses: dataItem?.resultLaboratoryAnalyses?.filter(
          (column) =>
            dateInInterval(start, end, column.biomaterialSamplingDate) &&
            column.value !== null &&
            column.value !== undefined,
        ),
      })) ?? [];

    setFilteredDynamics(dataFil.filter((it) => it.resultLaboratoryAnalyses.length > 0));
  }, [filter, dynamics?.testResults, isShowManualResults]);

  const updateFilter = (newFilters: IFilter) => {
    setFilter(newFilters);
  };

  const changeTestCodes: MultiSelectChangeHandler<string> = (e: { target: { value: string[] } }) => {
    const {
      target: {value},
    } = e;
    const selectedTests = testsOptions.filter(option => value.includes(option.value));
    setFilter((prev) => ({...prev, selectedTests}));
  };

  const handleTestsCheckToggle = () => {
    if (filter.selectedTests.length > testsOptions.length / 2) {
      setFilter((prev) => ({...prev, selectedTests: []}));
    } else {
      setFilter((prev) => ({...prev, selectedTests: testsOptions}));
    }
  };

  const doFiltration = (item: DynamicTestTransformed) => {
    return filter.selectedTests.length > 0 ? !!filter.selectedTests.find(test => test.value === item.code) : true;
  };

  const clearDate = () => {
    setFilter((prevState) => ({...prevState, ...initialDates}));
  };

  const onClear = () => {
    setIsClearing(true);
    clearDate();
    setTimePeriod('');
    setFilter({...initialFilters});
  };

  const changeDate = (name: string) => (date: Date | null) => {
    setFilter((prevState) => ({...prevState, [name]: date}));
    setTimePeriod(TimePeriod.CUSTOM);
  };

  useEffect(() => {
    if (!start && !end && selectedTests.length === 0) {
      setIsClearing(false);
    }
  }, [filter]);

  const renderTable = () => {
    if (!mobile && !tablet) {
      return <ResultDynamicsTable dynamicsWithTests={filteredDynamics} isLoading={isLoading} />;
    } else {
      return (
        <Table
          isLoading={isLoading}
          start={filter.start}
          end={filter.end}
          isModifiedScroll
          data={filteredDynamics}
        />
      );
    }
  };

  const calcBoxPaddings = () => {
    if (tablet) return '24px';
    if (mobile) return '24px 16px';
    return '36px';
  };

  const boxPaddings = calcBoxPaddings();

  if (!ready) {
    return null;
  }

  const isManual = !!resultManualDetails;

  return (
    <Stack gap={mobile ? 24 : 36} flexGrow={1}>
      <Stack sx={sx.container}>
        <Breadcrumbs>
          <Link to={ROUTERS_PATH.RESULTS} data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsBreadcrumbsResults)}>{t('RESULTS')}</Link>
          <Link to={`../?type=${isManual ? ResultType.MANUAL : ResultType.LABORATORY}`} data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsBreadcrumbsResultDetails)}>
            {resultTitle}
          </Link>
          <Text sx={{color: 'grey.300'}}>
            {mobile || tablet ? t('DYNAMICS') : t('DYNAMICS_OF_RESULTS')}
          </Text>
        </Breadcrumbs>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <PageHeader height={'auto'}>{t('DYNAMICS_OF_RESULTS')}</PageHeader>
          <div
            css={css`
              display: flex;
              gap: 40px;
            `}
          >
            {!mobile && !tabletPortrait && (
              <div
                css={css`
                  display: flex;
                `}
              >
                <RadioButton
                  size={'lg'}
                  active={isGraphics}
                  sx={{
                    padding: '3px 24px !important',
                    '& svg path': {
                      fill: isGraphics
                        ? `${theme.palette.common.white}!important`
                        : `${theme.palette.secondary.main}!important`,
                    },
                    '& .MuiButton-endIcon': {
                      width: 24,
                      height: 24,
                    },
                  }}
                  endIcon={<GraphIcon />}
                  onClick={changeDisableVariantDynamics}
                  data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsHeaderGraphButton)}
                >
                  {t('GRAPH')}
                </RadioButton>
                <RadioButton
                  active={!isGraphics}
                  sx={{
                    padding: '3px 24px !important',
                    '& svg path': {
                      fill: isGraphics
                        ? `${theme.palette.secondary.main}!important`
                        : `${theme.palette.common.white}!important`,
                    },
                    '& .MuiButton-endIcon': {
                      width: 24,
                      height: 24,
                    },
                  }}
                  size={'lg'}
                  endIcon={<TableIcon />}
                  onClick={changeDisableVariantDynamics}
                  data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsHeaderTableButton)}
                >
                  {t('TABLE')}
                </RadioButton>
              </div>
            )}
            {(mobile || tablet) && <SettingsButton onClick={toggleMobileFilersOpen} dataTestId={testId(DYNAMICS_DATA_TEST_ID.dynamicsHeaderOpenFiltersButton)} />}
          </div>
        </div>
        {(mobile || tabletPortrait) && (
          <div
            css={css`
              display: flex;
              width: 100%;
            `}
          >
            <RadioButton
              size={'lg'}
              fullWidth
              active={isGraphics}
              endIcon={
                <GraphIcon
                  fill={isGraphics ? theme.palette.common.white : theme.palette.secondary.main}
                />
              }
              sx={{
                padding: '3px 24px !important',
                '& svg path': {
                  fill: isGraphics
                    ? `${theme.palette.common.white}!important`
                    : `${theme.palette.secondary.main}!important`,
                },
                '& .MuiButton-endIcon': {
                  width: 24,
                  height: 24,
                },
              }}
              onClick={changeDisableVariantDynamics}
              data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsHeaderGraphButton)}
            >
              {t('GRAPH')}
            </RadioButton>
            <RadioButton
              active={!isGraphics}
              size={'lg'}
              fullWidth
              endIcon={
                <TableIcon
                  fill={isGraphics ? theme.palette.secondary.main : theme.palette.common.white}
                />
              }
              sx={{
                padding: '3px 24px !important',
                '& svg path': {
                  fill: isGraphics
                    ? `${theme.palette.secondary.main}!important`
                    : `${theme.palette.common.white}!important`,
                },
                '& .MuiButton-endIcon': {
                  width: 24,
                  height: 24,
                },
              }}
              onClick={changeDisableVariantDynamics}
              data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsHeaderTableButton)}
            >
              {t('TABLE')}
            </RadioButton>
          </div>
        )}
      </Stack>
      <WhiteBox noPaddings sx={{flexGrow: 1}}>
        <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
          {!mobile && !tablet && (
            <BoxPaddings sx={{padding: '24px 36px'}}>
              <FilterWrapper onClear={onClear} noPaddings refreshButtonDataTestId={testId(DYNAMICS_DATA_TEST_ID.dynamicsFiltersRefreshButton)}>
                <Grid container spacing={36} flexGrow={1}>
                  <Grid
                    xs={4}
                    lg={5}
                    xl={5}
                    flexDirection={'row'}
                    display={'flex'}
                    gap={12}>
                    <TempSelect
                      value={timePeriod}
                      onChange={changeTimePeriod}
                      options={Object.values(TimePeriod).map((value) => ({
                        name: t(value),
                        value,
                      }))}
                      label={t('TIME_PERIOD')}
                      placeholder={t('SELECT')}
                      compact={false}
                      testIdFeatureGroup={DYNAMICS_DATA_TEST_ID.dynamicsFiltersPeriodSelect}
                    />
                    <DateRangeButton
                      startDate={filter.start}
                      endDate={filter.end}
                      setStartDate={changeDate('start')}
                      setEndDate={changeDate('end')}
                      clearDate={clearDate}
                      startRangeWithCurrentMonth
                      openTrigger={isForceDatePickerOpen}
                      onOpen={handleOpenDatePicker}
                      buttonDataTestId={testId(DYNAMICS_DATA_TEST_ID.dynamicsFiltersDateRangeButton)}
                    />
                  </Grid>
                  <Grid xs={4} lg={4} xl={4}>
                    <SelectControl<string>
                      data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsFiltersTestsSelect)}
                      value={filter.selectedTests}
                      onChange={changeTestCodes}
                      multiple
                      label={t('TESTS')}
                      placeholder={t('SELECT')}
                      compact={false}
                      onSearch={handleChangeSearchValue}
                      onClose={handleClearSearchValue}
                      dropdownFooterRow={
                        <ActionsRow>
                          <ActionsButton
                            data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsFiltersTestsSelectDropdownCheckUncheckAll)}
                            onClick={handleTestsCheckToggle}
                          >
                            {filter.selectedTests.length > testsOptions.length / 2 ? t('UNCHECK_ALL') : t('CHECK_ALL')}
                          </ActionsButton>
                        </ActionsRow>
                      }
                    >
                      {filteredTestsOptions.length
                        ? filteredTestsOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value} data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsFiltersTestsSelectDropdownItem)}>
                            {option.label}
                          </MenuItem>
                        ))
                        : (
                          <ActionsRow>
                            {t('NO_SUITABLE_RESULTS')}
                          </ActionsRow>
                        )}
                    </SelectControl>
                  </Grid>
                  {isShowManualToggle && (
                    <Grid
                      xs={3}
                      lg={3}
                      xl={3}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'flex-end'}
                    >
                      <Toggle
                        labelLeft={t('SHOW_MANUALLY_ADDED_VALUES')}
                        checked={isShowManualResults}
                        onChange={handleToggleManualResultsShow}
                        data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsFiltersManualToggle)}
                      />
                    </Grid>
                  )}
                </Grid>
              </FilterWrapper>
            </BoxPaddings>
          )}
          <Box height={3}>
            <LoaderLine show={isClearing} size="sm" />
          </Box>
          <Box sx={isGraphics ? {} : sx.tableContainer}>
            {(mobile || tablet) && (
              <Stack sx={sx.toggleWrapper}>
                <Typography variant={'14_18_500'}>{t('SHOW_MANUALLY_ADDED_VALUES')}</Typography>
                <Toggle
                  checked={isShowManualResults}
                  onChange={handleToggleManualResultsShow}
                  data-test-id={testId(DYNAMICS_DATA_TEST_ID.dynamicsFiltersManualToggle)}
                />
              </Stack>
            )}
            {isGraphics
              ? (
                <BoxPaddings
                  paddings={boxPaddings}
                  sx={{
                    ...sx.graphicsWrapper,
                    borderColor: theme.palette.secondary[300],
                  }}
                >
                  <Graphics data={filteredDynamics} isLoading={isLoading} />
                </BoxPaddings>
              )
              : (
                renderTable()
              )}
          </Box>
        </Box>
      </WhiteBox>
      <FiltersDialog
        isOpen={isMobileFiltersOpen}
        filters={filter}
        onClose={toggleMobileFilersOpen}
        onSetNewFilters={updateFilter}
        testsOptions={testsOptions}
        filteredTestsOptions={filteredTestsOptions}
        timePeriod={timePeriod}
        onSetTimePeriod={handleSetTimePeriod}
        onChangeSearchValue={handleChangeSearchValue}
        onClearSearchValue={handleClearSearchValue}
      />
    </Stack>
  );
};
