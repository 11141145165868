/* eslint-disable no-comments/disallowComments */
/** @jsxImportSource @emotion/react */
import {Box, ButtonBaseProps} from '@mui/material';
import {HEADER_DATA_TEST_ID} from '@src/features/layouts/MainLayout/testIds';
import {useClickOutside} from '@src/shared/hooks/useClickOutside';
import {testId} from '@src/shared/utils/testId';
import {MenuList} from 'components/MenuList/MenuList';
import {FC, forwardRef, PropsWithChildren, useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';

import {QuestionStyled, SupportMenuButtonStyled} from './styles';

const sx = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
};

export interface ISupportMenuItem {
  to?: string
  onClick?: VoidFunction
  label: string
  iconRight?: JSX.Element
  iconLeft?: JSX.Element
  title?: string
  dataTestId: string
}
interface ISupportMenuProps {
  src?: string | undefined
  menu: ISupportMenuItem[]
  className?: string
}
export const SupportMenuView: FC<PropsWithChildren<ISupportMenuProps>> = ({
  children,
  src,
  menu,
  className,
}) => {
  const [opened, setOpened] = useState(false);
  const elRef = useRef(window.document.createElement('div'));
  const [container, setContainerEl] = useState<HTMLElement | null>(null);
  const tmtRef = useRef<number>();

  const handleClick = useCallback(() => {
    setOpened((s) => !s);
  }, []);

  const handleMouseLeave = useCallback(() => {
    tmtRef.current = window.setTimeout(() => {
      setOpened(false);
    }, 1000);
  }, []);

  const handleMouseOver = useCallback(() => {
    window.clearTimeout(tmtRef.current);
  }, []);

  const btRef = useClickOutside(() => {
    window.setTimeout(() => {
      setOpened(false);
    });
  });

  useEffect(() => {
    container?.appendChild(elRef.current);
    return () => {
      container?.removeChild(elRef.current);
    };
  }, [container]);

  return (
    <Box ref={setContainerEl} className={className} sx={sx.container}>
      <SupportMenuButton
        ref={(el) => {
          btRef.current = el;
        }}
        type={'button'}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
        onMouseOver={handleMouseOver}
        src={src}
        data-test-id={testId(HEADER_DATA_TEST_ID.headerSupportButton)}
      >
        <>
          {children}
          {createPortal(
            <MenuList
              parentRef={btRef}
              open={opened}
              menu={menu}
              onClose={handleClick}
              data-test-id={testId(HEADER_DATA_TEST_ID.headerSupportMenu)}
            />,
            elRef.current,
          )}
        </>
      </SupportMenuButton>
    </Box>
  );
};
interface IProps extends ButtonBaseProps {
  src?: string
}

export const SupportMenuButton = forwardRef<any, IProps>(({children, ...baseBtProps}, ref) => {
  return (
    <SupportMenuButtonStyled ref={ref} disableRipple {...baseBtProps}>
      <QuestionStyled />
      {children}
    </SupportMenuButtonStyled>
  );
});
SupportMenuButton.displayName = 'SupportMenuButton';
