export const OVERVIEW_DATA_TEST_ID = {
  overviewRelationsAddRelationButton: 'overview__relations_add-relation-button',
  overviewRelationsViewAllButton: 'overview__relations_view-more-button',
  overviewRelationsItem: 'overview__relations_item',
  overviewResultsAddResultsButton: 'overview__results_add-results-button',
  overviewResultsViewAllButton: 'overview__results_view-all-button',
  overviewResultsItem: 'overview__results_item',
  overviewInsuranceAddInsuranceButton: 'overview__insurance_add-insurance-button',
  overviewInsuranceItem: 'overview__insurance_item',
  overviewGeneralInfoEditButton: 'overview__general-info_edit-button',
  overviewContactsEditButton: 'overview__contacts_edit-button',
  overviewContactsPhonesMoreLessButton: 'overview__contacts_phones_more-less-button',
  overviewContactsEmailsMoreLessButton: 'overview__contacts_emails_more-less-button',
} as const;
