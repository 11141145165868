import {Box, Stack, Typography} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {BMI_DATA_TEST_ID} from '@src/pages/BMI/testIds';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ChevronLeft} from '@src/shared/assets/icons/chevron-left.svg';
import {useSidebarClassObserver, useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {useBmi, useRecomendations} from '@src/store/bmi/hooks';
import {Link} from 'react-router-dom';

import {Calculator} from './fragments/Calculator/Calculator';
import {Recomendations} from './fragments/Recomendations';
import {Statistics} from './fragments/Statistics';
import {StyledLinkButton, TypographyStyled, calculatorBMISx} from './styled';

export const CalculatorBMI = () => {
  const {desktop, xl, lg} = useMQuery();
  const {isSidebarOpen} = useSidebarClassObserver();
  const {bmi, weightTitle, ...calcProps} = useBmi();
  const {t, ready} = useTranslate('journal');
  const {recommendationData, isFetching: isRecommendationFetching} = useRecomendations();
  const isChangeGrid = (!isSidebarOpen && xl) || (desktop && !lg && !xl);

  const sx = calculatorBMISx(isChangeGrid);

  if (!ready) {
    return null;
  }

  return (
    <Stack sx={sx.wrapper}>
      <Stack sx={sx.topContainer}>
        <Stack gap={21} width="100%">
          <StyledLinkButton
            component={Link}
            to={ROUTERS_PATH.JOURNAL}
            variant="text"
            color="black"
            startIcon={<ChevronLeft />}
            data-test-id={testId(BMI_DATA_TEST_ID.bmiBreadcrumbsBack)}
          >
            <Typography sx={sx.breadCrumbs} variant="16_20_500">
              {t('BACK_TO_JOURNAL')}
            </Typography>
          </StyledLinkButton>
          <Box sx={sx.header}>
            <Typography variant="32_36_700">{t('BMI')}</Typography>
            {bmi && (
              <Box sx={sx.bmiBox}>
                <Typography sx={sx.bmi}>{bmi.toFixed(1)}</Typography>
                <TypographyStyled sx={sx.weightTitle}>{t(weightTitle)}</TypographyStyled>
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
      <Box sx={sx.mainContainer} gap={24}>
        <Stack sx={sx.calculatorBox}>
          <Calculator {...calcProps} />
          <Statistics
            data={calcProps.data || []}
            isChangeGrid={isChangeGrid}
            isLoading={calcProps.isFetching}
          />
        </Stack>
        <Box sx={sx.recomendationsBox}>
          <Recomendations {...recommendationData} isFetching={isRecommendationFetching} />
        </Box>
      </Box>
    </Stack>
  );
};
