import {Stack} from '@mui/material';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {useMQuery} from '@src/shared/hooks';
import {testId} from '@src/shared/utils/testId';
import {FC} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {FilterWrapperProps} from './types';

export const FilterWrapper: FC<FilterWrapperProps> = ({
  children,
  onClear,
  className,
  isMotionButton,
  sxRefreshButton,
  noPaddings,
  refreshButtonDataTestId = 'refresh-button',
}) => {
  const {xl} = useMQuery();
  const isMotion = sxRefreshButton || (isMotionButton && xl);
  return (
    <Stack
      className={className}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={36}
      flexWrap="nowrap"
      flexShrink={0}
      pr={18}
    >
      {children}
      <Stack
        sx={{
          ...sxRefreshButton,
          pb: noPaddings ? 0 : isMotion ? 0 : 8,
          pt: noPaddings ? 0 : isMotion ? 8 : 0,
        }}>
        <Button
          variant='text'
          sx={sx.btn}
          color='secondary'
          onClick={onClear}
          data-test-id={testId(refreshButtonDataTestId)}
        >
          <RefreshIcon />
        </Button>
      </Stack>
    </Stack>
  );
};
